import * as React from 'react';
import { memo } from 'react';
import {
    Checkbox as MuiCheckbox,
    checkboxClasses,
    FormControlLabel,
    formControlLabelClasses,
    FormGroup,
    styled,
} from '@mui/material';
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';
import CheckIcon from '@mui/icons-material/Check';
// import { ReactComponent as CheckComplete } from '../../../assets/icons/check-complete.svg';

const StyledFormGroup = styled(FormGroup)`
    position: relative;

    & .${formControlLabelClasses.root} {
        margin: 0;
    }
    & .${formControlLabelClasses.label} {
        color: ${({ theme }) => theme.palette.primary.contrastText};
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
    }

    & .${checkboxClasses.root} {
        height: 24px;
        width: 24px;
        border-radius: 5px;
        margin: 6px;
        pointer-events: none;

        // higher specificity
        &.${checkboxClasses.checked} {
            color: white;
        }

        ${({ theme }) =>
            theme.palette.mode === 'dark'
                ? `background-color: ${theme.palette.blue.lightBg};`
                : `border: 2px solid ${theme.palette.blue.lightBg};
        &.${checkboxClasses.checked} {
            background-color: ${theme.palette.blue.lightBg};
        }`}

        &.${checkboxClasses.disabled} {
            opacity: 0.5;
        }
    }
`;

// Standard Styled checkbox
export const Checkbox = memo(function Checkbox({
    label,
    ...rest
}: { label: React.ReactElement | string } & CheckboxProps) {
    return (
        <StyledFormGroup>
            <FormControlLabel
                control={<MuiCheckbox {...rest} icon={<span />} checkedIcon={<CheckIcon />} />}
                label={label}
            />
        </StyledFormGroup>
    );
});
