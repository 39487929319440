/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { Box, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { theme } from '../../../../theme';
import React from 'react';

export const exceptTooltipContentsStyles = {
    color: 'rgba(36, 159, 152, 1)',
    fontSize: '12px',
    fontWeight: '900',
    textAlign: 'left',
};

export const zoneTooltipContentsStyles = {
    color: '#7986CB',
    fontSize: '12px',
    fontWeight: '900',
    textAlign: 'left',
};

export const StyledStripTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: 'black',
        backgroundColor: 'white',
        borderRadius: '5px',
        boxShadow: '0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.2)',
        fontSize: '11px',
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '22px',
        padding: '10px',
        maxWidth: 'none',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.arrival.main,
    },
}));

export const StyledContainer = styled(Box)`
    position: relative;
    font-size: 12px;
    color: white;
    display: flex;
    align-items: center;
    z-index: 3;
`;

export const StyledAboveStripContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    z-index: 3;
`;

export const StyledStripTimeBadge = styled(Box)`
    border-radius: 3px;
    width: fit-content;
    height: fit-content;
    font-weight: 900;
    padding: 1px 4px;
    margin-left: 10px;
    margin-bottom: 5px;
    z-index: 3;
`;

export const StyledStripFlow = styled(Box)`
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    z-index: 3;
`;

export const StyledDirections = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledAcPaxInfos = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const StyledAcPaxInfo = styled(Box)`
    display: flex;
    align-items: center;
`;

export const StyledStripFooterContainer = styled(Box)`
    width: 140px;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 3;
`;

export const StyledStripFooter = styled(Box)`
    width: 120px;
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
`;

export const StyledLeftInfo = styled('div')`
    display: flex;
    flex-direction: row;
`;
