/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React, { memo, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { DriverMissionSlotDto, FlightFlow } from '../../../../../../backend/gen';
import { FormattedTime } from '../../../../../../components/time/FormattedTime';
import { IcaoIata } from '../../../../../../components/IcaoIata';
import { ReactComponent as AircraftIcon } from '../../../../../../assets/icons/icon-plane-light-bus.svg';
import { ReactComponent as AirlineSeatReclineExtraIcon } from '../../../../../../assets/icons/icon-passenger-light-bus.svg';
import { ReactComponent as StaffIcon } from '../../../../../../assets/icons/icon-staff.svg';
import {
    StyledAircraftPaxInfos,
    StyledCurrentMissionFlightInfoContainer,
    StyledFlightNumber,
    StyledFlow,
    StyledIcaoIata,
    StyledInfoBox,
    StyledLandingObt,
    StyledLandingObtBadge,
    StyledTime,
} from './CurrentMissionFlightInfo.style';
import { useInterval } from '../../../../../../hooks/useInterval';
import { intlFormatDistance } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { useIntlValue } from '@innovatm/js-iam';
import { getMissionPrimaryColor } from '../../../../../../utils/data.utils';

export const CurrentMissionFlightInfo = memo(function CurrentMissionFlightInfo({
    driverCurrentMission,
}: {
    driverCurrentMission: DriverMissionSlotDto;
}) {
    const locale = useIntlValue('locale');
    const [time, setTime] = useState(Date.now());
    useInterval(() => setTime(Date.now()), 60000);
    const timeDiff = useMemo(
        () => intlFormatDistance(driverCurrentMission.refTime, time, { locale: locale }),
        [driverCurrentMission.refTime, locale, time],
    );
    const flowColor = getMissionPrimaryColor(driverCurrentMission.type, driverCurrentMission.flight?.flow);
    const isStaffMission = driverCurrentMission.staffNum;

    return (
        <StyledCurrentMissionFlightInfoContainer color={flowColor}>
            <StyledFlow>
                {driverCurrentMission.flight?.flow === FlightFlow.ARR ? (
                    <FormattedMessage id="arrival" />
                ) : (
                    <FormattedMessage id="departure" />
                )}
            </StyledFlow>
            <StyledTime>
                <FormattedTime>{driverCurrentMission.refTime}</FormattedTime>
            </StyledTime>
            <StyledLandingObt>
                {driverCurrentMission.flight?.flow === FlightFlow.ARR ? (
                    <FormattedMessage id="landingTime" />
                ) : (
                    <FormattedMessage id="offBlockTime" />
                )}
            </StyledLandingObt>
            <StyledLandingObtBadge bgcolor={flowColor}>{timeDiff}</StyledLandingObtBadge>
            <StyledFlightNumber>{driverCurrentMission.flight?.flightNumber}</StyledFlightNumber>
            <StyledIcaoIata>
                {driverCurrentMission.flight?.flow === FlightFlow.ARR ? (
                    <FormattedMessage id="from" />
                ) : (
                    <FormattedMessage id="to" />
                )}{' '}
                <IcaoIata {...driverCurrentMission.flight?.adepAdes} />
            </StyledIcaoIata>
            <StyledAircraftPaxInfos>
                <StyledInfoBox>
                    <AircraftIcon fill={flowColor} width={'45px'} height={'45px'} />
                    <Box>{<IcaoIata {...driverCurrentMission.flight?.acType} /> || '-'}</Box>
                </StyledInfoBox>
                <StyledInfoBox>
                    {isStaffMission ? (
                        <>
                            <StaffIcon fill={flowColor} width={'45px'} height={'45px'} />
                            <Box>{driverCurrentMission.staffNum || '-'}</Box>
                        </>
                    ) : (
                        <>
                            <AirlineSeatReclineExtraIcon fill={flowColor} width={'45px'} height={'45px'} />
                            <Box>{driverCurrentMission.flight?.pax || '-'}</Box>
                        </>
                    )}
                </StyledInfoBox>
            </StyledAircraftPaxInfos>
        </StyledCurrentMissionFlightInfoContainer>
    );
});
