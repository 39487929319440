/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { ExtendedRecurrentMissionDto } from '../../../../../contexts/MissionFormContext';
import {
    StyledRecurrentMissionDialogRow,
    StyledRecurrentMissionTextInput,
    StyledRecurrentMissionTextInputContainer,
    StyledRecurrentMissionTimeContainer,
    StyledRecurrentMissionTimePicker,
} from './CurrentRecurrentMissionForm';
import { useZonedTimeConverter } from '../../../../../components/time/useZonedTimeConverter';

export function RecurrentNameStartTime({
    mission,
    setMission,
}: {
    mission: Partial<ExtendedRecurrentMissionDto>;
    setMission: (updatedMission: Partial<ExtendedRecurrentMissionDto>) => void;
}) {
    const { timestampToHHMM, hhmmToTimestamp } = useZonedTimeConverter();
    const intl = useIntl();

    return (
        <StyledRecurrentMissionDialogRow>
            <StyledRecurrentMissionTextInputContainer>
                <FormattedMessage id={'otherMissionForm.missionName'} />
                <StyledRecurrentMissionTextInput
                    placeholder={intl.formatMessage({ id: 'otherMissionForm.missionName' })}
                    type={'text'}
                    disableUnderline
                    value={mission?.name || ''}
                    onChange={e => setMission({ name: e.target.value })}
                />
            </StyledRecurrentMissionTextInputContainer>
            <StyledRecurrentMissionTimeContainer>
                <StyledRecurrentMissionTimePicker
                    name={'startTime'}
                    date={new Date()}
                    value={hhmmToTimestamp(mission?.startTime)}
                    onChange={e => setMission({ startTime: timestampToHHMM(e) })}
                    required
                    label={
                        <span className={'missionTimeLabelText'}>
                            <FormattedMessage id={'otherMissionForm.startTime'} />
                        </span>
                    }
                />
            </StyledRecurrentMissionTimeContainer>
        </StyledRecurrentMissionDialogRow>
    );
}
