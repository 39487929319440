import React, { memo } from 'react';
import { styled } from '@mui/material';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const StyledBlink = styled('span')`
    animation: blink 2s infinite;
`;

export const TimeDisplay = memo(function UtcDisplay({
    time,
    blink,
    timezone,
}: {
    time: number;
    blink: boolean;
    timezone?: string;
}) {
    const zonedTime = utcToZonedTime(time, timezone || '');

    return (
        <>
            {format(zonedTime, 'HH')}
            {blink ? <StyledBlink>:</StyledBlink> : ':'}
            {format(zonedTime, 'mm')}
            &nbsp;
            {timezone === 'UTC' ? ' UTC' : ' LOCAL'}
        </>
    );
});
