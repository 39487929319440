/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { DispatcherMissionSlotDto, FlightFlow } from '../../../../../../../backend/gen';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, styled } from '@mui/material';

export function DropOffInput({
    mission,
    setMission,
}: {
    mission: Partial<DispatcherMissionSlotDto>;
    setMission: React.Dispatch<React.SetStateAction<Partial<DispatcherMissionSlotDto>>>;
}) {
    const intl = useIntl();
    return !(mission?.flight?.flow === FlightFlow.ARR) ? null : (
        <>
            <StyledSectionTitle>
                <FormattedMessage id={'flightMissionForm.dropOffLocation'} />
            </StyledSectionTitle>
            <StyledInput
                placeholder={intl.formatMessage({ id: 'flightMissionForm.dropOffLocation' })}
                disableUnderline
                value={mission?.dropOffLocation || ''}
                type={'text'}
                inputProps={{ maxlength: 15 }}
                onChange={event => {
                    setMission({ ...mission, dropOffLocation: event.target.value });
                }}
            />
        </>
    );
}
const StyledInput = styled(Input)`
    min-width: 100%;
    height: 50px;
    border-radius: 5px;
    background-color: #f1f7fb;
    padding: 10px;
    color: #0a3b5d;
    margin-bottom: 20px;
`;

const StyledSectionTitle = styled('div')`
    opacity: 1;
    color: rgba(10, 59, 93, 1);
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
`;
