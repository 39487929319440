/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React, { memo } from 'react';
import { StyledStripTooltip } from '../MissionStrip.style';
import { Box, styled, Zoom } from '@mui/material';
import { FlightMissionStepsIndicator } from './StripFlightMissionSteps/FlightMissionStepsIndicator';
import { DispatcherMissionSlotDto } from '../../../../../backend/gen';
import { MissionStripStepsPreview } from '../MissionStripStepsPreview';

export const StripFlightSteps = memo(function StripFlightSteps({ mission }: { mission: DispatcherMissionSlotDto }) {
    return (
        <StyledStripTooltip
            title={
                <MissionStripStepsPreview
                    steps={mission.steps}
                    flow={mission.flight?.flow}
                    nbTours={mission.nbTours}
                    missionType={mission.type}
                />
            }
            followCursor={true}
            TransitionComponent={Zoom}
        >
            <StyledMissionStepsContainer>
                {[...Array(mission.nbTours)].map((_, i) => {
                    return (
                        <FlightMissionStepsIndicator
                            key={i}
                            tour={i + 1}
                            nbTours={mission.nbTours}
                            stepsTotal={mission.nbStepsTotal}
                            stepsDone={mission.nbStepsDone}
                            flow={mission.flight?.flow}
                        />
                    );
                })}
            </StyledMissionStepsContainer>
        </StyledStripTooltip>
    );
});

const StyledMissionStepsContainer = styled(Box)`
    display: flex;
    gap: 3px;
    margin-left: 6px;
    padding-top: 4px;
    position: relative;
    z-index: 3;
`;
