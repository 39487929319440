/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { Box, CircularProgress, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import React, { useCallback } from 'react';
import { useQueryGetExportMissions } from '../../../../backend/gen';
import { toast } from 'react-toastify';
import { errorToastConfig } from '../../../../utils/constants';
import { useUiValue } from '../../../../contexts/UiContext';
import { formatISO } from 'date-fns';
import { useCurrentUserClient, useCurrentUserClientTimezone } from '../../../../contexts/AuthContext.selectors';
import { utcToZonedTime } from 'date-fns-tz';

export function ExportMissionsHeaderButton({ from, to }: { from: number; to: number }) {
    const currentUserClient = useCurrentUserClient();
    const timezone = useCurrentUserClientTimezone();
    // Since startDayHourShift can make it so that the Gantt to timestamp is situated on day postOpsTo + 1,
    // we subtract it from the to date to be able to use the real user requested from-to
    const clientStartDayShiftInMs = currentUserClient?.startDayHourShift
        ? currentUserClient.startDayHourShift * (1000 * 60 * 60) + 1
        : 0;
    const postOpsToDateWithShift = to - clientStartDayShiftInMs;

    const isTactical = useUiValue('isTacticalView');
    const fromDateISO = from ? formatISO(utcToZonedTime(from, timezone), { representation: 'date' }) : undefined;
    const toDateISO = to
        ? formatISO(utcToZonedTime(postOpsToDateWithShift, timezone), { representation: 'date' })
        : undefined;

    const { isLoading: isLoadingExport, refetch: onClickExport } = useQueryGetExportMissions(
        {
            from: fromDateISO,
            to: toDateISO,
        },
        {
            enabled: false,
            onSuccess: ({ data }) => {
                if (data) {
                    const blob = new Blob([data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });

                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `missions_export_${fromDateISO}_${toDateISO}.xlsx`;
                    link.click();

                    window.URL.revokeObjectURL(link.href);
                }
            },
            onError: () => toast(<FormattedMessage id={'bus.error.exportMissions'} />, errorToastConfig),
        },
        {
            responseType: 'blob',
        },
    );

    const handleClickExport = useCallback(() => onClickExport(), [onClickExport]);

    if (isTactical) return null;
    return (
        <StyledPeriodDisplayContainer onClick={handleClickExport}>
            {isLoadingExport ? (
                <Box sx={{ mr: 1 }}>
                    <CircularProgress size={20} color="inherit" />
                </Box>
            ) : (
                <DownloadRoundedIcon />
            )}
            <FormattedMessage id={'dispatcher.exportMissions'} />
        </StyledPeriodDisplayContainer>
    );
}

const StyledPeriodDisplayContainer = styled(Box)`
    height: 50px;
    min-width: 100px;
    border-radius: 10px;
    background-color: #18537b;
    color: #ffffff;
    padding: 7px 7px 6px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ onClick }) => (onClick ? 'pointer' : '')};
`;
