/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { useCallback, useState } from 'react';
import { useBoolean } from '../../../../hooks/useBoolean';
import { useSetUi, useUiValue } from '../../../../contexts/UiContext';

export const useGanttPeriod = () => {
    const setIsTactical = useSetUi('isTacticalView');
    const isTactical = useUiValue('isTacticalView');

    const [requestFrom, setRequestFrom] = useState(() => Date.now());
    const [requestTo, setRequestTo] = useState(() => Date.now() + 24 * 60 * 60 * 1000);
    const [displayFrom, setDisplayFrom] = useState(requestFrom);
    const [displayTo, setDisplayTo] = useState(requestTo);
    const ganttPeriodDialogIsOpen = useBoolean(false);

    const setTactical = () => {
        setIsTactical(true);
        setRequestFrom(undefined);
        setRequestTo(undefined);
    };

    const setCustom = (from: number, to: number) => {
        setIsTactical(false);
        setRequestFrom(from);
        setRequestTo(to);
    };

    const updateDisplayRange = useCallback((from: number, to: number) => {
        setDisplayFrom(from);
        setDisplayTo(to);
    }, []);

    return {
        requestFrom,
        requestTo,
        displayFrom,
        displayTo,
        setTactical,
        setCustom,
        updateDisplayRange,
        ganttPeriodDialogIsOpen,
        isTactical,
    };
};
