/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { PeriodType } from '../BusUnavailabilityPeriodDialog/BusUnavailabilityPeriodDialog';
import React, { useCallback } from 'react';
import {
    BusVacationDto,
    DispatcherBusDto,
    KeyGetGetAllMissions,
    useMutationDeleteRemoveBusVacation,
    useMutationPostDeclareBusVacation,
} from '../../../../../backend/gen';
import { Button, buttonClasses, DialogActions } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ButtonProps } from '@mui/material/Button';
import { theme } from '../../../../../theme';
import { toast } from 'react-toastify';
import { errorToastConfig, successToastConfig } from '../../../../../utils/constants';
import { useSetUi } from '../../../../../contexts/UiContext';
import { useQueryClient } from 'react-query';
import { useZonedTimeConverter } from '../../../../../components/time/useZonedTimeConverter';

export function BusDefineVacationDialogActions({
    closeDialog,
    bus,
    period,
    name,
    comment,
    vacation,
}: {
    closeDialog: () => void;
    bus: DispatcherBusDto;
    period: PeriodType;
    name: string;
    comment?: string;
    vacation?: BusVacationDto;
}) {
    const { timestampToHHMM } = useZonedTimeConverter();
    const setSelectedVacationId = useSetUi('selectedVacationId');
    const queryClient = useQueryClient();
    const { mutateAsync: declareBusVacation } = useMutationPostDeclareBusVacation();
    const { mutateAsync: removeBusVacation } = useMutationDeleteRemoveBusVacation();

    const submissionDisabled = !period.start || !period.end || !name;

    const onClickHandleDeclareBusVacation = useCallback(() => {
        if (period.start && period.end) {
            // Backend handles case where period.end < period.start
            declareBusVacation({
                vacationUpdateDto: {
                    id: vacation?.id || null,
                    bus: bus.name,
                    start: timestampToHHMM(period.start),
                    end: timestampToHHMM(period.end),
                    driver: name,
                    comment: comment,
                },
            })
                .then(() => {
                    toast(
                        <FormattedMessage
                            id={'dispatcher.busDefineVacation.declarationSuccess'}
                            values={{ busName: bus.name, driverName: name }}
                        />,
                        successToastConfig,
                    );
                    closeDialog();
                    setSelectedVacationId(null);
                    queryClient.invalidateQueries(KeyGetGetAllMissions);
                })
                .catch(() => {
                    toast(<FormattedMessage id={'dispatcher.busDefineVacation.declarationFailed'} />, errorToastConfig);
                });
        }
    }, [
        bus.name,
        closeDialog,
        comment,
        declareBusVacation,
        name,
        period.end,
        period.start,
        queryClient,
        setSelectedVacationId,
        timestampToHHMM,
        vacation?.id,
    ]);

    const onClickHandleRemoveBusVacation = useCallback(() => {
        if (vacation) {
            removeBusVacation({ id: vacation.id })
                .then(() => {
                    closeDialog();
                    setSelectedVacationId(null);
                    queryClient.invalidateQueries(KeyGetGetAllMissions);
                })
                .catch(() => {
                    toast(<FormattedMessage id={'dispatcher.busDefineVacation.deletionFailed'} />, errorToastConfig);
                });
        }
    }, [closeDialog, queryClient, removeBusVacation, setSelectedVacationId, vacation]);

    const onClickCloseDialog = useCallback(() => {
        closeDialog();
        setSelectedVacationId(null);
    }, [closeDialog, setSelectedVacationId]);

    return (
        <DialogActions>
            <Button {...CancellationButtonProps} onClick={onClickCloseDialog}>
                <FormattedMessage id="confirm.default.cancel" />
            </Button>
            {vacation && (
                <Button {...DeletionButtonProps} onClick={onClickHandleRemoveBusVacation}>
                    <FormattedMessage id="dispatcher.busDefineVacation.delete" />
                </Button>
            )}
            <Button
                {...ConfirmationButtonProps}
                disabled={submissionDisabled}
                onClick={onClickHandleDeclareBusVacation}
            >
                <FormattedMessage id="confirm.default.confirm" />
            </Button>
        </DialogActions>
    );
}

const BusDefineVacationDialogButtonProps: Partial<ButtonProps> = {
    size: 'medium',
    sx: {
        borderRadius: '25px',
        padding: '16px 30px',
        letterSpacing: '0',
        textTransform: 'none',
        height: '50px',
        fontSize: '15px',
        lineHeight: '18px',
        whiteSpace: 'nowrap',
        [`&.${buttonClasses.text}`]: {
            border: '1px solid rgba(12, 69, 108, 0.5)',
        },
        [`&.${buttonClasses.outlined}`]: {
            color: theme => theme.palette.blue.lightest,
            borderColor: '#245f88',
        },
    },
};

const CancellationButtonProps: Partial<ButtonProps> = {
    ...BusDefineVacationDialogButtonProps,
    sx: {
        ...BusDefineVacationDialogButtonProps.sx,
        color: theme.palette.blue.dark,
    },
    variant: 'text',
};

const DeletionButtonProps: Partial<ButtonProps> = {
    ...BusDefineVacationDialogButtonProps,
    sx: {
        ...BusDefineVacationDialogButtonProps.sx,
        color: theme.palette.error.main,
        [`&.MuiButton-text`]: {
            border: `1px solid ${theme.palette.error.main}`,
        },
    },
    variant: 'text',
};

const ConfirmationButtonProps: Partial<ButtonProps> = {
    ...BusDefineVacationDialogButtonProps,
    sx: {
        ...BusDefineVacationDialogButtonProps.sx,
        [`&.Mui-disabled`]: {
            backgroundColor: theme.palette.blue.lightest,
        },
    },
    variant: 'contained',
};
