import { Box, styled } from '@mui/material';
import { HEADER_HEIGHT_IN_PX } from '../../../utils/constants';
import { ganttTitleWidth } from './Gantt/gantt.constants';

export const DispatcherPageContentContainer = styled(Box)`
    position: relative;
    height: calc(100% - ${HEADER_HEIGHT_IN_PX}px);
    color: white;
    overflow: auto;
`;

export const StyledGanttContainer = styled(Box)`
    display: flex;
    width: fit-content;
    padding-top: 10px;
    min-height: 100px;
`;

export const StyledCurrentTimeLine = styled(Box)`
    position: absolute;
    height: 100%;
    background-color: white;
    width: 1px;
    z-index: 3;
`;

export const StyledDateDelimiterLine = styled(Box)`
    position: absolute;
    height: 100%;
    width: 1px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='4' stroke-dasharray='4%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    z-index: 3;
`;

export const StyledDateDelimiterTextContainer = styled(Box)<{ ganttwidth: number }>(({ theme, ganttwidth }) => ({
    position: 'sticky',
    zIndex: 100,
    top: 0,
    paddingTop: '4px',
    height: '22px',
    width: `${ganttwidth + 20}px`,
    fontSize: '12px',
    backgroundColor: theme.palette.blue.lightBg,
}));

export const StyledCurrentVisibleDayText = styled(Box)`
    position: fixed;
    top: ${HEADER_HEIGHT_IN_PX}px;
    width: ${ganttTitleWidth + 50}px;
    text-align: end;
    background-color: ${({ theme }) => theme.palette.blue.lightBg};
    z-index: 200;
    padding-top: 4px;
    font-size: 12px;
`;
