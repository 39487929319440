/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { styled } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { VehicleReportInputRow } from './VehicleReportInputRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { theme } from '../../../../../../theme';
import { ReportItemDto } from '../../../../../../backend/gen';

export function VehicleReportBody({
    items,
    updateItem,
}: {
    items: ReportItemDto[];
    updateItem: (index: number, updatedItem: ReportItemDto) => void;
}) {
    const [showScrollArrow, setShowScrollArrow] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const setField = useCallback(
        (index: number, updatedField: Partial<ReportItemDto>) => {
            updateItem(index, { ...items[index], ...updatedField });
        },
        [items, updateItem],
    );

    useEffect(() => {
        const checkOverflow = () => {
            if (containerRef.current) {
                const { scrollHeight, clientHeight, scrollTop } = containerRef.current;
                setShowScrollArrow(scrollHeight > clientHeight);
                setIsAtBottom(scrollHeight - scrollTop <= clientHeight + 1);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, []);

    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollHeight, clientHeight, scrollTop } = containerRef.current;
            setIsAtBottom(scrollHeight - scrollTop <= clientHeight + 1);
        }
    };

    const handleScrollClick = () => {
        if (containerRef.current) {
            if (isAtBottom) {
                containerRef.current.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            } else {
                containerRef.current.scrollTo({
                    top: containerRef.current.scrollHeight,
                    behavior: 'smooth',
                });
            }
        }
    };

    return (
        <StyledContainer>
            <StyledScrollableContent ref={containerRef} onScroll={handleScroll}>
                {items.map((item, i) => (
                    <VehicleReportInputRow
                        key={i}
                        field={item}
                        setField={updatedField => setField(i, updatedField)}
                        index={i}
                    />
                ))}
            </StyledScrollableContent>
            {showScrollArrow && (
                <StyledScrollArrow onClick={handleScrollClick}>
                    {isAtBottom ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </StyledScrollArrow>
            )}
        </StyledContainer>
    );
}

const StyledContainer = styled('div')`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
`;

const StyledScrollableContent = styled('div')`
    flex: 1;
    overflow-y: auto;
    padding: 0 100px;
`;

const StyledScrollArrow = styled('div')`
    position: absolute;
    bottom: 30px;
    right: 30px;
    background-color: rgba(255, 255, 255, 0.8);
    color: ${theme.palette.text.primary};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;

    &:hover {
        background-color: rgba(255, 255, 255, 1);
    }
`;
