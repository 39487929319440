/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { DispatcherMissionSlotDto, FlightFlow } from '../../../../../../../backend/gen';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import React from 'react';
import { styled } from '@mui/material';

export function AirportResourceInfo({ mission }: { mission: Partial<DispatcherMissionSlotDto> }) {
    return !mission?.flight ? null : (
        <StyledDriverInfoContainer>
            {mission.flight?.flow === FlightFlow.ARR ? (
                <StyledRowDiv>
                    <StyledAirportResource>Pkg</StyledAirportResource>
                    <StyledFlightInfo>{mission.flight?.parking}</StyledFlightInfo>
                </StyledRowDiv>
            ) : (
                <StyledRowDiv>
                    <StyledAirportResource>Gate</StyledAirportResource>
                    <StyledFlightInfo>{mission.flight?.gate}</StyledFlightInfo>
                </StyledRowDiv>
            )}
            <ArrowRightAltIcon />
            {mission.flight?.flow === FlightFlow.ARR ? (
                <StyledRowDiv>
                    <StyledAirportResource>Zone</StyledAirportResource>
                    <StyledFlightInfo>{mission.flight?.zone || ''}</StyledFlightInfo>
                </StyledRowDiv>
            ) : (
                <StyledRowDiv>
                    <StyledAirportResource>Pkg</StyledAirportResource>
                    <StyledFlightInfo>{mission.flight?.parking || ''}</StyledFlightInfo>
                </StyledRowDiv>
            )}
        </StyledDriverInfoContainer>
    );
}

const StyledFlightInfo = styled(`div`)`
    color: rgba(8, 41, 64, 1);
    font-size: 20px;
    font-weight: 700;
    text-align: left;
`;
const StyledDriverInfoContainer = styled('div')`
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    border: 1px solid rgba(182, 210, 229, 1);
    min-width: 100%;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;
    min-height: 50px;
`;
export const StyledRowDiv = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const StyledAirportResource = styled('div')`
    color: #082940;
    font-size: 19px;
    font-weight: 400;
    margin-right: 5px;
`;
