/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React, { memo, useMemo } from 'react';
import { DispatcherMissionSlotDto, MissionState } from '../../../../../backend/gen';
import { Box, styled, Zoom } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import { StyledStripTooltip } from '../MissionStrip.style';
import { ganttPxPerMs } from '../../Gantt/gantt.constants';
import { STRIP_MINIMUM_WIDTH } from '../MissionStrip';

export const StripOther = memo(function StripOther({
    mission,
    color,
}: {
    mission: DispatcherMissionSlotDto;
    color: string;
}) {
    const isMissionTerminated = mission.state === MissionState.TERMINATED;
    const missionDurationAsGanttPxWidth = useMemo(
        () => (mission.endTime - mission.refTime) * ganttPxPerMs,
        [mission.endTime, mission.refTime],
    );
    const missionStripWidth = useMemo(
        () =>
            missionDurationAsGanttPxWidth >= STRIP_MINIMUM_WIDTH ? missionDurationAsGanttPxWidth : STRIP_MINIMUM_WIDTH,
        [missionDurationAsGanttPxWidth],
    );

    return (
        <StyledStripOtherBox
            sx={{
                backgroundColor: isMissionTerminated ? '#cccccc' : 'white',
                outline: mission.state === MissionState.IN_PROGRESS ? `3px solid ${color}` : 'none',
                width: `${missionStripWidth}px`,
            }}
        >
            <StyledStripTooltip title={mission.name}>
                <StyledMissionName>{mission.name}</StyledMissionName>
            </StyledStripTooltip>
            {mission.driverNote && (
                <StyledStripTooltip title={mission.driverNote} TransitionComponent={Zoom} placement={'bottom'}>
                    <CommentIcon
                        sx={{
                            color: color,
                            height: '20px',
                            width: '20px',
                            backgroundColor: isMissionTerminated ? '#cccccc' : 'white',
                            borderRadius: '2px',
                            marginRight: '-4px',
                        }}
                    />
                </StyledStripTooltip>
            )}
        </StyledStripOtherBox>
    );
});

const StyledStripOtherBox = styled(Box)`
    height: 30px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 0 15px;
    color: black;
    display: flex;
    gap: 3px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 3;
    font-weight: bolder;
`;

const StyledMissionName = styled('div')`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
