/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import {
    DispatcherMissionSlotDto,
    KeyGetGetAllMissions,
    OtherMissionUpdateDto,
    useMutationPatchUpdateOtherMission,
    useMutationPostCreateOtherMission,
} from '../../../../../../backend/gen';
import { Button, DialogActions } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import LoadingButton from '@mui/lab/LoadingButton';
import { ConfirmationButtonProps } from '../../../GanttPeriod/GanttPeriodChangeDialog/GanttPeriodChangeDialogActions';
import React, { useCallback } from 'react';
import { MissionFormDialogCancellationButtonProps } from '../../MissionFormDialogContentAndActionsContainer';
import { UseMutationOptions, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { errorToastConfig, successToastConfig } from '../../../../../../utils/constants';
import { useZonedTimeConverter } from '../../../../../../components/time/useZonedTimeConverter';

export function OtherMissionDialogActions({
    closeDialog,
    mission,
    isCreating,
}: {
    closeDialog: () => void;
    mission: Partial<DispatcherMissionSlotDto>;
    isCreating: boolean;
}) {
    const { timestampToHHMM } = useZonedTimeConverter();
    const queryClient = useQueryClient();
    const options: UseMutationOptions<any, any, any> = {
        onSuccess: async () => {
            await queryClient.invalidateQueries(KeyGetGetAllMissions);
            closeDialog();
            toast(<FormattedMessage id={'flightMissionForm.success'} />, successToastConfig);
        },
        onError: () => {
            toast(<FormattedMessage id={'dispatcher.createMissionRequestError'} />, errorToastConfig);
        },
    };
    const { mutateAsync: createOtherMission, isLoading: isLoadingPost } = useMutationPostCreateOtherMission(options);
    const { mutateAsync: updateOtherMission, isLoading: isLoadingPatch } = useMutationPatchUpdateOtherMission(options);

    function isFormValid(mission: Partial<DispatcherMissionSlotDto>) {
        return (
            mission?.name &&
            mission?.refTime &&
            mission?.endTime &&
            (mission?.steps?.length > 0 ? mission?.steps?.filter(step => !step.name)?.length === 0 : true)
        );
    }

    const onClickCreateMission = useCallback(() => {
        const otherMission: OtherMissionUpdateDto = {
            name: mission.name,
            steps: mission?.steps?.map(step => {
                return {
                    name: step.name,
                    time: timestampToHHMM(step.estimatedDoneTime),
                };
            }),
            startTime: timestampToHHMM(mission.refTime),
            endTime: timestampToHHMM(mission.endTime),
            comment: mission.comment,
            driverNote: mission.driverNote,
        };
        createOtherMission({ otherMissionUpdateDto: otherMission });
    }, [mission, createOtherMission, timestampToHHMM]);

    const onClickUpdateMission = useCallback(() => {
        const otherMission: OtherMissionUpdateDto = {
            name: mission.name,
            steps: mission?.steps?.map(step => {
                return {
                    name: step.name,
                    time: timestampToHHMM(step.estimatedDoneTime),
                };
            }),
            startTime: timestampToHHMM(mission.refTime),
            endTime: timestampToHHMM(mission.endTime),
            comment: mission.comment,
            driverNote: mission.driverNote,
        };
        updateOtherMission({ mission: mission.missionId, otherMissionUpdateDto: otherMission });
    }, [mission, updateOtherMission, timestampToHHMM]);

    return (
        <DialogActions>
            <Button {...MissionFormDialogCancellationButtonProps} onClick={closeDialog}>
                <FormattedMessage id="confirm.default.cancel" />
            </Button>
            <LoadingButton
                {...ConfirmationButtonProps}
                onClick={isCreating ? onClickCreateMission : onClickUpdateMission}
                loading={isCreating ? isLoadingPost : isLoadingPatch}
                disabled={isLoadingPost || isLoadingPatch || !isFormValid(mission)}
            >
                <FormattedMessage id={isCreating ? 'otherMissionForm.createMission' : 'otherMissionForm.editMission'} />
            </LoadingButton>
        </DialogActions>
    );
}
