/*
 * Copyright Innov'ATM all rights reserved. This software is the property of Innov'ATM and may not be used in any
 * manner except under a license agreement signed with Innov'ATM.
 */
export const stopPropagationCb = (cb?: (event: any) => any) => e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.nativeEvent) {
        e.nativeEvent.stopImmediatePropagation();
        e.nativeEvent.stopPropagation();
        e.nativeEvent.preventDefault();
    }
    if (cb) {
        cb(e);
    }
    return false;
};

export const stopPropagation = e => {
    e.stopPropagation();
    if (e.nativeEvent) {
        e.nativeEvent.stopImmediatePropagation();
        e.nativeEvent.stopPropagation();
    }
    return false;
};

export const onEnterKeyCb = (cb: () => void) => e => {
    if (cb && e.key === 'Enter') {
        cb();
    }
};
