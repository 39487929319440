import { Nav } from '../../../components/navigation/Nav/Nav';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { PageTitle } from '../../../components/navigation/Nav/PageTitle';
import { Header, LeftComponentsHeader, RightComponentsHeader } from '../../../components/navigation/Header/Header';
import { Clock } from '../../../components/navigation/ToggleUTCLocal/Clock';
import { noop } from '../../../utils/data.utils';

export const SettingsHeader = memo(function SettingsHeader() {
    return (
        <Header>
            <LeftComponentsHeader>
                <Nav>
                    <PageTitle>
                        <FormattedMessage id="nav.title.settings" />
                    </PageTitle>
                </Nav>
            </LeftComponentsHeader>
            <RightComponentsHeader>
                <Clock onClick={noop} />
            </RightComponentsHeader>
        </Header>
    );
});
