import React, { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { GanttTimeline } from './GanttTimeline';
import { GanttGrid } from './GanttGrid';
import { DispatcherBusDto, DispatcherMissionSlotDto, useQueryGetExportBusReports } from '../../../../backend/gen';
import {
    GanttContentBox,
    GanttFlightRow,
    StyledFlightVerticalLine,
    StyledGanttContent,
    StyledGanttTitle,
} from './Gantt.style';
import { bounded, generateGanttMissionRows } from './gantt.utils';
import { Box, styled, Tooltip, tooltipClasses, TooltipProps, Zoom } from '@mui/material';
import { ganttFlightRowHeight, ganttFlightRowMargin, ganttPxPerMs } from './gantt.constants';
import { errorToastConfig, HALF_HOUR } from '../../../../utils/constants';
import { getMissionPrimaryColor } from '../../../../utils/data.utils';
import { BusGanttMenu } from './BusGanttMenu/BusGanttMenu';
import { BusUnavailabilityPeriodDialog } from './BusUnavailabilityPeriodDialog/BusUnavailabilityPeriodDialog';
import { useBoolean } from '../../../../hooks/useBoolean';
import { BusUnavailabilityPeriodGanttRow } from './BusUnavailabilityPeriodGanttRow/BusUnavailabilityPeriodGanttRow';
import { useUiValue } from '../../../../contexts/UiContext';
import { BusDefineVacationDialog } from './BusDefineVacationDialog/BusDefineVacationDialog';
import { BusVacationGanttRow } from './BusVacationGanttRow/BusVacationGanttRow';
import { MissionStrip } from '../Strips/MissionStrip';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { BusRequestReportDialog } from './BusRequestReportDialog/BusRequestReportDialog';
import { RequestedVehicleReportMarker } from './RequestedVehicleReportMarker';
import { toast } from 'react-toastify';
import { formatISO } from 'date-fns';
import { useCurrentUserClient, useCurrentUserClientTimezone } from '../../../../contexts/AuthContext.selectors';
import { utcToZonedTime } from 'date-fns-tz';

export const Gantt = memo(function Gantt({
    buses,
    bus,
    titleBgColor,
    missionData,
    from,
    to,
    isUnallocated,
}: {
    buses: string[];
    bus?: DispatcherBusDto;
    titleBgColor: string;
    missionData: DispatcherMissionSlotDto[];
    from: number;
    to: number;
    isUnallocated?: boolean;
}) {
    const timezone = useCurrentUserClientTimezone();
    const intl = useIntl();
    const isTactical = useUiValue('isTacticalView');
    const selectedVacationId = useUiValue('selectedVacationId');
    const rows = generateGanttMissionRows(missionData).filter(row => row.length > 0);
    const unavailabilityPeriodDialogOpen = useBoolean(false);
    const defineVacationDialogOpen = useBoolean(false);
    const requestReportDialogOpen = useBoolean(false);
    const currentUserClient = useCurrentUserClient();
    // Since startDayHourShift can make it so that the Gantt to timestamp is situated on day postOpsTo + 1,
    // we subtract it from the to date to be able to use the real user requested from-to
    const clientStartDayShiftInMs = currentUserClient?.startDayHourShift
        ? currentUserClient.startDayHourShift * (1000 * 60 * 60) + 1
        : 0;
    const postOpsToDateWithShift = to - clientStartDayShiftInMs;

    const { refetch: downloadReports } = useQueryGetExportBusReports(
        {
            bus: bus?.name,
            from: isTactical ? undefined : formatISO(utcToZonedTime(from, timezone), { representation: 'date' }),
            to: isTactical
                ? undefined
                : formatISO(utcToZonedTime(postOpsToDateWithShift, timezone), { representation: 'date' }),
        },
        {
            enabled: false,
            onSuccess: ({ data }) => {
                if (data) {
                    const blob = new Blob([data], { type: 'application/zip' });
                    const url = window.URL.createObjectURL(blob);
                    const fileNameDates = isTactical
                        ? `${formatISO(utcToZonedTime(from, timezone), {
                              representation: 'date',
                          })}`
                        : `${formatISO(utcToZonedTime(from, timezone), {
                              representation: 'date',
                          })}_${formatISO(utcToZonedTime(postOpsToDateWithShift, timezone), {
                              representation: 'date',
                          })}`;
                    const fileName = `vehicle_reports_${bus?.name || 'bus'}_${fileNameDates}.zip`;

                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    // Clean up the URL object
                    window.URL.revokeObjectURL(url);
                }
            },
            onError: () =>
                toast(
                    <FormattedMessage id={'dispatcher.ganttBusMenu.downloadAvailableReportsFailure'} />,
                    errorToastConfig,
                ),
        },
        {
            responseType: 'blob',
        },
    );

    const hasOnDemandReport = useMemo(
        () => !isUnallocated && !!bus?.onDemandReportTodo,
        [bus?.onDemandReportTodo, isUnallocated],
    );

    return (
        <>
            <StyledGanttTitle bgcolor={titleBgColor} sx={{ paddingTop: bus ? '60px' : '15px' }}>
                <span className="busName">{bus ? bus.name : <FormattedMessage id="unallocated" />}</span>
                {isTactical && bus && (
                    <>
                        <BusUnavailabilityPeriodDialog
                            bus={bus}
                            open={unavailabilityPeriodDialogOpen.value}
                            closeDialog={unavailabilityPeriodDialogOpen.setFalse}
                        />
                        <BusDefineVacationDialog
                            bus={bus}
                            open={defineVacationDialogOpen.value}
                            closeDialog={defineVacationDialogOpen.setFalse}
                            vacationId={selectedVacationId}
                        />
                        <BusRequestReportDialog
                            busName={bus?.name || ''}
                            report={bus?.onDemandReportTodo}
                            open={requestReportDialogOpen.value}
                            closeDialog={requestReportDialogOpen.setFalse}
                        />
                        <BusGanttMenu
                            onClickOpenUnavailabilityPeriodDialog={unavailabilityPeriodDialogOpen.setTrue}
                            onClickOpenDefineVacationDialog={defineVacationDialogOpen.setTrue}
                            onClickOpenRequestReportDialog={requestReportDialogOpen.setTrue}
                            onClickDownloadVehicleReports={downloadReports}
                            vehicleReportRequest={bus?.onDemandReportTodo}
                            availableReports={bus?.nbOfCompletedReports}
                            isTactical={isTactical}
                        />
                        {!!bus?.nbOfMissingReports && (
                            <StyledTooltip
                                title={intl.formatMessage(
                                    { id: 'dispatcher.ganttBusInfo.missingReports' },
                                    { number: bus.nbOfMissingReports },
                                )}
                                TransitionComponent={Zoom}
                                placement={'bottom'}
                            >
                                <StyledSummarizeIcon />
                            </StyledTooltip>
                        )}
                    </>
                )}
                {!isTactical && bus && (
                    <BusGanttMenu
                        onClickDownloadVehicleReports={downloadReports}
                        vehicleReportRequest={bus?.onDemandReportTodo}
                        availableReports={bus?.nbOfCompletedReports}
                        isTactical={isTactical}
                    />
                )}
            </StyledGanttTitle>
            <StyledGanttContent>
                <GanttTimeline from={from} to={to} />
                <GanttContentBox>
                    <GanttGrid from={from} to={to} />
                    {hasOnDemandReport && (
                        <RequestedVehicleReportMarker
                            report={bus.onDemandReportTodo}
                            from={from}
                            onClickOpenRequestReportDialog={requestReportDialogOpen.setTrue}
                        />
                    )}
                    {bus?.unavailability && (
                        <BusUnavailabilityPeriodGanttRow
                            ganttFrom={from}
                            ganttTo={to}
                            unavailabilityStartTime={bus.unavailability.start}
                            unavailabilityEndTime={bus.unavailability.end}
                        />
                    )}
                    {rows?.length ? (
                        bus?.vacations?.map(vacation => (
                            <BusVacationGanttRow
                                key={vacation.id}
                                ganttFrom={from}
                                ganttTo={to}
                                vacation={vacation}
                                onClickOpenDefineVacationDialog={defineVacationDialogOpen.setTrue}
                            />
                        ))
                    ) : (
                        <GanttFlightRow>
                            {bus?.vacations?.map(vacation => (
                                <BusVacationGanttRow
                                    key={vacation.id}
                                    ganttFrom={from}
                                    ganttTo={to}
                                    vacation={vacation}
                                    onClickOpenDefineVacationDialog={defineVacationDialogOpen.setTrue}
                                />
                            ))}
                        </GanttFlightRow>
                    )}
                    {rows?.map((row, i) => (
                        <GanttFlightRow key={i}>
                            {row?.map((mission: DispatcherMissionSlotDto, j: React.Key) => {
                                const boundedStartTime = bounded(mission.refTime, from - HALF_HOUR, to + HALF_HOUR);
                                const left = (boundedStartTime - from) * ganttPxPerMs;
                                const lineColor = getMissionPrimaryColor(mission.type, mission.flight?.flow);
                                // Vertical line element height must equal distance from top of GanttGrid
                                // all the way to StripFlight. The calculation for this distance is as follows:
                                // height of gantt grid (20) +
                                // space around rows * # of rows +1 to avoid multiply by 0 first row (25 * (i + 1)) +
                                // height of a single row multiplied by amount of rows (25 * i)
                                const lineHeight = 20 + ganttFlightRowMargin * (i + 1) + ganttFlightRowHeight * i;

                                return (
                                    <React.Fragment key={j}>
                                        <StyledFlightVerticalLine
                                            bgcolor={lineColor}
                                            height={lineHeight}
                                            left={`${left}px`}
                                        />
                                        <Box left={`${left}px`} position={'absolute'}>
                                            <MissionStrip
                                                buses={buses}
                                                mission={mission}
                                                isUnallocated={isUnallocated}
                                            />
                                        </Box>
                                    </React.Fragment>
                                );
                            })}
                        </GanttFlightRow>
                    ))}
                </GanttContentBox>
            </StyledGanttContent>
        </>
    );
});

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: 'black',
        backgroundColor: 'white',
        borderRadius: '5px',
        boxShadow: '0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.2)',
        fontSize: '11px',
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '22px',
        padding: '10px',
        maxWidth: 'none',
    },
}));

const StyledSummarizeIcon = styled(SummarizeIcon)`
    margin-top: 10px;
    color: #db4343;
    background-color: white;
    border-radius: 5px;
`;
