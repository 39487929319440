import React, { memo, useCallback } from 'react';
import {
    Header,
    LeftComponentsHeader,
    MiddleComponentsHeader,
    RightComponentsHeader,
} from '../../../../components/navigation/Header/Header';
import { Nav } from '../../../../components/navigation/Nav/Nav';
import { Clock } from '../../../../components/navigation/ToggleUTCLocal/Clock';
import { ReactComponent as BusIcon } from '../../../../assets/icons/icon-bus.svg';
import { StyledBusContainer, StyledBusName } from './DriverMissionsPageHeader.style';
import { useNavigate } from 'react-router-dom';
import { ROUTE_DRIVER } from '../../../../routes';
import { styled } from '@mui/material';
import { SquareButton } from '../../../../components/Button/SquareButton/SquareButton';
import { FormattedMessage } from 'react-intl';
import { BusReportDto, ReportType } from '../../../../backend/gen';

export const DriverMissionsPageHeader = memo(function DriverMissionsPageHeader({
    bus,
    activeDrivers,
    openReport,
    reportsToDo,
}: {
    bus: string;
    activeDrivers: string[];
    openReport: (type: ReportType) => void;
    reportsToDo: BusReportDto[];
}) {
    const navigate = useNavigate();
    const navigateToBusSelection = useCallback(() => {
        navigate(ROUTE_DRIVER);
    }, [navigate]);

    const shouldReduceButtonText = reportsToDo && reportsToDo.length > 2;
    const isReportAvailable = useCallback(
        (type: ReportType) => reportsToDo.some(report => report.type === type),
        [reportsToDo],
    );

    return (
        <Header>
            <LeftComponentsHeader>
                <Nav />
                {activeDrivers.length > 0 && (
                    <StyledActiveDriversList>{activeDrivers.join(', ')}</StyledActiveDriversList>
                )}
            </LeftComponentsHeader>
            <MiddleComponentsHeader>
                <Clock />
            </MiddleComponentsHeader>
            <RightComponentsHeader>
                <StyledReportButtonsContainer>
                    {isReportAvailable(ReportType.ON_DEMAND) && (
                        <SquareButton
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => openReport(ReportType.ON_DEMAND)}
                        >
                            <FormattedMessage
                                id={'driver.vehicleReport.lightReport'}
                                values={{ fullLength: !shouldReduceButtonText }}
                            />
                        </SquareButton>
                    )}
                    {isReportAvailable(ReportType.DAILY) && (
                        <SquareButton
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => openReport(ReportType.DAILY)}
                        >
                            <FormattedMessage
                                id={'driver.vehicleReport.dailyReport'}
                                values={{ fullLength: !shouldReduceButtonText }}
                            />
                        </SquareButton>
                    )}
                    {isReportAvailable(ReportType.WEEKLY) && (
                        <SquareButton
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => openReport(ReportType.WEEKLY)}
                        >
                            <FormattedMessage
                                id={'driver.vehicleReport.weeklyReport'}
                                values={{ fullLength: !shouldReduceButtonText }}
                            />
                        </SquareButton>
                    )}
                </StyledReportButtonsContainer>
                <StyledBusContainer onClick={navigateToBusSelection}>
                    <StyledBusName>{bus}</StyledBusName>
                    <BusIcon />
                </StyledBusContainer>
            </RightComponentsHeader>
        </Header>
    );
});

const StyledActiveDriversList = styled('div')`
    font-size: 21px;
    font-family: 'PT Sans Narrow', sans-serif;
    display: flex;
    align-items: center;
`;

const StyledReportButtonsContainer = styled('div')`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 20px;
`;
