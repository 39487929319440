/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { DriverMissionSlotDto } from '../../../../../../backend/gen';
import { styled } from '@mui/material';
import { StyledStrip, StyledStripContainer } from '../../StripBus.style';
import { FormattedTime } from '../../../../../../components/time/FormattedTime';
import { theme } from '../../../../../../theme';
import { FormattedMessage } from 'react-intl';

export function OtherMissionStripBus({ mission }: { mission: DriverMissionSlotDto }) {
    return (
        <StyledStripContainer>
            <StyledOtherMissionStrip>
                <StyledMissionName>{mission?.name}</StyledMissionName>
                <StyledMissionDetails>
                    <StyledMissionTime>
                        <FormattedTime>{mission?.refTime}</FormattedTime>
                    </StyledMissionTime>
                    <StyledMissionSteps>
                        <FormattedMessage
                            id={'DriverMissionsPage.numberSteps'}
                            values={{ count: mission?.steps?.length }}
                        />
                    </StyledMissionSteps>
                </StyledMissionDetails>
            </StyledOtherMissionStrip>
        </StyledStripContainer>
    );
}

const StyledOtherMissionStrip = styled(StyledStrip)`
    width: 390px;
    flex-direction: column;
    justify-content: center;
    padding: 0 29px;
    gap: 5px;
`;

const StyledMissionName = styled('div')`
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledMissionDetails = styled('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: center;
`;

const StyledMissionTime = styled('div')`
    font-size: 30px;
    font-weight: bold;
    color: ${theme.palette.other.main};
`;

const StyledMissionSteps = styled('div')`
    font-size: 18px;
    align-self: center;
`;
