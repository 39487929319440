import { useCallback } from 'react';
import { useDateFnsFormat } from '../../hooks/useDateFnsFormat';
import { utcToZonedTime } from 'date-fns-tz';
import { useCurrentUserClientTimezone } from '../../contexts/AuthContext.selectors';

export const useFormattedTime = () => {
    const dateFormat = useDateFnsFormat();
    const timezone = useCurrentUserClientTimezone();

    return useCallback(
        (date: number | Date, format = 'HH:mm') => dateFormat(utcToZonedTime(date, timezone || ''), format),
        [dateFormat, timezone],
    );
};
