import { Box } from '@mui/material';
import React, { memo } from 'react';
import { ReactComponent as SchengenIcon } from '../../../../../../../assets/icons/icon-schengen-small.svg';
import { ReactComponent as GateIcon } from '../../../../../../../assets/icons/icon-gate-small.svg';
import { ReactComponent as ParkingIcon } from '../../../../../../../assets/icons/icon-parking-small.svg';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { DriverMissionSlotDto, FlightFlow } from '../../../../../../../backend/gen';
import { getMissionPrimaryColor } from '../../../../../../../utils/data.utils';
import {
    StyledStripDirectionContainer,
    StyledStripDirectionIconContainer,
    StyledStripDirectionIconsContainer,
} from './StripDirection.style';

export const StripDirection = memo(({ mission }: { mission: DriverMissionSlotDto }) => {
    const iconFillColor = getMissionPrimaryColor(mission.type, mission.flight?.flow);
    const isExceptional = mission.pickUpLocation;

    return (
        <StyledStripDirectionContainer>
            <StyledStripDirectionIconsContainer>
                {mission.flight?.flow === FlightFlow.ARR ? (
                    <StyledStripDirectionIconContainer>
                        <ParkingIcon fill={iconFillColor} width={'45px'} height={'45px'} />
                        <Box>{mission.flight?.parking || '-'}</Box>
                    </StyledStripDirectionIconContainer>
                ) : isExceptional ? (
                    <StyledStripDirectionIconContainer>
                        <PinDropIcon sx={{ color: iconFillColor, width: '45px', height: '45px' }} />
                        <Box>{'Pick-up'}</Box>
                    </StyledStripDirectionIconContainer>
                ) : (
                    <StyledStripDirectionIconContainer>
                        <GateIcon fill={iconFillColor} width={'45px'} height={'45px'} />
                        <Box>{mission.flight?.gate || '-'}</Box>
                    </StyledStripDirectionIconContainer>
                )}
                {mission.flight?.flow === FlightFlow.ARR ? (
                    <StyledStripDirectionIconContainer>
                        <SchengenIcon fill={iconFillColor} width={'45px'} height={'45px'} />
                        <Box>{mission.flight?.zone || '-'}</Box>
                    </StyledStripDirectionIconContainer>
                ) : isExceptional ? (
                    <StyledStripDirectionIconContainer>
                        <PinDropIcon sx={{ color: iconFillColor, width: '45px', height: '45px' }} />
                        <Box>{'Drop-off'}</Box>
                    </StyledStripDirectionIconContainer>
                ) : (
                    <StyledStripDirectionIconContainer>
                        <ParkingIcon fill={iconFillColor} width={'45px'} height={'45px'} />
                        <Box>{mission.flight?.parking || '-'}</Box>
                    </StyledStripDirectionIconContainer>
                )}
            </StyledStripDirectionIconsContainer>
        </StyledStripDirectionContainer>
    );
});
