import { Box, styled } from '@mui/material';
import { HEADER_HEIGHT_IN_PX } from '../../../utils/constants';
import { driverNextMissionsWidthInPx } from '../driver.utils';

export const StyledDriverMissionsPageContainer = styled(Box)`
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - ${HEADER_HEIGHT_IN_PX}px);
`;

export const StyledNoMissionsContainer = styled(Box)`
    text-align: center;
    height: 100%;
    width: -webkit-calc(100% - ${driverNextMissionsWidthInPx}px);
    width: -moz-calc(100% - ${driverNextMissionsWidthInPx}px);
    width: calc(100% - ${driverNextMissionsWidthInPx}px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
`;

export const StyledCurrentMissionContainer = styled(Box)`
    margin-left: auto;
    position: relative;
`;

export const StyledNextMissionsContainer = styled(Box)`
    margin-left: auto;
    margin-right: 0;
    width: ${driverNextMissionsWidthInPx}px;
    height: 100%;
    background-color: #245f88;
`;
