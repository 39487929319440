/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { styled } from '@mui/material';
import { DriverMissionSlotDto } from '../../../../../../backend/gen';
import { theme } from '../../../../../../theme';

export function CurrentMissionOtherInfo({ mission }: { mission: DriverMissionSlotDto }) {
    return (
        <StyledContainer>
            <span>{mission?.name}</span>
            <StyledDriverNote>{mission?.driverNote && mission.driverNote.toUpperCase()}</StyledDriverNote>
        </StyledContainer>
    );
}

const StyledContainer = styled('div')`
    color: ${theme.palette.text.primary};
    font-size: 21px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const StyledDriverNote = styled('span')`
    font-size: 14px;
    font-weight: normal;
`;
