/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { useState } from 'react';
import {
    BusReportConfirmDto,
    BusReportDto,
    KeyGetGetNextMissions,
    ReportItemDto,
    ReportType,
    useMutationPatchConfirmBusReport,
} from '../../../../backend/gen';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { errorToastConfig, successToastConfig } from '../../../../utils/constants';
import { useQueryClient } from 'react-query';

export function useVehicleReport(reportsToDo: BusReportDto[]) {
    const intl = useIntl();
    const queryClient = useQueryClient();

    const [isReportOpen, setIsReportOpen] = useState(false);
    const [currentReport, setCurrentReport] = useState<BusReportDto | null>(null);
    const [driverName, setDriverName] = useState('');

    const { mutateAsync: confirmBusReport } = useMutationPatchConfirmBusReport();

    function openReport(type: ReportType) {
        const reportToOpen = reportsToDo.find(report => report.type === type);
        if (reportToOpen) {
            setIsReportOpen(true);
            setCurrentReport(reportToOpen);
        } else {
            toast(intl.formatMessage({ id: 'driver.vehicleReport.failedToOpen' }), errorToastConfig);
        }
    }

    function closeReport() {
        setIsReportOpen(false);
        setCurrentReport(null);
        setDriverName('');
    }

    const handleSubmitReport = async (updatedItems: ReportItemDto[]) => {
        if (!currentReport) {
            toast(intl.formatMessage({ id: 'driver.vehicleReport.failedToOpen' }), errorToastConfig);
            return;
        }

        const confirmDto: BusReportConfirmDto = {
            id: currentReport.id,
            items: updatedItems,
            driverName: driverName,
        };

        await confirmBusReport({ busReportConfirmDto: confirmDto })
            .then(() => {
                toast(
                    intl.formatMessage({ id: 'driver.vehicleReport.submitSuccess' }, { type: currentReport.type }),
                    successToastConfig,
                );
                queryClient.invalidateQueries(KeyGetGetNextMissions);
                closeReport();
            })
            .catch(() => {
                toast(
                    intl.formatMessage({ id: 'driver.vehicleReport.submitFailure' }, { type: currentReport.type }),
                    errorToastConfig,
                );
            });
    };

    return {
        isReportOpen,
        currentReport,
        driverName,
        setDriverName,
        openReport,
        closeReport,
        handleSubmitReport,
    };
}
