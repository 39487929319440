import React, { memo, useEffect, useRef, useState } from 'react';
import { ReactComponent as EapLogo } from '../../../../assets/images/eap_logo.svg';
import {
    StyledBlink,
    StyledBusDisplayHeaderContainer,
    StyledBusDisplayTimeContainer,
    StyledEapLogoContainer,
} from './BusDisplayHeader.style';
import { FormattedTime } from '../../../../components/time/FormattedTime';

export const BusDisplayHeader = memo(function BusDisplayHeader() {
    const timeRef = useRef<NodeJS.Timer>();
    const [time, setTime] = useState(Date.now());

    useEffect(() => {
        timeRef.current = setInterval(() => setTime(Date.now()), 1000);
        return () => {
            clearInterval(timeRef.current);
        };
    }, []);

    return (
        <StyledBusDisplayHeaderContainer>
            <StyledBusDisplayTimeContainer>
                <FormattedTime format={'HH'}>{time}</FormattedTime>
                {<StyledBlink>:</StyledBlink>}
                <FormattedTime format={'mm'}>{time}</FormattedTime>
            </StyledBusDisplayTimeContainer>
            <StyledEapLogoContainer>
                <EapLogo width={'200px'} />
            </StyledEapLogoContainer>
        </StyledBusDisplayHeaderContainer>
    );
});
