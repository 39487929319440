import { DispatcherMissionSlotDto } from '../../../../backend/gen';
import { isWithinInterval } from 'date-fns';
import { ganttPxPerMs } from './gantt.constants';

export const bounded = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

const isMissionOverlappingCurrentRowMission = (
    mission: DispatcherMissionSlotDto,
    rowMission: DispatcherMissionSlotDto,
) => {
    const isRowMissionTypeOther = rowMission.type === 'OTHER' || rowMission.type === 'RECURRENT';
    const minimumWidth = 175;
    const rowMissionDurationAsGanttPxWidth = isRowMissionTypeOther
        ? (rowMission.endTime - rowMission.refTime) * ganttPxPerMs
        : 0;

    return isWithinInterval(mission.refTime, {
        start: rowMission.refTime,
        end: isRowMissionTypeOther
            ? rowMissionDurationAsGanttPxWidth <= minimumWidth
                ? rowMission.refTime + minimumWidth / ganttPxPerMs
                : rowMission.endTime + 50 / ganttPxPerMs
            : rowMission.refTime + minimumWidth / ganttPxPerMs,
    });
};

export const generateGanttMissionRows = (data: DispatcherMissionSlotDto[]) => {
    const rows: Array<DispatcherMissionSlotDto[]> = [[], [], [], [], [], [], [], [], [], [], [], [], []];

    if (!data) return [];

    for (const mission of data) {
        for (const row of rows) {
            let rowContainsOverlappingMission = false;
            for (const rowMission of row) {
                if (isMissionOverlappingCurrentRowMission(mission, rowMission)) {
                    rowContainsOverlappingMission = true;
                    break;
                }
            }
            if (!rowContainsOverlappingMission) {
                // @ts-ignore
                row.push(mission);
                break;
            }
        }
    }

    return rows;
};
