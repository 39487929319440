import React, { memo } from 'react';
import { DriverMissionSlotDto, FlightFlow } from '../../../../../../../backend/gen';
import { FormattedTime } from '../../../../../../../components/time/FormattedTime';
import { FormattedMessage } from 'react-intl';
import { getMissionPrimaryColor } from '../../../../../../../utils/data.utils';
import { StyledStripFlow, StyledStripTime, StyledStripTimeFlowContainer } from './StripTimeFlow.style';

export const StripTimeFlow = memo(function StripTimeFlow({ mission }: { mission: DriverMissionSlotDto }) {
    const flowColor = getMissionPrimaryColor(mission.type, mission.flight?.flow);

    return (
        <StyledStripTimeFlowContainer color={flowColor}>
            <StyledStripTime>
                <FormattedTime>{mission.refTime}</FormattedTime>
            </StyledStripTime>
            <StyledStripFlow>
                {mission.flight?.flow === FlightFlow.ARR ? (
                    <FormattedMessage id="arrival" />
                ) : (
                    <FormattedMessage id="departure" />
                )}
            </StyledStripFlow>
        </StyledStripTimeFlowContainer>
    );
});
