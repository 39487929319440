/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { DispatcherMissionSlotDto } from '../../../../../../backend/gen';
import { useMissionForm } from '../../../../../../contexts/MissionFormContext';
import React, { useCallback } from 'react';
import { Input, styled } from '@mui/material';
import { TimeField } from '../../../../../../components/form/TimeField/TimeField';
import { theme } from '../../../../../../theme';
import { OtherMissionNameStartTime } from './OtherMissionDialogContentComponents/OtherMissionNameStartTime';
import { OtherMissionSteps } from './OtherMissionDialogContentComponents/OtherMissionSteps';
import { OtherMissionAddStepEndTime } from './OtherMissionDialogContentComponents/OtherMissionAddStepEndTime';
import { OtherMissionNoteComment } from './OtherMissionDialogContentComponents/OtherMissionNoteComment';

export function OtherMissionDialogContent({ mission }: { mission: Partial<DispatcherMissionSlotDto> }) {
    const { updateFormMissionValues } = useMissionForm();

    const setMission = useCallback(
        (updatedMission: Partial<DispatcherMissionSlotDto>) => {
            updateFormMissionValues('otherMissionForm', updatedMission);
        },
        [updateFormMissionValues],
    );

    return (
        <>
            <OtherMissionNameStartTime mission={mission} setMission={setMission} />
            <OtherMissionSteps mission={mission} setMission={setMission} />
            <OtherMissionAddStepEndTime mission={mission} setMission={setMission} />
            <OtherMissionNoteComment mission={mission} setMission={setMission} />
        </>
    );
}

export const StyledOtherMissionDialogRow = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 40px;
    & .missionTimeLabelText {
        color: ${theme.palette.blue.darkest};
    }
`;

export const StyledOtherMissionTextInputContainer = styled('div')`
    height: 75px;
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: end;
    margin-bottom: 20px;
    & > span {
        position: absolute;
        top: 0;
        left: 1px;
        font-size: 14px;
        font-weight: normal;
    }
`;

export const StyledOtherMissionTextInput = styled(Input)`
    height: 50px;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    background-color: #f1f7fb;
`;

export const StyledOtherMissionTimeContainer = styled('div')`
    width: 120px;
`;

export const StyledOtherMissionTimePicker = styled(TimeField)`
    & .MuiFilledInput-root {
        background-color: #f1f7fb !important;
    }
    & .MuiInputBase-input {
        color: ${theme.palette.blue.darkest} !important;
        ::-webkit-calendar-picker-indicator {
            filter: invert(0) !important;
        }
    }
`;
