/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import {
    StyledOtherMissionDialogRow,
    StyledOtherMissionTextInput,
    StyledOtherMissionTextInputContainer,
    StyledOtherMissionTimeContainer,
    StyledOtherMissionTimePicker,
} from '../OtherMissionDialogContent';
import { DispatcherMissionSlotDto } from '../../../../../../../backend/gen';

export function OtherMissionNameStartTime({
    mission,
    setMission,
}: {
    mission: Partial<DispatcherMissionSlotDto>;
    setMission: (updatedMission: Partial<DispatcherMissionSlotDto>) => void;
}) {
    const intl = useIntl();
    const isMissionTodo = !mission?.state || mission.state === 'TODO';

    return (
        <StyledOtherMissionDialogRow>
            <StyledOtherMissionTextInputContainer>
                <FormattedMessage id={'otherMissionForm.missionName'} />
                <StyledOtherMissionTextInput
                    placeholder={intl.formatMessage({ id: 'otherMissionForm.missionName' })}
                    type={'text'}
                    disableUnderline
                    value={mission?.name || ''}
                    onChange={e => setMission({ name: e.target.value })}
                    disabled={!isMissionTodo}
                />
            </StyledOtherMissionTextInputContainer>
            <StyledOtherMissionTimeContainer>
                <StyledOtherMissionTimePicker
                    name={'startTime'}
                    date={new Date()}
                    value={mission?.refTime ?? null}
                    onChange={e => setMission({ refTime: e })}
                    required
                    label={
                        <span className={'missionTimeLabelText'}>
                            <FormattedMessage id={'otherMissionForm.startTime'} />
                        </span>
                    }
                    disabled={!isMissionTodo}
                />
            </StyledOtherMissionTimeContainer>
        </StyledOtherMissionDialogRow>
    );
}
