/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { DispatcherMissionSlotDto, RecurrentMissionDto } from '../backend/gen';

// Extend RecurrentMissionDto to include missionId.
// On the recurrent missions page, missions are of type RecurrentMissionDto and represent a mission template
// From which specific mission occurrences are created for the Gantt as DispatcherMissionSlotDto
// Use missionId presence to determine whether we are editing an occurrence (DispatcherMissionSlotDto)
// or a mission template (RecurrentMissionDto)
export type ExtendedRecurrentMissionDto = RecurrentMissionDto & {
    missionId?: string;
};

export interface MissionFormValues {
    flightMissionForm: DispatcherMissionSlotDto;
    otherMissionForm: DispatcherMissionSlotDto;
    recurrentMissionForm: ExtendedRecurrentMissionDto;
}

export interface MissionFormState<T> {
    isDialogOpen: boolean;
    mission: T | null;
    isCreating?: boolean; // Whether user is editing existing mission or creating a new one
}

export type MissionFormStateType =
    | {
          type: 'flightMissionForm';
          isDialogOpen: boolean;
          mission: DispatcherMissionSlotDto | null;
          isCreating?: boolean;
      }
    | {
          type: 'otherMissionForm';
          isDialogOpen: boolean;
          mission: DispatcherMissionSlotDto | null;
          isCreating?: boolean;
      }
    | {
          type: 'recurrentMissionForm';
          isDialogOpen: boolean;
          mission: ExtendedRecurrentMissionDto | null;
          isCreating?: boolean;
      };

interface MissionFormContextProps {
    openDialog: <K extends keyof MissionFormValues>(dialog: K, values: MissionFormValues[K]) => void;
    closeDialog: (dialog: keyof MissionFormValues) => void;
    updateFormValues: <K extends keyof MissionFormValues>(form: K, values: Partial<MissionFormStateType>) => void;
    updateFormMissionValues: <K extends keyof MissionFormValues>(
        form: K,
        values: Partial<MissionFormValues[K]>,
    ) => void;
    currentFormValues: MissionFormStateType;
}

const MissionFormContext = createContext<MissionFormContextProps | undefined>(undefined);

export const MissionFormProvider = ({ children }: { children: ReactNode }) => {
    const [flightMissionForm, setFlightMissionForm] = useState<MissionFormState<DispatcherMissionSlotDto>>({
        isDialogOpen: false,
        mission: null,
        isCreating: undefined,
    });
    const [otherMissionForm, setOtherMissionForm] = useState<MissionFormState<DispatcherMissionSlotDto>>({
        isDialogOpen: false,
        mission: null,
        isCreating: undefined,
    });
    const [recurrentMissionForm, setRecurrentMissionForm] = useState<MissionFormState<ExtendedRecurrentMissionDto>>({
        isDialogOpen: false,
        mission: null,
        isCreating: undefined,
    });

    const openDialog = useCallback(<K extends keyof MissionFormValues>(dialog: K, mission: MissionFormValues[K]) => {
        if (dialog === 'flightMissionForm')
            setFlightMissionForm({
                isDialogOpen: true,
                mission: mission as DispatcherMissionSlotDto,
                isCreating: !(mission as DispatcherMissionSlotDto)?.missionId,
            });
        if (dialog === 'otherMissionForm')
            setOtherMissionForm({
                isDialogOpen: true,
                mission: mission as DispatcherMissionSlotDto,
                isCreating: !(mission as DispatcherMissionSlotDto)?.missionId,
            });
        if (dialog === 'recurrentMissionForm')
            setRecurrentMissionForm({
                isDialogOpen: true,
                mission: mission as ExtendedRecurrentMissionDto,
                isCreating: !(mission as ExtendedRecurrentMissionDto)?.id,
            });
    }, []);

    const closeDialog = useCallback((dialog: keyof MissionFormValues) => {
        if (dialog === 'flightMissionForm')
            setFlightMissionForm({ isDialogOpen: false, mission: null, isCreating: undefined });
        if (dialog === 'otherMissionForm')
            setOtherMissionForm({ isDialogOpen: false, mission: null, isCreating: undefined });
        if (dialog === 'recurrentMissionForm')
            setRecurrentMissionForm({ isDialogOpen: false, mission: null, isCreating: undefined });
    }, []);

    const updateFormValues = useCallback(
        <K extends keyof MissionFormValues>(form: K, values: Partial<MissionFormStateType>) => {
            if (form === 'flightMissionForm') {
                const newValues = {
                    ...values,
                    mission: values.mission as DispatcherMissionSlotDto,
                };
                return setFlightMissionForm(prev => ({
                    ...prev,
                    ...newValues,
                }));
            }
            if (form === 'otherMissionForm') {
                const newValues = {
                    ...values,
                    mission: values.mission as DispatcherMissionSlotDto,
                };
                return setOtherMissionForm(prev => ({
                    ...prev,
                    ...newValues,
                }));
            }
            if (form === 'recurrentMissionForm') {
                const newValues = {
                    ...values,
                    mission: values.mission as ExtendedRecurrentMissionDto,
                };
                return setRecurrentMissionForm(prev => ({
                    ...prev,
                    ...newValues,
                }));
            }
        },
        [],
    );

    const updateFormMissionValues = useCallback(
        <K extends keyof MissionFormValues>(form: K, mission: Partial<MissionFormValues[K]>) => {
            if (form === 'flightMissionForm')
                setFlightMissionForm(prev => ({
                    ...prev,
                    mission: { ...prev.mission, ...mission } as DispatcherMissionSlotDto,
                }));
            if (form === 'otherMissionForm')
                setOtherMissionForm(prev => ({
                    ...prev,
                    mission: { ...prev.mission, ...mission } as DispatcherMissionSlotDto,
                }));
            if (form === 'recurrentMissionForm')
                setRecurrentMissionForm(prev => ({
                    ...prev,
                    mission: { ...prev.mission, ...mission } as ExtendedRecurrentMissionDto,
                }));
        },
        [],
    );

    const currentFormValues: MissionFormStateType = useMemo(() => {
        if (flightMissionForm.isDialogOpen) return { type: 'flightMissionForm', ...flightMissionForm };
        if (otherMissionForm.isDialogOpen) return { type: 'otherMissionForm', ...otherMissionForm };
        if (
            recurrentMissionForm?.mission?.id ||
            recurrentMissionForm?.mission?.missionId ||
            recurrentMissionForm?.isCreating
        )
            return { type: 'recurrentMissionForm', ...recurrentMissionForm };
        return null;
    }, [flightMissionForm, otherMissionForm, recurrentMissionForm]);

    return (
        <MissionFormContext.Provider
            value={{
                openDialog,
                closeDialog,
                updateFormValues,
                updateFormMissionValues,
                currentFormValues,
            }}
        >
            {children}
        </MissionFormContext.Provider>
    );
};

export const useMissionForm = () => {
    const context = useContext(MissionFormContext);
    if (!context) {
        throw new Error('useMissionForm must be used within a MissionFormProvider');
    }
    return context;
};
