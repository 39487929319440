/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import AddIcon from '@mui/icons-material/Add';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import {
    StyledOtherMissionDialogRow,
    StyledOtherMissionTimeContainer,
    StyledOtherMissionTimePicker,
} from '../OtherMissionDialogContent';
import { styled } from '@mui/material';
import { SquareButton } from '../../../../../../../components/Button/SquareButton/SquareButton';
import { DispatcherMissionSlotDto } from '../../../../../../../backend/gen';

export function OtherMissionAddStepEndTime({
    mission,
    setMission,
}: {
    mission: Partial<DispatcherMissionSlotDto>;
    setMission: (updatedMission: Partial<DispatcherMissionSlotDto>) => void;
}) {
    const isMissionTodo = !mission?.state || mission.state === 'TODO';

    return (
        <StyledOtherMissionDialogRow>
            <StyledAddStepButton
                color="primary"
                size="small"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() =>
                    setMission({
                        steps: [
                            ...(mission?.steps || []),
                            { name: '', done: false, descriptionEng: '', descriptionFr: '', estimatedDoneTime: null },
                        ],
                    })
                }
                disabled={!isMissionTodo}
            >
                <FormattedMessage id={'otherMissionForm.addStep'} />
            </StyledAddStepButton>
            <StyledOtherMissionTimeContainer>
                <StyledOtherMissionTimePicker
                    name={'endTime'}
                    date={new Date()}
                    value={mission?.endTime ?? null}
                    onChange={e =>
                        setMission({
                            endTime: e,
                        })
                    }
                    required
                    label={
                        <span className={'missionTimeLabelText'}>
                            <FormattedMessage id={'otherMissionForm.endTime'} />
                        </span>
                    }
                    disabled={!isMissionTodo}
                />
            </StyledOtherMissionTimeContainer>
        </StyledOtherMissionDialogRow>
    );
}

const StyledAddStepButton = styled(SquareButton)`
    color: white !important;
`;
