/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { Box, styled } from '@mui/material';
import { CurrentMissionFlightInfo } from './CurrentMissionFlightInfo';
import { CurrentMissionFlightSteps } from './CurrentMissionFlightSteps';
import React from 'react';
import { DriverMissionSlotDto } from '../../../../../../backend/gen';

export function DriverCurrentFlightMission({ mission }: { mission: DriverMissionSlotDto }) {
    return (
        <StyledMissionContainer>
            <CurrentMissionFlightInfo driverCurrentMission={mission} />
            <CurrentMissionFlightSteps driverCurrentMission={mission} />
        </StyledMissionContainer>
    );
}

const StyledMissionContainer = styled(Box)`
    display: flex;
    align-items: flex-start;
`;
