/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { styled } from '@mui/material';
import { ReactComponent as MissionListArrow } from '../../../../../assets/images/arrow-mission-list.svg';
import { FormattedMessage } from 'react-intl';

export function NoCurrentRecurrentMission() {
    return (
        <StyledContainer>
            <FormattedMessage id={'dispatcher.recurrent.noMissionsCta'} />
            <StyledMissionListArrow />
        </StyledContainer>
    );
}

const StyledContainer = styled('div')`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 24px;
    color: white;
    margin-top: 50px;
    gap: 60px;
`;

const StyledMissionListArrow = styled(MissionListArrow)`
    transform: scaleX(-1);
`;
