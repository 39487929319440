/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { GanttPeriodChangeDialogActions } from './GanttPeriodChangeDialogActions';
import React, { useCallback, useMemo, useState } from 'react';
import { GanttPeriodChangeDialogContent } from './GanttPeriodChangeDialogContent';
import { FormattedMessage } from 'react-intl';
import { Box, styled } from '@mui/material';
import { theme } from '../../../../../theme';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/Icon-Information.svg';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useCurrentUserClient, useCurrentUserClientTimezone } from '../../../../../contexts/AuthContext.selectors';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

interface GanttPeriodChangeDialogContentAndActionsContainerProps {
    closeDialog: () => void;
    from: number;
    to: number;
    setTactical: () => void;
    setCustom: (from: number, to: number) => void;
}

export function GanttPeriodChangeDialogContentAndActionsContainer({
    closeDialog,
    from,
    to,
    setTactical,
    setCustom,
}: GanttPeriodChangeDialogContentAndActionsContainerProps) {
    const currentUserClient = useCurrentUserClient();
    const timezone = useCurrentUserClientTimezone();
    const [today] = useState(() => utcToZonedTime(new Date(), timezone || 'UTC'));

    const [formFrom, setFormFrom] = useState<number>(from);
    const [formTo, setFormTo] = useState<number>(to);
    // Since startDayHourShift can make it so that the Gantt to timestamp is situated on day postOpsTo + 1,
    // we subtract it from the to date to be able to show the real user requested from-to
    const clientStartDayShiftInMs = currentUserClient?.startDayHourShift
        ? currentUserClient.startDayHourShift * (1000 * 60 * 60) + 1
        : 0;

    const onClickSetTactical = useCallback(() => {
        setTactical();
        closeDialog();
    }, [closeDialog, setTactical]);

    const onSubmitSetCustom = useCallback(() => {
        const zonedToday = startOfDay(today);
        const utcToday = zonedTimeToUtc(zonedToday, timezone || 'UTC');

        if (formTo > utcToday.getTime()) {
            const zonedYesterday = subDays(zonedToday, 1);
            const utcYesterday = zonedTimeToUtc(endOfDay(zonedYesterday), timezone || 'UTC');
            setCustom(formFrom, utcYesterday.getTime());
        } else {
            setCustom(formFrom, formTo - clientStartDayShiftInMs);
        }
        closeDialog();
    }, [today, timezone, formTo, closeDialog, setCustom, formFrom, clientStartDayShiftInMs]);

    const datesIncoherent = useMemo(() => {
        return formFrom > formTo || !formFrom || !formTo;
    }, [formFrom, formTo]);

    return (
        <>
            <GanttPeriodChangeDialogContent
                formFrom={formFrom}
                formTo={formTo}
                setFormFrom={setFormFrom}
                setFormTo={setFormTo}
                onClickSetTactical={onClickSetTactical}
            />
            <GanttPeriodChangeDialogActions
                closeDialog={closeDialog}
                onSubmitSetCustom={onSubmitSetCustom}
                disabled={datesIncoherent}
            />
            {datesIncoherent && (
                <StyledPeriodErrorInfo className="invalid">
                    <StyledInfoIcon />
                    <FormattedMessage id={'dispatcher.ganttPeriod.incoherentDates'} />
                </StyledPeriodErrorInfo>
            )}
        </>
    );
}

const StyledPeriodErrorInfo = styled(Box)`
    font-size: 12px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    &.invalid {
        color: ${theme.palette.error.main};
        svg {
            stroke: ${theme.palette.error.main};
        }
    }
`;

const StyledInfoIcon = styled(InfoIcon)`
    stroke: gray;
    width: 30px;
    height: 30px;
`;
