import { Box, styled } from '@mui/material';
import { StyledStripSegment } from '../../../StripBus.style';

export const StyledStripTimeFlowContainer = styled(StyledStripSegment)`
    width: 135px;
    height: 115px;
    border-radius: 10px 0 0 10px;
`;

export const StyledStripTime = styled(Box)`
    font-size: 30px;
    font-weight: bold;
`;

export const StyledStripFlow = styled(Box)`
    font-size: 18px;
    text-transform: uppercase;
`;
