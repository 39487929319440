/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { NoCurrentRecurrentMission } from './NoCurrentRecurrentMission';
import { Input, styled } from '@mui/material';
import { ExtendedRecurrentMissionDto, useMissionForm } from '../../../../../contexts/MissionFormContext';
import { FormattedMessage } from 'react-intl';
import { RecurrentNameStartTime } from './RecurrentNameStartTime';
import { useCallback } from 'react';
import { RecurrentMissionSteps } from './RecurrentMissionSteps';
import { RecurrentAddStepEndTime } from './RecurrentAddStepEndTime';
import { RecurrentNotePeriod } from './RecurrentNotePeriod';
import { theme } from '../../../../../theme';
import { TimeField } from '../../../../../components/form/TimeField/TimeField';

export function CurrentRecurrentMissionForm({ mission }: { mission: Partial<ExtendedRecurrentMissionDto> }) {
    const { currentFormValues, updateFormMissionValues } = useMissionForm();

    const setMission = useCallback(
        (updatedMission: Partial<ExtendedRecurrentMissionDto>) => {
            updateFormMissionValues('recurrentMissionForm', updatedMission);
        },
        [updateFormMissionValues],
    );

    if (!currentFormValues?.isCreating && !mission?.missionId && !mission?.id) return <NoCurrentRecurrentMission />;
    return (
        <StyledContainer>
            <FormattedMessage
                id={
                    currentFormValues?.isCreating
                        ? 'dispatcher.recurrent.newMissionTitle'
                        : 'dispatcher.recurrent.editMissionTitle'
                }
            />
            <StyledFormSection>
                <div>
                    <RecurrentNameStartTime mission={mission} setMission={setMission} />
                    <RecurrentMissionSteps mission={mission} setMission={setMission} />
                    <RecurrentAddStepEndTime mission={mission} setMission={setMission} />
                </div>
                <div>
                    <RecurrentNotePeriod mission={mission} setMission={setMission} />
                </div>
            </StyledFormSection>
        </StyledContainer>
    );
}

const StyledContainer = styled('div')`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 24px;
    color: white;
    margin-top: 50px;
    gap: 60px;
`;

const StyledFormSection = styled('div')`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    overflow: auto;
`;

export const StyledRecurrentMissionDialogRow = styled('div')`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 40px;
    & .missionTimeLabelText {
        color: white;
    }
`;

export const StyledRecurrentMissionTextInputContainer = styled('div')`
    height: 75px;
    width: 300px;
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: end;
    margin-bottom: 20px;
    & > span {
        position: absolute;
        top: 0;
        left: 1px;
        font-size: 14px;
        font-weight: normal;
    }
    .MuiInput-input::-webkit-input-placeholder {
        opacity: 0.75;
    }
`;

export const StyledRecurrentMissionTextInput = styled(Input)`
    height: 50px;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    color: white;
    background-color: ${theme.palette.blue.lighter};
`;

export const StyledRecurrentMissionTimeContainer = styled('div')`
    width: 120px;
`;

export const StyledRecurrentMissionTimePicker = styled(TimeField)`
    & .MuiFilledInput-root {
        background-color: ${theme.palette.blue.lighter} !important;
    }
`;
