/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { ExtendedRecurrentMissionDto } from '../../../../../contexts/MissionFormContext';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import {
    StyledRecurrentMissionDialogRow,
    StyledRecurrentMissionTextInput,
    StyledRecurrentMissionTextInputContainer,
} from './CurrentRecurrentMissionForm';
import { styled } from '@mui/material';
import { DayOfWeek } from '../../../../../backend/gen';
import { RecurrentPeriodCheckbox } from './RecurrentPeriodCheckbox';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { theme } from '../../../../../theme';

export function RecurrentNotePeriod({
    mission,
    setMission,
}: {
    mission: Partial<ExtendedRecurrentMissionDto>;
    setMission: (updatedMission: Partial<ExtendedRecurrentMissionDto>) => void;
}) {
    const intl = useIntl();

    const formInvalidWarningFormattedMessageKey = !mission?.name
        ? 'dispatcher.recurrent.invalid.name'
        : !mission?.startTime
        ? 'dispatcher.recurrent.invalid.startTime'
        : !mission?.endTime
        ? 'dispatcher.recurrent.invalid.endTime'
        : !(mission?.steps?.length > 0 ? mission?.steps?.filter(step => !step.name)?.length === 0 : true)
        ? 'dispatcher.recurrent.invalid.stepName'
        : !mission?.pattern || mission.pattern.length === 0
        ? 'dispatcher.recurrent.invalid.period'
        : null;

    return (
        <>
            <StyledRecurrentMissionDialogRow>
                <StyledRecurrentMissionTextInputContainer>
                    <FormattedMessage id={'otherMissionForm.driverNote'} />
                    <StyledRecurrentMissionTextInput
                        placeholder={intl.formatMessage({ id: 'otherMissionForm.driverNote' })}
                        type={'text'}
                        disableUnderline
                        value={mission?.driverNote || ''}
                        onChange={e => setMission({ driverNote: e.target.value })}
                    />
                </StyledRecurrentMissionTextInputContainer>
            </StyledRecurrentMissionDialogRow>
            <StyledRecurrentMissionDialogRow>
                <StyledPeriodCheckboxes>
                    {Object.values(DayOfWeek).map(day => (
                        <RecurrentPeriodCheckbox key={day} day={day} mission={mission} setMission={setMission} />
                    ))}
                </StyledPeriodCheckboxes>
            </StyledRecurrentMissionDialogRow>
            <StyledInfoNote>
                <InfoOutlinedIcon />
                <FormattedMessage id={'dispatcher.recurrent.propagationInfo'} />
            </StyledInfoNote>
            {formInvalidWarningFormattedMessageKey && (
                <StyledInfoNote style={{ color: theme.palette.error.main }}>
                    <InfoOutlinedIcon />
                    <FormattedMessage id={formInvalidWarningFormattedMessageKey} />
                </StyledInfoNote>
            )}
        </>
    );
}

const StyledPeriodCheckboxes = styled('div')`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    width: 370px;
`;

const StyledInfoNote = styled('div')`
    width: 370px;
    margin-top: 20px;
    font-size: 13px;
    font-weight: normal;
    display: flex;
    align-items: flex-start;
    gap: 5px;
`;
