import { IcaoIata } from '../../../../../../../components/IcaoIata';
import { DriverMissionSlotDto } from '../../../../../../../backend/gen';
import { Box } from '@mui/material';
import React, { memo } from 'react';
import { ReactComponent as AirlineSeatReclineExtraIcon } from '../../../../../../../assets/icons/icon-passenger-light-bus.svg';
import { ReactComponent as StaffIcon } from '../../../../../../../assets/icons/icon-staff.svg';
import { ReactComponent as AircraftIcon } from '../../../../../../../assets/icons/icon-plane-light-bus.svg';
import { getMissionPrimaryColor } from '../../../../../../../utils/data.utils';
import { StyledAircraftPaxInfoBox, StyledAircraftPaxInfoContainer } from './StripAircraftPaxInfos.style';

export const StripAircraftPaxInfos = memo(({ mission }: { mission: DriverMissionSlotDto }) => {
    const iconFillColor = getMissionPrimaryColor(mission.type, mission.flight?.flow);
    const isStaffMission = mission.staffNum;

    return (
        <StyledAircraftPaxInfoContainer>
            <StyledAircraftPaxInfoBox>
                <AircraftIcon fill={iconFillColor} width={'45px'} height={'45px'} />
                <Box>{<IcaoIata {...mission.flight?.acType} /> || '-'}</Box>
            </StyledAircraftPaxInfoBox>
            <StyledAircraftPaxInfoBox>
                {isStaffMission ? (
                    <>
                        <StaffIcon fill={iconFillColor} width={'45px'} height={'45px'} />
                        <Box>{mission.staffNum || '-'}</Box>
                    </>
                ) : (
                    <>
                        <AirlineSeatReclineExtraIcon fill={iconFillColor} width={'45px'} height={'45px'} />
                        <Box>{mission.flight?.pax || '-'}</Box>
                    </>
                )}
            </StyledAircraftPaxInfoBox>
        </StyledAircraftPaxInfoContainer>
    );
});
