import { FormattedMessage } from 'react-intl';
import { DriverMissionSlotDto } from '../../../../backend/gen';
import { StripBus } from '../StripBus/StripBus';
import { StyledNextMissionsList, StyledNextMissionsTitle, StyledNoMissionsContainer } from './DriverNextMissions.style';
import { Box, Typography } from '@mui/material';
import React from 'react';

const sleepyBusImg = require('../../../../assets/images/Bus_Sleepy_Small.png');

export const DriverNextMissions = function DriverNextMissions({
    driverNextMissions,
    onClickMission,
}: {
    driverNextMissions?: DriverMissionSlotDto[];
    onClickMission?: (mission: DriverMissionSlotDto | null) => void;
}) {
    return (
        <>
            <StyledNextMissionsTitle>
                <FormattedMessage id="DriverNextMissionsPanel.nextMissions" />
            </StyledNextMissionsTitle>
            <StyledNextMissionsList>
                {driverNextMissions && driverNextMissions.length > 0 ? (
                    driverNextMissions.map(mission => (
                        <Box key={mission.missionId} onClick={onClickMission ? () => onClickMission(mission) : null}>
                            <StripBus mission={mission} />
                        </Box>
                    ))
                ) : (
                    <StyledNoMissionsContainer>
                        <img alt={'No missions available'} src={sleepyBusImg} />
                        <Typography variant={'h5'}>
                            <FormattedMessage id="DriverMissionsPage.noMissionsForNow" />
                        </Typography>
                    </StyledNoMissionsContainer>
                )}
            </StyledNextMissionsList>
        </>
    );
};
