/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { checkboxClasses, styled } from '@mui/material';
import { ExtendedRecurrentMissionDto } from '../../../../../contexts/MissionFormContext';
import { useCallback } from 'react';
import { Checkbox } from '../../../../../components/form/Checkbox/Checkbox';
import { DayOfWeek } from '../../../../../backend/gen';
import { theme } from '../../../../../theme';
import { useIntl } from 'react-intl';

function toggleArrayValue<T>(array: T[], value: T): T[] {
    const index = array.indexOf(value);
    if (index === -1) {
        return [...array, value];
    } else {
        return array.filter(item => item !== value);
    }
}

export function RecurrentPeriodCheckbox({
    day,
    mission,
    setMission,
}: {
    day: DayOfWeek;
    mission: Partial<ExtendedRecurrentMissionDto>;
    setMission: (updatedMission: Partial<ExtendedRecurrentMissionDto>) => void;
}) {
    const intl = useIntl();

    const onClickTogglePeriodDay = useCallback(() => {
        setMission({ pattern: toggleArrayValue(mission?.pattern || [], day) });
    }, [day, mission?.pattern, setMission]);

    return (
        <StyledContainer>
            <Checkbox
                label={intl.formatMessage({ id: `dayOfWeek.${day}` })}
                onClick={onClickTogglePeriodDay}
                checked={mission?.pattern ? mission.pattern.includes(day) : false}
            />
        </StyledContainer>
    );
}

const StyledContainer = styled('div')`
    display: flex;
    & .${checkboxClasses.root} {
        background-color: ${theme.palette.blue.lighter} !important;
    }
`;
