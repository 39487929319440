import { Box } from '@mui/material';
import React, { memo, useCallback, useState } from 'react';
import { StyledToursMenuItem, StyledToursMenuItemControls } from './MissionStripMenuContentsToursMenuItem.style';
import { FormattedMessage } from 'react-intl';

export const MissionStripMenuContentsToursMenuItem = memo(function MissionStripMenuContentsToursMenuItem({
    tours,
    onConfirm,
    isMissionTerminated,
}: {
    tours: number;
    onConfirm: (tours: number) => void;
    isMissionTerminated: boolean;
}) {
    const [updatedNbTours, setUpdatedNbTours] = useState(tours);

    const onClickSubtractOneTour = useCallback(() => {
        if (updatedNbTours > 1) {
            setUpdatedNbTours(updatedNbTours - 1);
        }
    }, [updatedNbTours]);

    const onClickAddOneTour = useCallback(() => {
        setUpdatedNbTours(updatedNbTours + 1);
    }, [updatedNbTours]);

    const onClickConfirm = useCallback(() => {
        onConfirm(updatedNbTours);
    }, [onConfirm, updatedNbTours]);

    return (
        <StyledToursMenuItem>
            <Box>
                <FormattedMessage id={'stripFlight.menu.busRounds'} />
            </Box>
            <StyledToursMenuItemControls>
                {!isMissionTerminated && <Box onClick={onClickSubtractOneTour}>-</Box>}
                {updatedNbTours}
                <Box onClick={onClickAddOneTour}>+</Box>
                <Box onClick={onClickConfirm}>OK</Box>
            </StyledToursMenuItemControls>
        </StyledToursMenuItem>
    );
});
