/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { DispatcherMissionSlotDto } from '../../../../../../../backend/gen';
import React from 'react';
import { Input, styled } from '@mui/material';

export function CommentInput({
    mission,
    setMission,
}: {
    mission: Partial<DispatcherMissionSlotDto>;
    setMission: React.Dispatch<React.SetStateAction<Partial<DispatcherMissionSlotDto>>>;
}) {
    return !mission?.flight ? null : (
        <>
            <StyledSectionTitle>Comment (report)</StyledSectionTitle>
            <StyledInput
                placeholder="free text for the report"
                disableUnderline
                onChange={event => setMission({ ...mission, comment: event.target.value })}
                value={mission?.comment || ''}
            ></StyledInput>
        </>
    );
}
const StyledSectionTitle = styled('div')`
    opacity: 1;
    color: rgba(10, 59, 93, 1);
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
`;

const StyledInput = styled(Input)`
    min-width: 100%;
    height: 50px;
    border-radius: 5px;
    background-color: #f1f7fb;
    padding: 10px;
    color: #0a3b5d;
    margin-bottom: 20px;
`;
