/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { Header, LeftComponentsHeader, RightComponentsHeader } from '../../../components/navigation/Header/Header';
import { Nav } from '../../../components/navigation/Nav/Nav';
import { PageTitle } from '../../../components/navigation/Nav/PageTitle';
import { FormattedMessage } from 'react-intl';
import { Clock } from '../../../components/navigation/ToggleUTCLocal/Clock';
import { noop } from '../../../utils/data.utils';
import React from 'react';

export function RecurrentMissionsHeader() {
    return (
        <Header>
            <LeftComponentsHeader>
                <Nav>
                    <PageTitle>
                        <FormattedMessage id="nav.title.recurrent" />
                    </PageTitle>
                </Nav>
            </LeftComponentsHeader>
            <RightComponentsHeader>
                <Clock onClick={noop} />
            </RightComponentsHeader>
        </Header>
    );
}
