import LoadingButton from '@mui/lab/LoadingButton';
import { buttonClasses, styled } from '@mui/material';

export const RoundButton = styled(LoadingButton)`
    border-radius: 25px;
    padding: 16px 30px;
    letter-spacing: 0;
    text-transform: none;
    height: ${({ size }) => (size === 'small' ? '30px' : size === 'medium' ? '50px' : '75px')};
    font-size: ${({ size }) => (size === 'small' ? '13px' : size === 'medium' ? '15px' : '22px')};
    line-height: 18px;
    white-space: nowrap;
    &.${buttonClasses.text} {
        border: 1px solid rgba(12, 69, 108, 0.5);
    }
    &.${buttonClasses.outlined} {
        color: ${({ theme }) => theme.palette.primary.contrastText};
        border-color: #245f88;
        &.${buttonClasses.disabled} {
            color: rgba(255, 255, 255, 0.3);
            border-color: rgba(255, 255, 255, 0.12);
        }
    }

    ${({ theme }) =>
        theme.palette.mode === 'light'
            ? `
    &.${buttonClasses.containedSecondary} {
        background-color: ${theme.palette.secondary.dark};
    }
    &.${buttonClasses.textPrimary} {
        color: ${theme.palette.primary.contrastText};
    }`
            : ''}
`;
