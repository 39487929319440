import { Button, styled } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { PopoverMenu } from '../navigation/PopoverMenu/PopoverMenu';
import { CustomMenuStyles } from '../CustomMenu/CustomMenu.style';
import MenuItem from '@mui/material/MenuItem';
import { FormattedMessage } from 'react-intl';
import { useMissionForm } from '../../contexts/MissionFormContext';
import { useNavigate } from 'react-router-dom';
import { ROUTE_RECURRENT_MISSIONS } from '../../routes';

export function NewMissionButton() {
    const { openDialog } = useMissionForm();
    const navigate = useNavigate();

    return (
        <PopoverMenu
            renderButton={({ onClick }) => (
                <StyledNewButton onClick={onClick}>
                    <AddIcon height={24} width={24} /> <FormattedMessage id={'newMission.newButton'} />
                </StyledNewButton>
            )}
            menuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
            }}
            menuStyles={CustomMenuStyles}
        >
            <MenuItem onClick={() => openDialog('flightMissionForm', null)}>
                <FormattedMessage id="newMission.flightMission" />
            </MenuItem>
            <MenuItem onClick={() => openDialog('otherMissionForm', null)}>
                <FormattedMessage id="newMission.otherMission" />
            </MenuItem>
            <MenuItem onClick={() => navigate(ROUTE_RECURRENT_MISSIONS)}>
                <FormattedMessage id="newMission.recurrentMission" />
            </MenuItem>
        </PopoverMenu>
    );
}

const StyledNewButton = styled(Button)`
    border-radius: 10px;
    background-color: ${({ theme }) => theme.palette.blue.lightBg};
    min-width: 90px;
    min-height: 36px;
    color: ${({ theme }) => theme.palette.blue.lightest};
    font-size: 15px;
    font-weight: 500;
`;
