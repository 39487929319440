/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React, { memo, useEffect, useMemo, useRef } from 'react';
import { DriverMissionSlotDto, FlightFlow } from '../../../../../../backend/gen';
import { ReactComponent as SchengenIcon } from '../../../../../../assets/icons/icon-schengen-medium.svg';
import { ReactComponent as GateIcon } from '../../../../../../assets/icons/icon-gate-medium.svg';
import { ReactComponent as ParkingIcon } from '../../../../../../assets/icons/icon-parking-medium.svg';
import { ReactComponent as ArrowIcon } from '../../../../../../assets/icons/icon-arrival-tours-arrow.svg';
import { ReactComponent as ArrStepCompleteIcon } from '../../../../../../assets/icons/icon-step-complete-purple.svg';
import { ReactComponent as DepStepCompleteIcon } from '../../../../../../assets/icons/icon-step-complete-green.svg';
import { Box } from '@mui/material';
import {
    StyledArrowIconContainer,
    StyledBox,
    StyledContainer,
    StyledMissionInfoContainer,
    StyledMissionPickUpDropoffContainer,
    StyledStepContainer,
    StyledStepPendingCheckbox,
    StyledStepsContainer,
    StyledTours,
} from './CurrentMissionFlightSteps.style';
import { Locales, useIntlValue } from '@innovatm/js-iam';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { getMissionPrimaryColor } from '../../../../../../utils/data.utils';

export const CurrentMissionFlightSteps = memo(function CurrentMissionFlightSteps({
    driverCurrentMission,
}: {
    driverCurrentMission: DriverMissionSlotDto;
}) {
    const locale = useIntlValue('locale');
    const flowColor = getMissionPrimaryColor(driverCurrentMission.type, driverCurrentMission.flight?.flow);
    const stepCompleteIcon =
        driverCurrentMission.flight?.flow === FlightFlow.ARR ? <ArrStepCompleteIcon /> : <DepStepCompleteIcon />;
    const isExceptional = driverCurrentMission.pickUpLocation;
    const currentStep = useMemo(() => {
        const indexOfFirstIncompleteStep = driverCurrentMission.steps.findIndex(step => step.done === false);
        return indexOfFirstIncompleteStep === 0 ? 0 : indexOfFirstIncompleteStep - 1;
    }, [driverCurrentMission.steps]);

    const hasOverflowTop = driverCurrentMission.steps.length > 8 && currentStep > 3;
    const hasOverflowBottom =
        driverCurrentMission.steps.length > 8 && currentStep < driverCurrentMission.steps.length - 4;
    const stepsContainerClasses = useMemo(() => {
        if (hasOverflowBottom && hasOverflowTop) {
            return 'both-fadeout';
        } else if (hasOverflowBottom) {
            return 'bottom-fadeout';
        } else if (hasOverflowTop) {
            return 'top-fadeout';
        } else {
            return '';
        }
    }, [hasOverflowBottom, hasOverflowTop]);

    const stepsRef = useRef([]);
    useEffect(() => {
        const firstIncompleteStepIndex = driverCurrentMission.steps.findIndex(step => !step.done);
        stepsRef.current[
            driverCurrentMission.steps.find(step => !step.done)
                ? firstIncompleteStepIndex > 0
                    ? firstIncompleteStepIndex - 1
                    : 0
                : driverCurrentMission.steps.length - 1
        ].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    }, [driverCurrentMission.steps]);

    return (
        <StyledContainer>
            <StyledMissionInfoContainer>
                <StyledBox>
                    {driverCurrentMission.flight?.flow === FlightFlow.ARR ? (
                        <>
                            <ParkingIcon fill={flowColor} width={'50px'} height={'50px'} />
                            <Box>{driverCurrentMission.flight?.parking}</Box>
                        </>
                    ) : isExceptional ? (
                        <>
                            <PinDropIcon sx={{ color: flowColor, width: '45px', height: '45px' }} />
                            <Box>Pick-Up</Box>
                        </>
                    ) : (
                        <>
                            <GateIcon fill={flowColor} width={'50px'} height={'50px'} />
                            <Box>{driverCurrentMission.flight?.gate}</Box>
                        </>
                    )}
                </StyledBox>

                <StyledArrowIconContainer>
                    {driverCurrentMission.nbTours > 1 && (
                        <StyledTours color={flowColor}>{`x${driverCurrentMission.nbTours}`}</StyledTours>
                    )}
                    <ArrowIcon fill={flowColor} />
                </StyledArrowIconContainer>

                <StyledBox>
                    {driverCurrentMission.flight?.flow === FlightFlow.ARR ? (
                        <>
                            <SchengenIcon fill={flowColor} width={'50px'} height={'50px'} />
                            <Box>{driverCurrentMission.flight?.zone}</Box>
                        </>
                    ) : isExceptional ? (
                        <>
                            <PinDropIcon sx={{ color: flowColor, width: '45px', height: '45px' }} />
                            <Box>Drop-off</Box>
                        </>
                    ) : (
                        <>
                            <ParkingIcon fill={flowColor} width={'50px'} height={'50px'} />
                            <Box>{driverCurrentMission.flight?.parking}</Box>
                        </>
                    )}
                </StyledBox>
            </StyledMissionInfoContainer>
            {(!!driverCurrentMission.pickUpLocation || !!driverCurrentMission.dropOffLocation) && (
                <StyledMissionPickUpDropoffContainer>
                    <div>{driverCurrentMission.pickUpLocation || ''}</div>
                    <div>{driverCurrentMission.dropOffLocation}</div>
                </StyledMissionPickUpDropoffContainer>
            )}
            <StyledStepsContainer
                className={stepsContainerClasses}
                marginTop={driverCurrentMission.dropOffLocation ? '70px' : '40px'}
                height={driverCurrentMission.dropOffLocation ? '390px' : '420px'}
            >
                {driverCurrentMission.steps.map((step, i) => {
                    return (
                        <StyledStepContainer
                            key={i}
                            color={!driverCurrentMission.steps[i].done ? '#ACACAC' : ''}
                            ref={e => (stepsRef.current[i] = e)}
                        >
                            {step.done ? stepCompleteIcon : <StyledStepPendingCheckbox />}
                            <Box color={i === currentStep && step.done ? flowColor : ''}>
                                {locale === Locales.en ? step.descriptionEng : step.descriptionFr}
                            </Box>
                        </StyledStepContainer>
                    );
                })}
            </StyledStepsContainer>
        </StyledContainer>
    );
});
