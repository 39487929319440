/**
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import { useEffect, useState } from 'react';
import { useMirroredRef } from './useMirroredRef';

// If resetValue changes, the timer restarts
export const useInterval = (callback: () => void, frequency: number, resetValue?: any) => {
    const [delay, setDelay] = useState(frequency - (Date.now() % frequency));

    const savedCallback = useMirroredRef(callback);
    useEffect(() => {
        const timeout = setInterval(() => {
            if (delay !== frequency) {
                setDelay(frequency);
            }

            return savedCallback.current();
        }, delay);

        return () => {
            clearInterval(timeout);
        };
    }, [delay, frequency, resetValue, savedCallback]);
};
