/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { useCurrentUserClientTimezone } from '../../contexts/AuthContext.selectors';
import { useCallback } from 'react';
import { format, parse } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export function useZonedTimeConverter() {
    const timezone = useCurrentUserClientTimezone();

    const timestampToHHMM = useCallback(
        (timestamp: number | undefined | null) => {
            return timestamp ? format(utcToZonedTime(timestamp, timezone || ''), 'HH:mm') : undefined;
        },
        [timezone],
    );

    const hhmmToTimestamp = useCallback(
        (timeString: string | undefined | null) => {
            if (!timeString) return null;

            let date: Date;
            if (timeString.length === 5) {
                date = zonedTimeToUtc(parse(timeString, 'HH:mm', new Date()), timezone || '');
            } else if (timeString.length === 8) {
                date = zonedTimeToUtc(parse(timeString, 'HH:mm:ss', new Date()), timezone || '');
            } else {
                return null;
            }

            return date.getTime();
        },
        [timezone],
    );

    return {
        timestampToHHMM,
        hhmmToTimestamp,
    };
}
