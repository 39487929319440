/**
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */
import { FlightFlow, MissionType } from '../backend/gen';
import { theme } from '../theme';

export const safeParse = (s?: string | null) => {
    try {
        return s ? JSON.parse(s) : s;
    } catch (e) {
        return s;
    }
};

export const noop = () => {
    /**/
};

export const getMissionPrimaryColor = (missionType: MissionType, flow?: FlightFlow) => {
    return missionType === 'OTHER' || missionType === 'RECURRENT'
        ? (theme.palette.other.main as string)
        : flow === FlightFlow.ARR
        ? (theme.palette.arrival.main as string)
        : (theme.palette.departure.main as string);
};
