/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import classNames from 'classnames';
import { FormLabel, formLabelClasses, Input, Radio, radioClasses, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { theme } from '../../../../../../theme';
import { ReportItemDto } from '../../../../../../backend/gen';

export function VehicleReportInputRow({
    field,
    setField,
    index,
}: {
    field: ReportItemDto;
    setField: (updatedField: Partial<ReportItemDto>) => void;
    index: number;
}) {
    const isFirstRow = index === 0;

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setField({ ok: event.target.value === 'OK' });
    };

    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setField({ comment: event.target.value });
    };

    return (
        <StyledReportInputRow>
            <StyledInputName title={field.name}>{field.name}</StyledInputName>
            <StyledRadioContainer className={classNames({ firstRow: isFirstRow })}>
                <span>
                    {isFirstRow && <FormLabel>OK</FormLabel>}
                    <Radio checked={field.ok} value="OK" name={`radio-buttons-${index}`} onChange={handleRadioChange} />
                </span>
                <span>
                    {isFirstRow && <FormLabel>NOK</FormLabel>}
                    <Radio
                        checked={!field.ok}
                        value="NOK"
                        name={`radio-buttons-${index}`}
                        onChange={handleRadioChange}
                    />
                </span>
            </StyledRadioContainer>
            <StyledTextInputContainer className={classNames({ firstRow: isFirstRow })}>
                {isFirstRow && <FormattedMessage id={'driver.vehicleReport.nokComment'} />}
                <StyledTextInput
                    type={'text'}
                    disableUnderline
                    value={field.comment || ''}
                    onChange={handleCommentChange}
                    className={classNames({ invalid: !field.ok && !field.comment })}
                />
            </StyledTextInputContainer>
        </StyledReportInputRow>
    );
}

const StyledReportInputRow = styled('div')`
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    font-size: 16px;
    justify-content: space-evenly;
    padding: 0;
`;

const StyledInputName = styled('div')`
    width: 250px;
`;

const StyledRadioContainer = styled('div')`
    display: flex;
    gap: 30px;
    position: relative;
    & span {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: ${theme.palette.secondary.main};
    }
    & .${formLabelClasses.root} {
        color: ${theme.palette.text.primary} !important;
        position: absolute;
        top: -7px;
    }

    &.firstRow {
        & .${radioClasses.root} {
            margin-top: 20px;
            margin-bottom: 0;
        }
    }
`;

const StyledTextInputContainer = styled('div')`
    position: relative;
    display: flex;
    align-items: end;
    &.firstRow {
        height: 75px;
        & > span {
            position: absolute;
            top: 0;
            left: 1px;
            font-size: 14px;
            font-weight: normal;
        }
    }
`;

const StyledTextInput = styled(Input)`
    height: 50px;
    width: 340px;
    border-radius: 5px;
    padding: 10px;
    background-color: #f1f7fb;
    &.invalid {
        border: 1px solid ${theme.palette.secondary.main};
    }
`;
