import { buttonBaseClasses, styled, Switch, switchClasses, SwitchProps } from '@mui/material';

export const RoundSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 50,
    height: 30,
    padding: 0,
    margin: theme.spacing(1),
    alignItems: 'center',
    [`& .${switchClasses.thumb}`]: {
        width: 28,
        height: 28,
        background: 'white',
        borderRadius: '15px',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.5)',
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
    },
    [`& .${switchClasses.track}`]: {
        height: '70%',
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: `${theme.palette.grey[400]}`,
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },

    [`& .${switchClasses.switchBase}.${buttonBaseClasses.root}`]: {
        padding: 1,
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            [`& .${switchClasses.thumb}:before`]: {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.primary.main,
                )}" d="M9 16.172l10.594-10.594 1.406 1.406-12 12-5.578-5.578 1.406-1.406z"/></svg>')`,
            },
            [`& + .${switchClasses.track}`]: {
                backgroundColor: theme.palette.blue.main,
                opacity: 1,
                border: 'none',
            },
            [`&.Mui-disabled + .${switchClasses.track}`]: {},
        },
        [`&.Mui-focusVisible .${switchClasses.thumb}`]: {
            color: theme.palette.blue.main,
            border: '6px solid #fff',
        },
        [`&.Mui-disabled .${switchClasses.thumb}`]: {},
    },
}));
