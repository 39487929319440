/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { useEffect, useState } from 'react';
import { calculateGanttWidth } from '../DispatcherPage.utils';
import { ganttContentPaddingLeft, ganttPxPerMs, ganttTitleWidth } from '../Gantt/gantt.constants';
import { addDays, differenceInMilliseconds } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useCurrentUserClientTimezone } from '../../../../contexts/AuthContext.selectors';

export const useGanttDimensions = (from: number, to: number) => {
    const [ganttWidth, setGanttWidth] = useState(0);
    const [dayDelimiters, setDayDelimiters] = useState<number[]>([]);
    const timezone = useCurrentUserClientTimezone();

    useEffect(() => {
        setGanttWidth(calculateGanttWidth(from, to, ganttPxPerMs, ganttTitleWidth, ganttContentPaddingLeft));

        const startOfZonedDay = new Date(utcToZonedTime(from, timezone || '')).setHours(0, 0, 0, 0);
        let day = zonedTimeToUtc(new Date(startOfZonedDay), timezone || '');

        const delimiters = [];
        while (day.getTime() <= new Date(to).getTime()) {
            delimiters.push(
                String(differenceInMilliseconds(day, from) * ganttPxPerMs + ganttTitleWidth + ganttContentPaddingLeft),
            );
            day = zonedTimeToUtc(utcToZonedTime(addDays(day, 1), timezone || ''), timezone || '');
        }
        setDayDelimiters(delimiters);
    }, [from, to, timezone]);

    return { ganttWidth, dayDelimiters };
};
