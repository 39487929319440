/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import AddIcon from '@mui/icons-material/Add';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { ExtendedRecurrentMissionDto } from '../../../../../contexts/MissionFormContext';
import { styled } from '@mui/material';
import { SquareButton } from '../../../../../components/Button/SquareButton/SquareButton';
import {
    StyledRecurrentMissionDialogRow,
    StyledRecurrentMissionTimeContainer,
    StyledRecurrentMissionTimePicker,
} from './CurrentRecurrentMissionForm';
import { useZonedTimeConverter } from '../../../../../components/time/useZonedTimeConverter';

export function RecurrentAddStepEndTime({
    mission,
    setMission,
}: {
    mission: Partial<ExtendedRecurrentMissionDto>;
    setMission: (updatedMission: Partial<ExtendedRecurrentMissionDto>) => void;
}) {
    const { hhmmToTimestamp, timestampToHHMM } = useZonedTimeConverter();
    return (
        <StyledRecurrentMissionDialogRow>
            <StyledAddStepButton
                color="primary"
                size="small"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() =>
                    setMission({
                        steps: [...(mission?.steps || []), { name: '', time: null }],
                    })
                }
            >
                <FormattedMessage id={'otherMissionForm.addStep'} />
            </StyledAddStepButton>
            <StyledRecurrentMissionTimeContainer>
                <StyledRecurrentMissionTimePicker
                    name={'endTime'}
                    date={new Date()}
                    value={hhmmToTimestamp(mission?.endTime)}
                    onChange={e =>
                        setMission({
                            endTime: timestampToHHMM(e),
                        })
                    }
                    required
                    label={
                        <span className={'missionTimeLabelText'}>
                            <FormattedMessage id={'otherMissionForm.endTime'} />
                        </span>
                    }
                />
            </StyledRecurrentMissionTimeContainer>
        </StyledRecurrentMissionDialogRow>
    );
}

const StyledAddStepButton = styled(SquareButton)`
    color: white !important;
`;
