/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { FormattedMessage } from 'react-intl';
import { Button, styled } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { GanttPeriodChangeDialogProps } from './GanttPeriodChangeDialogActions';
import { ButtonProps } from '@mui/material/Button';
import { theme } from '../../../../../theme';
import { DatePicker } from '../../../../../components/form/DatePicker/DatePicker';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useCurrentUserClient, useCurrentUserClientTimezone } from '../../../../../contexts/AuthContext.selectors';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

interface GanttPeriodChangeDialogContentProps {
    formFrom: number;
    setFormFrom: (from: number) => void;
    formTo: number;
    setFormTo: (to: number) => void;
    onClickSetTactical: () => void;
}

export function GanttPeriodChangeDialogContent({
    formFrom,
    setFormFrom,
    formTo,
    setFormTo,
    onClickSetTactical,
}: GanttPeriodChangeDialogContentProps) {
    const timezone = useCurrentUserClientTimezone();
    const currentUserClient = useCurrentUserClient();
    const [today] = useState(() => utcToZonedTime(new Date(), timezone || 'UTC'));
    const yesterday = useMemo(() => subDays(today, 1), [today]);

    const clientHistoricalViewMaxDaysAgo = currentUserClient?.historicalViewMaxDaysAgo;
    // Since startDayHourShift can make it so that the Gantt to timestamp is situated on day postOpsTo + 1,
    // we subtract it from the to date to be able to show the real user requested from-to
    const clientStartDayShiftInMs = currentUserClient?.startDayHourShift
        ? currentUserClient.startDayHourShift * (1000 * 60 * 60) + 1
        : 0;
    const minDate = useMemo(
        () => subDays(today, clientHistoricalViewMaxDaysAgo || 30),
        [today, clientHistoricalViewMaxDaysAgo],
    );
    const maxDate = yesterday;

    // Convert UTC timestamps to zoned dates for display
    const fromValue = useMemo(() => {
        if (!formFrom) {
            return yesterday;
        }
        const zonedFrom = utcToZonedTime(formFrom, timezone || 'UTC');
        return zonedFrom >= startOfDay(today) ? yesterday : zonedFrom;
    }, [formFrom, yesterday, today, timezone]);

    const toValue = useMemo(() => {
        if (!formTo) {
            return yesterday;
        }
        const zonedTo = utcToZonedTime(formTo - clientStartDayShiftInMs, timezone || 'UTC');
        return zonedTo > today ? yesterday : zonedTo;
    }, [formTo, timezone, today, yesterday, clientStartDayShiftInMs]);

    const handleChangeFormFrom = useCallback(
        (date: Date | null) => {
            if (!date) {
                setFormFrom(0);
                return;
            }
            const utcDate = zonedTimeToUtc(startOfDay(date), timezone || 'UTC');
            setFormFrom(utcDate.getTime());
        },
        [setFormFrom, timezone],
    );

    const handleChangeFormTo = useCallback(
        (date: Date | null) => {
            if (!date) {
                setFormTo(0);
                return;
            }
            const utcDate = zonedTimeToUtc(endOfDay(date), timezone || 'UTC');
            setFormTo(utcDate.getTime());
        },
        [setFormTo, timezone],
    );

    return (
        <>
            <Button {...SetTacticalButtonProps} onClick={onClickSetTactical}>
                <FormattedMessage id="dispatcher.ganttPeriod.dialogSetTactical" />
            </Button>
            <StyledMiddleText>
                <div>
                    <FormattedMessage id={'dispatcher.ganttPeriod.dialogMiddleTextTop'} />
                </div>
                <div>
                    <FormattedMessage id={'dispatcher.ganttPeriod.dialogMiddleTextBottom'} />
                </div>
            </StyledMiddleText>
            <StyledCalendarContainer>
                <StyledCalendarDatePicker
                    value={fromValue}
                    onChange={handleChangeFormFrom}
                    minDate={minDate}
                    maxDate={maxDate}
                    label={<FormattedMessage id={'from'} />}
                    required={true}
                />
                <StyledCalendarDatePicker
                    value={toValue}
                    onChange={handleChangeFormTo}
                    minDate={minDate}
                    maxDate={maxDate}
                    label={<FormattedMessage id={'to'} />}
                    required={true}
                />
            </StyledCalendarContainer>
        </>
    );
}

const SetTacticalButtonProps: Partial<ButtonProps> = {
    ...GanttPeriodChangeDialogProps,
    sx: {
        ...GanttPeriodChangeDialogProps.sx,
        [`&.Mui-disabled`]: {
            backgroundColor: theme.palette.blue.lightest,
        },
        marginBottom: '15px',
    },
    variant: 'contained',
};

const StyledMiddleText = styled('div')`
    font-size: 18px;
    text-align: center;
    padding-bottom: 20px;
`;

const StyledCalendarContainer = styled('div')`
    display: flex;
    gap: 20px;
    & .MuiInputLabel-root {
        color: ${theme.palette.blue.darkest} !important;
    }
`;

const StyledCalendarDatePicker = styled(DatePicker)`
    & .MuiInputLabel-formControl {
        color: ${theme.palette.blue.darkest} !important;
    }
    & .MuiInputBase-input {
        color: ${theme.palette.blue.darkest} !important;
    }
    & .MuiButtonBase-root {
        color: ${theme.palette.blue.darkest} !important;
    }
    & .MuiInputBase-root {
        background-color: #f1f7fb !important;
    }
`;
