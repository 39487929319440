/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { styled } from '@mui/material';
import { theme } from '../../../../theme';
import { RecurrentMissionDto, RecurrentMissionsDto } from '../../../../backend/gen';
import React, { useCallback } from 'react';
import { useMissionForm } from '../../../../contexts/MissionFormContext';

export function RecurrentMissionsList({
    missions,
    selectedMissionId,
    setSelectedMissionId,
}: {
    missions: RecurrentMissionsDto;
    selectedMissionId: string;
    setSelectedMissionId: React.Dispatch<React.SetStateAction<string>>;
}) {
    const { updateFormValues } = useMissionForm();

    const onClickSelectMission = useCallback(
        (mission: RecurrentMissionDto) => {
            setSelectedMissionId(mission.id);
            updateFormValues('recurrentMissionForm', {
                isCreating: false,
                mission: mission,
            });
        },
        [setSelectedMissionId, updateFormValues],
    );

    return (
        <StyledContainer>
            {missions?.missions?.map(mission => (
                <StyledMissionListItem
                    key={mission.id}
                    onClick={() => onClickSelectMission(mission)}
                    selected={mission.id === selectedMissionId}
                >
                    {mission.name}
                </StyledMissionListItem>
            ))}
        </StyledContainer>
    );
}

const StyledContainer = styled('div')`
    width: 435px;
    height: 100%;
    border-right: 1px solid ${theme.palette.primary.dark};
    font-size: 24px;
    color: white;
    padding: 36px;
`;

const StyledMissionListItem = styled('div')<{ selected: boolean }>`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    padding: 6px 10px;
    background-color: ${props => (props.selected ? theme.palette.other.main : 'transparent')};
    :hover {
        background-color: ${props => (props.selected ? theme.palette.other.main : theme.palette.blue.lightest)};
    }
`;
