/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { memo, useMemo } from 'react';
import { OnDemandReportDto } from '../../../../backend/gen';
import { ganttPxPerMs } from './gantt.constants';
import { Box, styled, Tooltip } from '@mui/material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { useCurrentTime } from '../hooks/useCurrentTime';
import { FormattedTime } from '../../../../components/time/FormattedTime';

export const RequestedVehicleReportMarker = memo(function RequestedVehicleReportMarker({
    report,
    from,
    onClickOpenRequestReportDialog,
}: {
    report: OnDemandReportDto;
    from: number;
    onClickOpenRequestReportDialog?: () => void;
}) {
    const { now } = useCurrentTime(0);
    const reportTime = new Date(report?.at || 0).getTime();
    const left = (reportTime - from) * ganttPxPerMs;

    const isPastDue = useMemo(() => reportTime < now, [reportTime, now]);

    return (
        <Box position="absolute" left={`${left}px`} top="0" zIndex={3} onClick={onClickOpenRequestReportDialog}>
            <Tooltip
                title={
                    <FormattedMessage
                        id={'dispatcher.gantt.onDemandReportDue'}
                        values={{ time: <FormattedTime>{report.at}</FormattedTime> }}
                    />
                }
                placement={'right'}
            >
                <StyledSummarizeIcon color="action" fontSize={'medium'} className={classNames({ isPastDue })} />
            </Tooltip>
        </Box>
    );
});

const StyledSummarizeIcon = styled(SummarizeIcon)`
    position: absolute;
    top: -37px;
    left: -8px;
    cursor: pointer;
    &.isPastDue {
        color: #db4343;
    }
`;
