/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { buttonClasses, styled } from '@mui/material';
import { theme } from '../../../theme';
import {
    errorToastConfig,
    RECURRENT_MISSIONS_ACTIONS_HEIGHT_IN_PX,
    successToastConfig,
} from '../../../utils/constants';
import { SquareButton } from '../../../components/Button/SquareButton/SquareButton';
import AddIcon from '@mui/icons-material/Add';
import { FormattedMessage } from 'react-intl';
import React, { useCallback } from 'react';
import { ExtendedRecurrentMissionDto, useMissionForm } from '../../../contexts/MissionFormContext';
import { RoundButton } from '../../../components/Button/RoundButton/RoundButton';
import {
    KeyGetGetAllRecurrentMissions,
    useMutationDeleteRemoveRecurrentMission,
    useMutationPatchUpdateRecurrentMission,
    useMutationPostCreateRecurrentMission,
} from '../../../backend/gen';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';

export function RecurrentMissionsActions({
    selectedMissionId,
    setSelectedMissionId,
    mission,
}: {
    selectedMissionId: string;
    setSelectedMissionId: React.Dispatch<React.SetStateAction<string>>;
    mission: Partial<ExtendedRecurrentMissionDto>;
}) {
    const queryClient = useQueryClient();
    const { mutateAsync: createMission } = useMutationPostCreateRecurrentMission();
    const { mutateAsync: updateMission } = useMutationPatchUpdateRecurrentMission();
    const { mutateAsync: deleteMission } = useMutationDeleteRemoveRecurrentMission();

    const { currentFormValues, updateFormValues } = useMissionForm();

    const isFormValid =
        mission?.name &&
        mission?.startTime &&
        mission?.endTime &&
        (mission?.steps?.length > 0 ? mission?.steps?.filter(step => !step.name)?.length === 0 : true) &&
        mission?.pattern &&
        mission.pattern.length > 0;

    const onClickNewMission = useCallback(() => {
        setSelectedMissionId(null);
        updateFormValues('recurrentMissionForm', {
            isCreating: true,
            mission: {
                steps: undefined,
                name: undefined,
                startTime: undefined,
                pattern: undefined,
                endTime: undefined,
                driverNote: undefined,
                id: undefined,
                missionId: undefined,
            },
        });
    }, [setSelectedMissionId, updateFormValues]);

    const onClickCancel = useCallback(() => {
        updateFormValues('recurrentMissionForm', {
            isCreating: false,
        });
        setSelectedMissionId(null);
    }, [setSelectedMissionId, updateFormValues]);

    const onClickCreateMission = useCallback(() => {
        createMission({
            recurrentMissionUpdateDto: {
                name: mission?.name,
                endTime: mission?.endTime,
                startTime: mission?.startTime,
                driverNote: mission?.driverNote,
                pattern: mission?.pattern,
                steps: mission?.steps,
            },
        })
            .then(() => {
                toast(<FormattedMessage id={'dispatcher.recurrent.createSuccess'} />, successToastConfig);
                onClickCancel();
                queryClient.invalidateQueries(KeyGetGetAllRecurrentMissions);
            })
            .catch(() => {
                toast(<FormattedMessage id={'dispatcher.recurrent.createFailed'} />, errorToastConfig);
            });
    }, [
        createMission,
        mission?.driverNote,
        mission?.endTime,
        mission?.name,
        mission?.pattern,
        mission?.startTime,
        mission?.steps,
        onClickCancel,
        queryClient,
    ]);

    const onClickEditMission = useCallback(() => {
        updateMission({
            mission: mission?.id,
            recurrentMissionUpdateDto: {
                name: mission?.name,
                endTime: mission?.endTime,
                startTime: mission?.startTime,
                driverNote: mission?.driverNote,
                pattern: mission?.pattern,
                steps: mission?.steps,
            },
        })
            .then(() => {
                toast(<FormattedMessage id={'dispatcher.recurrent.editSuccess'} />, successToastConfig);
                onClickCancel();
                queryClient.invalidateQueries(KeyGetGetAllRecurrentMissions);
            })
            .catch(() => {
                toast(<FormattedMessage id={'dispatcher.recurrent.editFailed'} />, errorToastConfig);
            });
    }, [
        mission?.driverNote,
        mission?.endTime,
        mission?.id,
        mission?.name,
        mission?.pattern,
        mission?.startTime,
        mission?.steps,
        onClickCancel,
        queryClient,
        updateMission,
    ]);

    const onClickDeleteMission = useCallback(() => {
        if (selectedMissionId) {
            deleteMission({ mission: selectedMissionId })
                .then(() => {
                    toast(<FormattedMessage id={'dispatcher.recurrent.deleteSuccess'} />, successToastConfig);
                    onClickCancel();
                    queryClient.invalidateQueries(KeyGetGetAllRecurrentMissions);
                })
                .catch(() => {
                    toast(<FormattedMessage id={'dispatcher.recurrent.deleteFailed'} />, errorToastConfig);
                });
        }
    }, [deleteMission, onClickCancel, queryClient, selectedMissionId]);

    return (
        <StyledContainer>
            <StyledActionsSection>
                {!currentFormValues?.isCreating && (
                    <StyledAddRecurrentMissionButton
                        color="primary"
                        size="small"
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={onClickNewMission}
                    >
                        <FormattedMessage id={'dispatcher.recurrent.newMissionButton'} />
                    </StyledAddRecurrentMissionButton>
                )}
            </StyledActionsSection>
            <StyledActionsSection>
                {currentFormValues?.isCreating ? (
                    <>
                        <RoundButton color="primary" size="medium" variant="outlined" onClick={onClickCancel}>
                            <FormattedMessage id={'dispatcher.recurrent.cancel'} />
                        </RoundButton>
                        <RoundButton
                            color="primary"
                            size="medium"
                            variant="contained"
                            disabled={!isFormValid}
                            onClick={onClickCreateMission}
                        >
                            <FormattedMessage id={'dispatcher.recurrent.createMissionButton'} />
                        </RoundButton>
                    </>
                ) : selectedMissionId ? (
                    <>
                        <RoundButton color="primary" size="medium" variant="outlined" onClick={onClickCancel}>
                            <FormattedMessage id={'dispatcher.recurrent.cancel'} />
                        </RoundButton>
                        <StyledDeleteMissionButton
                            color="error"
                            size="medium"
                            variant="text"
                            onClick={onClickDeleteMission}
                        >
                            <FormattedMessage id={'dispatcher.recurrent.deleteMissionButton'} />
                        </StyledDeleteMissionButton>
                        <RoundButton
                            color="primary"
                            size="medium"
                            variant="contained"
                            disabled={!isFormValid}
                            onClick={onClickEditMission}
                        >
                            <FormattedMessage id={'dispatcher.recurrent.editMissionButton'} />
                        </RoundButton>
                    </>
                ) : null}
            </StyledActionsSection>
        </StyledContainer>
    );
}

const StyledContainer = styled('div')`
    height: ${RECURRENT_MISSIONS_ACTIONS_HEIGHT_IN_PX}px;
    border-top: 1px solid ${theme.palette.primary.dark};
    padding: 0 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledActionsSection = styled('div')`
    display: flex;
    gap: 50px;
`;

const StyledAddRecurrentMissionButton = styled(SquareButton)`
    color: white !important;
`;

const StyledDeleteMissionButton = styled(RoundButton)`
    &.${buttonClasses.text} {
        border: 1px solid ${theme.palette.error.main};
        background-color: white;
        transition: opacity 0.4s;
        &:hover {
            opacity: 0.8;
        }
    }
`;
