import { Box, styled } from '@mui/material';
import { theme } from '../../../../../../theme';

export const StyledMissionSteps = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const StyledPendingStepDot = styled(Box)`
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: #082940;
`;

export const StyledArrStepDot = styled(Box)`
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: ${theme.palette.arrival.main};
`;

export const StyledDepStepDot = styled(Box)`
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: ${theme.palette.departure.main};
`;
