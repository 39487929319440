/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React from 'react';
import { DriverMissionSlotDto } from '../../../../../../backend/gen';
import { StyledStrip, StyledStripContainer } from '../../StripBus.style';
import { StripHeader } from './StripHeader/StripHeader';
import { StripTimeFlow } from './StripTimeFlow/StripTimeFlow';
import { StripAircraftPaxInfos } from './StripAircraftPaxInfos/StripAircraftPaxInfos';
import { StripDirection } from './StripDirection/StripDirection';

export function FlightMissionStripBus({ mission }: { mission: DriverMissionSlotDto }) {
    return (
        <StyledStripContainer>
            <StripHeader flight={mission.flight} />
            <StyledStrip>
                <StripTimeFlow mission={mission} />
                <StripAircraftPaxInfos mission={mission} />
                <StripDirection mission={mission} />
            </StyledStrip>
        </StyledStripContainer>
    );
}
