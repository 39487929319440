/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { styled } from '@mui/material';
import { HEADER_HEIGHT_IN_PX, RECURRENT_MISSIONS_ACTIONS_HEIGHT_IN_PX } from '../../../utils/constants';
import { RecurrentMissionsList } from './RecurrentMissionsComponents/RecurrentMissionsList';
import { CurrentRecurrentMissionForm } from './RecurrentMissionsComponents/CurrentRecurrentMissionForm/CurrentRecurrentMissionForm';
import { ExtendedRecurrentMissionDto } from '../../../contexts/MissionFormContext';
import { RecurrentMissionsDto } from '../../../backend/gen';
import React from 'react';

export function RecurrentMissionsContent({
    mission,
    missions,
    selectedMissionId,
    setSelectedMissionId,
}: {
    mission: Partial<ExtendedRecurrentMissionDto>;
    missions: RecurrentMissionsDto;
    selectedMissionId: string;
    setSelectedMissionId: React.Dispatch<React.SetStateAction<string>>;
}) {
    return (
        <StyledContainer>
            <RecurrentMissionsList
                missions={missions}
                selectedMissionId={selectedMissionId}
                setSelectedMissionId={setSelectedMissionId}
            />
            <CurrentRecurrentMissionForm mission={mission} />
        </StyledContainer>
    );
}

const StyledContainer = styled('div')`
    height: calc(100% - ${HEADER_HEIGHT_IN_PX + RECURRENT_MISSIONS_ACTIONS_HEIGHT_IN_PX}px);
    display: flex;
`;
