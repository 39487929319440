import { Box, styled } from '@mui/material';
import { StyledStripSegment } from '../../../StripBus.style';

export const StyledStripDirectionContainer = styled(StyledStripSegment)`
    width: 140px;
    height: 115px;
    border-right: none;
`;

export const StyledStripDirectionIconsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
`;

export const StyledStripDirectionIconContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 18px;
    font-weight: bold;
`;
