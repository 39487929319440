/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ReportType } from '../../../../../../backend/gen';

export function VehicleReportHeader({ reportType }: { reportType?: ReportType }) {
    return (
        <StyledContainer>
            <StyledReportType>
                <FormattedMessage id={'driver.vehicleReport.formTitle'} values={{ type: reportType }} />
            </StyledReportType>
        </StyledContainer>
    );
}

const StyledContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 40px;
`;

const StyledReportType = styled('div')`
    font-size: 21px;
    font-weight: bold;
`;
