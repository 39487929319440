/**
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import { useCallback, useState } from 'react';
import { usePersistedState } from './usePersistedState';

export const usePersistedBoolean = (key: string, initial: boolean) => {
    const [value, setValue] = usePersistedState(key, initial);
    return {
        value,
        setValue,
        toggle: useCallback(() => setValue(v => !v), [setValue]),
        setTrue: useCallback(() => setValue(true), [setValue]),
        setFalse: useCallback(() => setValue(false), [setValue]),
    };
};

export const useBoolean = (initial: boolean | (() => boolean) = false) => {
    const [value, setValue] = useState(initial);
    return {
        value,
        setValue,
        toggle: useCallback(() => setValue(v => !v), [setValue]),
        setTrue: useCallback(() => setValue(true), [setValue]),
        setFalse: useCallback(() => setValue(false), [setValue]),
    };
};
