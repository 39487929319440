import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { dateFnsLocaleMap } from '../hooks/useDateFnsFormat';
import { LocalizationProvider } from '@mui/lab';
import { memo } from 'react';
import { useIntlValue } from '@innovatm/js-iam';

export const DateFnsProvider = memo(({ children }) => {
    const locale = useIntlValue('locale');

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={dateFnsLocaleMap[locale]}>
            {children}
        </LocalizationProvider>
    );
});
