import { Locales } from '@innovatm/js-iam';

export const localeToDatePlaceholder: Record<Locales, string> = {
    [Locales.en]: 'mm/dd/yyyy',
    [Locales.fr]: 'jj/mm/aaaa',
};

export const localeToDateFormat: Record<Locales, string> = {
    [Locales.en]: 'MM/dd/yyyy',
    [Locales.fr]: 'dd/MM/yyyy',
};
