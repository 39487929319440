/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React, { memo } from 'react';
import { CustomMenuButton } from '../../../../../components/CustomMenu/CustomMenuButton';
import { PopoverMenu } from '../../../../../components/navigation/PopoverMenu/PopoverMenu';
import { MissionStripMenuContents } from './MissionStripMenuContents/MissionStripMenuContents';
import { DispatcherMissionSlotDto } from '../../../../../backend/gen';
import { CustomMenuStyles } from '../../../../../components/CustomMenu/CustomMenu.style';

export const MissionStripMenu = memo(function MissionStripMenu({
    buses,
    isMenuButtonVisible,
    mission,
    isMissionTerminated,
    isUnallocated,
}: {
    buses: string[];
    isMenuButtonVisible: {
        value: boolean;
        setValue: React.Dispatch<React.SetStateAction<boolean>>;
        toggle: () => void;
        setTrue: () => void;
        setFalse: () => void;
    };
    mission: DispatcherMissionSlotDto;
    isMissionTerminated: boolean;
    isUnallocated: boolean;
}) {
    return (
        <PopoverMenu
            renderButton={({ onClick }) => isMenuButtonVisible.value && <CustomMenuButton onClick={onClick} />}
            menuProps={{
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
            }}
            onClose={isMenuButtonVisible.setFalse}
            menuStyles={CustomMenuStyles}
            disablePortal={false}
        >
            {({ close }) => (
                <MissionStripMenuContents
                    buses={buses}
                    close={close}
                    mission={mission}
                    isMissionTerminated={isMissionTerminated}
                    isUnallocated={isUnallocated}
                />
            )}
        </PopoverMenu>
    );
});
