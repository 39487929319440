/**
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import { useRef, useEffect } from 'react';

export const useMirroredRef = <V>(value: V, cb?: (v: V) => any) => {
    const valueRef = useRef<V>(value);
    useEffect(() => {
        valueRef.current = value;
        if (cb) {
            cb(value);
        }
    }, [valueRef, value, cb]);
    return valueRef;
};
