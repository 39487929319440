import React, { memo, useCallback } from 'react';
import { CustomMenuButton } from '../../../../../components/CustomMenu/CustomMenuButton';
import { CustomMenuStyles } from '../../../../../components/CustomMenu/CustomMenu.style';
import { PopoverMenu } from '../../../../../components/navigation/PopoverMenu/PopoverMenu';
import { FormattedMessage } from 'react-intl';
import { MenuItem } from '@mui/material';
import { OnDemandReportDto } from '../../../../../backend/gen';

export const BusGanttMenu = memo(function BusGanttMenu({
    onClickOpenUnavailabilityPeriodDialog,
    onClickOpenDefineVacationDialog,
    onClickOpenRequestReportDialog,
    onClickDownloadVehicleReports,
    vehicleReportRequest,
    availableReports,
    isTactical,
}: {
    onClickOpenUnavailabilityPeriodDialog?: () => void;
    onClickOpenDefineVacationDialog?: () => void;
    onClickOpenRequestReportDialog?: () => void;
    onClickDownloadVehicleReports: () => void;
    vehicleReportRequest: OnDemandReportDto;
    availableReports: number;
    isTactical: boolean;
}) {
    const onClickDefineUnavailability = useCallback(
        (close: () => void) => {
            onClickOpenUnavailabilityPeriodDialog();
            close();
        },
        [onClickOpenUnavailabilityPeriodDialog],
    );
    const onClickDefineVacation = useCallback(
        (close: () => void) => {
            onClickOpenDefineVacationDialog();
            close();
        },
        [onClickOpenDefineVacationDialog],
    );
    const onClickRequestReport = useCallback(
        (close: () => void) => {
            onClickOpenRequestReportDialog();
            close();
        },
        [onClickOpenRequestReportDialog],
    );
    const onClickDownloadReports = useCallback(
        (close: () => void) => {
            onClickDownloadVehicleReports();
            close();
        },
        [onClickDownloadVehicleReports],
    );

    return (
        <>
            <PopoverMenu
                renderButton={({ onClick }) => <CustomMenuButton onClick={onClick} />}
                menuProps={{
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    disableAutoFocusItem: true,
                }}
                menuStyles={CustomMenuStyles}
                disablePortal={false}
            >
                {({ close }) =>
                    isTactical
                        ? [
                              <MenuItem key={1} onClick={() => onClickDefineUnavailability(close)}>
                                  <FormattedMessage id={'dispatcher.ganttBusMenu.defineUnavailability'} />
                              </MenuItem>,
                              <MenuItem key={2} onClick={() => onClickDefineVacation(close)}>
                                  <FormattedMessage id={'dispatcher.ganttBusMenu.defineVacation'} />
                              </MenuItem>,
                              <MenuItem key={3} onClick={() => onClickRequestReport(close)}>
                                  <FormattedMessage
                                      id={
                                          vehicleReportRequest?.id
                                              ? 'dispatcher.ganttBusMenu.editReport'
                                              : 'dispatcher.ganttBusMenu.requestReport'
                                      }
                                  />
                              </MenuItem>,
                              <MenuItem
                                  key={4}
                                  onClick={() => onClickDownloadReports(close)}
                                  disabled={!availableReports}
                              >
                                  <FormattedMessage
                                      id={'dispatcher.ganttBusMenu.downloadAvailableReports'}
                                      values={{ numAvailable: availableReports || 0 }}
                                  />
                              </MenuItem>,
                          ]
                        : [
                              <MenuItem
                                  key={1}
                                  onClick={() => onClickDownloadReports(close)}
                                  disabled={!availableReports}
                              >
                                  <FormattedMessage
                                      id={'dispatcher.ganttBusMenu.downloadAvailableReports'}
                                      values={{ numAvailable: availableReports || 0 }}
                                  />
                              </MenuItem>,
                          ]
                }
            </PopoverMenu>
        </>
    );
});
