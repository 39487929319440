import { Box, styled } from '@mui/material';
import {
    ganttContentPaddingLeft,
    ganttFlightRowHeight,
    ganttFlightRowMargin,
    ganttTitleWidth,
} from './gantt.constants';

export const GanttContentBox = styled(Box)`
    position: relative;
    max-height: fit-content;
`;

export const StyledGanttTitle = styled(Box)`
    position: sticky;
    left: 0;
    width: ${ganttTitleWidth}px;
    color: #d5dde3;
    font-family: 'DIN Condensed', serif;
    font-size: 24px;
    padding-left: 10px;
    margin-top: -10px;
    z-index: 5;
    .busName {
        text-transform: uppercase;
    }
`;

export const StyledGanttContent = styled(Box)`
    padding-left: ${ganttContentPaddingLeft}px;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
`;

export const StyledFlightVerticalLine = styled(Box)`
    width: 2px;
    position: absolute;
    top: 0;
    z-index: 2;
`;

export const GanttFlightRow = styled(Box)`
    height: ${ganttFlightRowHeight}px;
    padding-top: ${ganttFlightRowMargin}px;
    margin-bottom: ${ganttFlightRowMargin}px;
    width: 100%;
`;
