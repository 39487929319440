import React, { memo, useState } from 'react';
import { BusUnavailabilityPeriodDialogContent } from './BusUnavailabilityPeriodDialogContent/BusUnavailabilityPeriodDialogContent';
import { BusUnavailabilityPeriodDialogActions } from './BusUnavailabilityPeriodDialogActions/BusUnavailabilityPeriodDialogActions';
import { PeriodType } from '../BusUnavailabilityPeriodDialog';
import { DispatcherBusDto } from '../../../../../../backend/gen';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useCurrentUserClientTimezone } from '../../../../../../contexts/AuthContext.selectors';

export const BusUnavailabilityPeriodDialogContentAndActionsContainer = memo(
    function BusUnavailabilityPeriodDialogContentAndActionsContainer({
        bus,
        closeDialog,
    }: {
        bus: DispatcherBusDto;
        closeDialog: () => void;
    }) {
        const timezone = useCurrentUserClientTimezone();
        const [period, setPeriod] = useState<PeriodType>({
            start: bus.unavailability?.start
                ? utcToZonedTime(zonedTimeToUtc(bus.unavailability.start, timezone), timezone).getTime()
                : utcToZonedTime(new Date(), timezone).getTime(),
            end: bus.unavailability?.end
                ? utcToZonedTime(zonedTimeToUtc(bus.unavailability.end, timezone), timezone).getTime()
                : null,
        });

        return (
            <>
                <BusUnavailabilityPeriodDialogContent period={period} setPeriod={setPeriod} />
                <BusUnavailabilityPeriodDialogActions
                    closeDialog={closeDialog}
                    period={period}
                    setPeriod={setPeriod}
                    bus={bus}
                />
            </>
        );
    },
);
