import { Box, styled } from '@mui/material';

export const StyledBusContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
`;

export const StyledBusName = styled(Box)`
    margin-top: 2px;
    white-space: nowrap;
`;
