/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExtendedRecurrentMissionDto } from '../../../../../contexts/MissionFormContext';
import { styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
    StyledRecurrentMissionDialogRow,
    StyledRecurrentMissionTextInput,
    StyledRecurrentMissionTextInputContainer,
    StyledRecurrentMissionTimeContainer,
    StyledRecurrentMissionTimePicker,
} from './CurrentRecurrentMissionForm';
import { useZonedTimeConverter } from '../../../../../components/time/useZonedTimeConverter';

export function RecurrentMissionSteps({
    mission,
    setMission,
}: {
    mission: Partial<ExtendedRecurrentMissionDto>;
    setMission: (updatedMission: Partial<ExtendedRecurrentMissionDto>) => void;
}) {
    const { timestampToHHMM, hhmmToTimestamp } = useZonedTimeConverter();
    const intl = useIntl();

    const onChangeUpdateStepName = useCallback(
        (name: string, stepIndex: number) => {
            setMission({
                steps: mission?.steps?.map((s, index) => (index === stepIndex ? { ...s, name } : s)),
            });
        },
        [mission?.steps, setMission],
    );

    const onChangeUpdateStepTime = useCallback(
        (time: number | Date | null, stepIndex: number) => {
            if (!time) return undefined;
            const timeAsNumber = typeof time === 'number' ? time : time.getTime();
            setMission({
                steps: mission?.steps?.map((s, index) =>
                    index === stepIndex ? { ...s, time: timestampToHHMM(timeAsNumber) } : s,
                ),
            });
        },
        [mission?.steps, setMission, timestampToHHMM],
    );

    return (
        <>
            {mission?.steps?.map((step, i) => (
                <StyledRecurrentMissionDialogRow key={i}>
                    <StyledRecurrentMissionTextInputContainer>
                        <FormattedMessage id={'otherMissionForm.stepName'} />
                        <StyledRecurrentMissionTextInput
                            placeholder={intl.formatMessage({ id: 'otherMissionForm.stepName' })}
                            type={'text'}
                            disableUnderline
                            value={step.name || ''}
                            onChange={e => onChangeUpdateStepName(e.target.value, i)}
                        />
                    </StyledRecurrentMissionTextInputContainer>
                    <StyledRecurrentMissionTimeContainer>
                        <StyledRecurrentMissionTimePicker
                            name={'stepTime'}
                            date={new Date()}
                            value={hhmmToTimestamp(step?.time)}
                            onChange={time => onChangeUpdateStepTime(time, i)}
                            required
                            label={
                                <span className={'missionTimeLabelText'}>
                                    <FormattedMessage id={'otherMissionForm.stepTime'} />
                                </span>
                            }
                        />
                    </StyledRecurrentMissionTimeContainer>
                    <StyledCloseIcon
                        onClick={() => setMission({ steps: mission?.steps?.filter((_, index) => index !== i) })}
                    />
                </StyledRecurrentMissionDialogRow>
            ))}
        </>
    );
}

const StyledCloseIcon = styled(CloseIcon)`
    position: absolute;
    right: -30px;
    opacity: 80%;
    cursor: pointer;
`;
