import { Typography } from '@mui/material';
import * as React from 'react';
import { memo } from 'react';

export const PageTitle = memo(function PageTitle({ children }) {
    return (
        <Typography variant="h1" color="white" noWrap>
            {children}
        </Typography>
    );
});
