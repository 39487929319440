import { enUS as en, fr } from 'date-fns/locale';
import { useCallback } from 'react';
import { format as dateFnsFormat, Locale } from 'date-fns';
import { Locales, useIntlValue } from '@innovatm/js-iam';

export const dateFnsLocaleMap: Record<Locales, Locale> = {
    [Locales.en]: en,
    [Locales.fr]: fr,
};

export const useDateFnsFormat = () => {
    const localeFromContext = useIntlValue('locale');

    return useCallback(
        (
            date: Date | number,
            format: string,
            options?: {
                locale?: Locale;
                weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
                firstWeekContainsDate?: number;
                useAdditionalWeekYearTokens?: boolean;
                useAdditionalDayOfYearTokens?: boolean;
            },
        ) =>
            dateFnsFormat(date, format, {
                locale: options?.locale || dateFnsLocaleMap[localeFromContext],
                ...options,
            }),
        [localeFromContext],
    );
};
