/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Copyright Innov'ATM all rights reserved. This software is the property of Innov'ATM and may not be used in any
 * manner except under a license agreement signed with Innov'ATM.
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { BusVacationDto } from '../models';
import { DispatcherGanttDto } from '../models';
import { DispatcherMissionSlotDto } from '../models';
import { FlightMissionCreateDto } from '../models';
import { FlightMissionUpdateDto } from '../models';
import { OnDemandReportDto } from '../models';
import { OnDemandReportUpdateDto } from '../models';
import { OtherMissionUpdateDto } from '../models';
import { PeriodDto } from '../models';
import { RecurrentMissionDto } from '../models';
import { RecurrentMissionUpdateDto } from '../models';
import { RecurrentMissionsDto } from '../models';
import { UnavailabilityUpdateDto } from '../models';
import { VacationUpdateDto } from '../models';
import { Configuration, ConfigurationParameters } from '../configuration';
import { BASE_PATH, BaseAPI, RequestArgs } from '../base';
import { AxiosInstance, AxiosPromise, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, UseMutationOptions, useMutation, UseQueryOptions } from 'react-query';
import {
    assertParamExists,
    createRequestFunction,
    DUMMY_BASE_URL,
    serializeDataIfNeeded,
    setSearchParams,
    toPathString,
    globalAxios,
    setBearerAuthToObject,
} from '../common';
import { useAppAuthValue } from '../../utils';
export const DispatcherRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        allocateBus: async (mission: string, bus: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mission' is not null or undefined
            assertParamExists('allocateBus', 'mission', mission);
            // verify required parameter 'bus' is not null or undefined
            assertParamExists('allocateBus', 'bus', bus);
            const localVarPath = `/dispatcher/allocate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (mission !== undefined) {
                localVarQueryParameter['mission'] = mission;
            }
            if (bus !== undefined) {
                localVarQueryParameter['bus'] = bus;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        createFlightMission: async (
            flightMissionCreateDto: FlightMissionCreateDto,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'flightMissionCreateDto' is not null or undefined
            assertParamExists('createFlightMission', 'flightMissionCreateDto', flightMissionCreateDto);
            const localVarPath = `/dispatcher/mission/flight`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                flightMissionCreateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        createOtherMission: async (
            otherMissionUpdateDto: OtherMissionUpdateDto,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'otherMissionUpdateDto' is not null or undefined
            assertParamExists('createOtherMission', 'otherMissionUpdateDto', otherMissionUpdateDto);
            const localVarPath = `/dispatcher/mission/other`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                otherMissionUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        createRecurrentMission: async (
            recurrentMissionUpdateDto: RecurrentMissionUpdateDto,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'recurrentMissionUpdateDto' is not null or undefined
            assertParamExists('createRecurrentMission', 'recurrentMissionUpdateDto', recurrentMissionUpdateDto);
            const localVarPath = `/dispatcher/recurrent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                recurrentMissionUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        deallocateBus: async (mission: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mission' is not null or undefined
            assertParamExists('deallocateBus', 'mission', mission);
            const localVarPath = `/dispatcher/allocate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (mission !== undefined) {
                localVarQueryParameter['mission'] = mission;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        declareBusUnavailability: async (
            unavailabilityUpdateDto: UnavailabilityUpdateDto,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'unavailabilityUpdateDto' is not null or undefined
            assertParamExists('declareBusUnavailability', 'unavailabilityUpdateDto', unavailabilityUpdateDto);
            const localVarPath = `/dispatcher/unavailability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                unavailabilityUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        declareBusVacation: async (vacationUpdateDto: VacationUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vacationUpdateDto' is not null or undefined
            assertParamExists('declareBusVacation', 'vacationUpdateDto', vacationUpdateDto);
            const localVarPath = `/dispatcher/vacation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                vacationUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        duplicateToBus: async (mission: string, bus: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mission' is not null or undefined
            assertParamExists('duplicateToBus', 'mission', mission);
            // verify required parameter 'bus' is not null or undefined
            assertParamExists('duplicateToBus', 'bus', bus);
            const localVarPath = `/dispatcher/duplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (mission !== undefined) {
                localVarQueryParameter['mission'] = mission;
            }
            if (bus !== undefined) {
                localVarQueryParameter['bus'] = bus;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        exportBusReports: async (bus?: string, from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dispatcher/reports/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (bus !== undefined) {
                localVarQueryParameter['bus'] = bus;
            }
            if (from !== undefined) {
                localVarQueryParameter['from'] =
                    (from as any) instanceof Date ? (from as any).toISOString().substr(0, 10) : from;
            }
            if (to !== undefined) {
                localVarQueryParameter['to'] =
                    (to as any) instanceof Date ? (to as any).toISOString().substr(0, 10) : to;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        exportMissions: async (from: string, to: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('exportMissions', 'from', from);
            // verify required parameter 'to' is not null or undefined
            assertParamExists('exportMissions', 'to', to);
            const localVarPath = `/dispatcher/missions/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (from !== undefined) {
                localVarQueryParameter['from'] =
                    (from as any) instanceof Date ? (from as any).toISOString().substr(0, 10) : from;
            }
            if (to !== undefined) {
                localVarQueryParameter['to'] =
                    (to as any) instanceof Date ? (to as any).toISOString().substr(0, 10) : to;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        getAllMissions: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dispatcher/missions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (from !== undefined) {
                localVarQueryParameter['from'] =
                    (from as any) instanceof Date ? (from as any).toISOString().substr(0, 10) : from;
            }
            if (to !== undefined) {
                localVarQueryParameter['to'] =
                    (to as any) instanceof Date ? (to as any).toISOString().substr(0, 10) : to;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        getAllRecurrentMissions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dispatcher/recurrent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        removeBusUnavailability: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeBusUnavailability', 'id', id);
            const localVarPath = `/dispatcher/unavailability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        removeBusVacation: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeBusVacation', 'id', id);
            const localVarPath = `/dispatcher/vacation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        removeMission: async (mission: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mission' is not null or undefined
            assertParamExists('removeMission', 'mission', mission);
            const localVarPath = `/dispatcher/mission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (mission !== undefined) {
                localVarQueryParameter['mission'] = mission;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        removeOnDemandBusReport: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeOnDemandBusReport', 'id', id);
            const localVarPath = `/dispatcher/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        removeRecurrentMission: async (mission: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mission' is not null or undefined
            assertParamExists('removeRecurrentMission', 'mission', mission);
            const localVarPath = `/dispatcher/recurrent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (mission !== undefined) {
                localVarQueryParameter['mission'] = mission;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        requestOnDemandBusReport: async (
            onDemandReportUpdateDto: OnDemandReportUpdateDto,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'onDemandReportUpdateDto' is not null or undefined
            assertParamExists('requestOnDemandBusReport', 'onDemandReportUpdateDto', onDemandReportUpdateDto);
            const localVarPath = `/dispatcher/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                onDemandReportUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        updateFlightMission: async (
            mission: string,
            flightMissionUpdateDto: FlightMissionUpdateDto,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'mission' is not null or undefined
            assertParamExists('updateFlightMission', 'mission', mission);
            // verify required parameter 'flightMissionUpdateDto' is not null or undefined
            assertParamExists('updateFlightMission', 'flightMissionUpdateDto', flightMissionUpdateDto);
            const localVarPath = `/dispatcher/mission/flight`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'PATCH',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (mission !== undefined) {
                localVarQueryParameter['mission'] = mission;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                flightMissionUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        updateNumberOfTours: async (mission: string, tours: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mission' is not null or undefined
            assertParamExists('updateNumberOfTours', 'mission', mission);
            // verify required parameter 'tours' is not null or undefined
            assertParamExists('updateNumberOfTours', 'tours', tours);
            const localVarPath = `/dispatcher/nbtours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'PATCH',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (mission !== undefined) {
                localVarQueryParameter['mission'] = mission;
            }
            if (tours !== undefined) {
                localVarQueryParameter['tours'] = tours;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        updateOtherMission: async (
            mission: string,
            otherMissionUpdateDto: OtherMissionUpdateDto,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'mission' is not null or undefined
            assertParamExists('updateOtherMission', 'mission', mission);
            // verify required parameter 'otherMissionUpdateDto' is not null or undefined
            assertParamExists('updateOtherMission', 'otherMissionUpdateDto', otherMissionUpdateDto);
            const localVarPath = `/dispatcher/mission/other`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'PATCH',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (mission !== undefined) {
                localVarQueryParameter['mission'] = mission;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                otherMissionUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        updateRecurrentMission: async (
            mission: string,
            recurrentMissionUpdateDto: RecurrentMissionUpdateDto,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'mission' is not null or undefined
            assertParamExists('updateRecurrentMission', 'mission', mission);
            // verify required parameter 'recurrentMissionUpdateDto' is not null or undefined
            assertParamExists('updateRecurrentMission', 'recurrentMissionUpdateDto', recurrentMissionUpdateDto);
            const localVarPath = `/dispatcher/recurrent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'PATCH',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (mission !== undefined) {
                localVarQueryParameter['mission'] = mission;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                recurrentMissionUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
export const DispatcherRestControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = DispatcherRestControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} mission
         * @param {string} bus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allocateBus(
            mission: string,
            bus: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherMissionSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocateBus(mission, bus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {FlightMissionCreateDto} flightMissionCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFlightMission(
            flightMissionCreateDto: FlightMissionCreateDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherMissionSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFlightMission(
                flightMissionCreateDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {OtherMissionUpdateDto} otherMissionUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOtherMission(
            otherMissionUpdateDto: OtherMissionUpdateDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherMissionSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOtherMission(
                otherMissionUpdateDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {RecurrentMissionUpdateDto} recurrentMissionUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRecurrentMission(
            recurrentMissionUpdateDto: RecurrentMissionUpdateDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurrentMissionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRecurrentMission(
                recurrentMissionUpdateDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} mission
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deallocateBus(
            mission: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherMissionSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deallocateBus(mission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UnavailabilityUpdateDto} unavailabilityUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declareBusUnavailability(
            unavailabilityUpdateDto: UnavailabilityUpdateDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeriodDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.declareBusUnavailability(
                unavailabilityUpdateDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {VacationUpdateDto} vacationUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declareBusVacation(
            vacationUpdateDto: VacationUpdateDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusVacationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.declareBusVacation(vacationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} mission
         * @param {string} bus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateToBus(
            mission: string,
            bus: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherMissionSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateToBus(mission, bus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} [bus]
         * @param {string} [from]
         * @param {string} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportBusReports(
            bus?: string,
            from?: string,
            to?: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportBusReports(bus, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} from
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportMissions(
            from: string,
            to: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportMissions(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} [from]
         * @param {string} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMissions(
            from?: string,
            to?: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherGanttDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMissions(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRecurrentMissions(
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurrentMissionsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRecurrentMissions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeBusUnavailability(
            id: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeBusUnavailability(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeBusVacation(
            id: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeBusVacation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} mission
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMission(
            mission: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherMissionSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeMission(mission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeOnDemandBusReport(
            id: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeOnDemandBusReport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} mission
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeRecurrentMission(
            mission: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeRecurrentMission(mission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {OnDemandReportUpdateDto} onDemandReportUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestOnDemandBusReport(
            onDemandReportUpdateDto: OnDemandReportUpdateDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnDemandReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestOnDemandBusReport(
                onDemandReportUpdateDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} mission
         * @param {FlightMissionUpdateDto} flightMissionUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFlightMission(
            mission: string,
            flightMissionUpdateDto: FlightMissionUpdateDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherMissionSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFlightMission(
                mission,
                flightMissionUpdateDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} mission
         * @param {number} tours
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNumberOfTours(
            mission: string,
            tours: number,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherMissionSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNumberOfTours(mission, tours, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} mission
         * @param {OtherMissionUpdateDto} otherMissionUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOtherMission(
            mission: string,
            otherMissionUpdateDto: OtherMissionUpdateDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatcherMissionSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOtherMission(
                mission,
                otherMissionUpdateDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} mission
         * @param {RecurrentMissionUpdateDto} recurrentMissionUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRecurrentMission(
            mission: string,
            recurrentMissionUpdateDto: RecurrentMissionUpdateDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurrentMissionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRecurrentMission(
                mission,
                recurrentMissionUpdateDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

export const DispatcherRestControllerApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = DispatcherRestControllerApiFp(configuration);
    return {
        allocateBus(mission: string, bus: string, options?: any): AxiosPromise<DispatcherMissionSlotDto> {
            return localVarFp.allocateBus(mission, bus, options).then(request => request(axios, basePath));
        },
        createFlightMission(
            flightMissionCreateDto: FlightMissionCreateDto,
            options?: any,
        ): AxiosPromise<DispatcherMissionSlotDto> {
            return localVarFp
                .createFlightMission(flightMissionCreateDto, options)
                .then(request => request(axios, basePath));
        },
        createOtherMission(
            otherMissionUpdateDto: OtherMissionUpdateDto,
            options?: any,
        ): AxiosPromise<DispatcherMissionSlotDto> {
            return localVarFp
                .createOtherMission(otherMissionUpdateDto, options)
                .then(request => request(axios, basePath));
        },
        createRecurrentMission(
            recurrentMissionUpdateDto: RecurrentMissionUpdateDto,
            options?: any,
        ): AxiosPromise<RecurrentMissionDto> {
            return localVarFp
                .createRecurrentMission(recurrentMissionUpdateDto, options)
                .then(request => request(axios, basePath));
        },
        deallocateBus(mission: string, options?: any): AxiosPromise<DispatcherMissionSlotDto> {
            return localVarFp.deallocateBus(mission, options).then(request => request(axios, basePath));
        },
        declareBusUnavailability(
            unavailabilityUpdateDto: UnavailabilityUpdateDto,
            options?: any,
        ): AxiosPromise<PeriodDto> {
            return localVarFp
                .declareBusUnavailability(unavailabilityUpdateDto, options)
                .then(request => request(axios, basePath));
        },
        declareBusVacation(vacationUpdateDto: VacationUpdateDto, options?: any): AxiosPromise<BusVacationDto> {
            return localVarFp.declareBusVacation(vacationUpdateDto, options).then(request => request(axios, basePath));
        },
        duplicateToBus(mission: string, bus: string, options?: any): AxiosPromise<DispatcherMissionSlotDto> {
            return localVarFp.duplicateToBus(mission, bus, options).then(request => request(axios, basePath));
        },
        exportBusReports(bus?: string, from?: string, to?: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportBusReports(bus, from, to, options).then(request => request(axios, basePath));
        },
        exportMissions(from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportMissions(from, to, options).then(request => request(axios, basePath));
        },
        getAllMissions(from?: string, to?: string, options?: any): AxiosPromise<DispatcherGanttDto> {
            return localVarFp.getAllMissions(from, to, options).then(request => request(axios, basePath));
        },
        getAllRecurrentMissions(options?: any): AxiosPromise<RecurrentMissionsDto> {
            return localVarFp.getAllRecurrentMissions(options).then(request => request(axios, basePath));
        },
        removeBusUnavailability(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeBusUnavailability(id, options).then(request => request(axios, basePath));
        },
        removeBusVacation(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeBusVacation(id, options).then(request => request(axios, basePath));
        },
        removeMission(mission: string, options?: any): AxiosPromise<DispatcherMissionSlotDto> {
            return localVarFp.removeMission(mission, options).then(request => request(axios, basePath));
        },
        removeOnDemandBusReport(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeOnDemandBusReport(id, options).then(request => request(axios, basePath));
        },
        removeRecurrentMission(mission: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeRecurrentMission(mission, options).then(request => request(axios, basePath));
        },
        requestOnDemandBusReport(
            onDemandReportUpdateDto: OnDemandReportUpdateDto,
            options?: any,
        ): AxiosPromise<OnDemandReportDto> {
            return localVarFp
                .requestOnDemandBusReport(onDemandReportUpdateDto, options)
                .then(request => request(axios, basePath));
        },
        updateFlightMission(
            mission: string,
            flightMissionUpdateDto: FlightMissionUpdateDto,
            options?: any,
        ): AxiosPromise<DispatcherMissionSlotDto> {
            return localVarFp
                .updateFlightMission(mission, flightMissionUpdateDto, options)
                .then(request => request(axios, basePath));
        },
        updateNumberOfTours(mission: string, tours: number, options?: any): AxiosPromise<DispatcherMissionSlotDto> {
            return localVarFp.updateNumberOfTours(mission, tours, options).then(request => request(axios, basePath));
        },
        updateOtherMission(
            mission: string,
            otherMissionUpdateDto: OtherMissionUpdateDto,
            options?: any,
        ): AxiosPromise<DispatcherMissionSlotDto> {
            return localVarFp
                .updateOtherMission(mission, otherMissionUpdateDto, options)
                .then(request => request(axios, basePath));
        },
        updateRecurrentMission(
            mission: string,
            recurrentMissionUpdateDto: RecurrentMissionUpdateDto,
            options?: any,
        ): AxiosPromise<RecurrentMissionDto> {
            return localVarFp
                .updateRecurrentMission(mission, recurrentMissionUpdateDto, options)
                .then(request => request(axios, basePath));
        },
    };
};
export interface DispatcherRestControllerApiAllocateBusRequest {
    readonly mission: string;

    readonly bus: string;
}

export interface DispatcherRestControllerApiCreateFlightMissionRequest {
    readonly flightMissionCreateDto: FlightMissionCreateDto;
}

export interface DispatcherRestControllerApiCreateOtherMissionRequest {
    readonly otherMissionUpdateDto: OtherMissionUpdateDto;
}

export interface DispatcherRestControllerApiCreateRecurrentMissionRequest {
    readonly recurrentMissionUpdateDto: RecurrentMissionUpdateDto;
}

export interface DispatcherRestControllerApiDeallocateBusRequest {
    readonly mission: string;
}

export interface DispatcherRestControllerApiDeclareBusUnavailabilityRequest {
    readonly unavailabilityUpdateDto: UnavailabilityUpdateDto;
}

export interface DispatcherRestControllerApiDeclareBusVacationRequest {
    readonly vacationUpdateDto: VacationUpdateDto;
}

export interface DispatcherRestControllerApiDuplicateToBusRequest {
    readonly mission: string;

    readonly bus: string;
}

export interface DispatcherRestControllerApiExportBusReportsRequest {
    readonly bus?: string;

    readonly from?: string;

    readonly to?: string;
}

export interface DispatcherRestControllerApiExportMissionsRequest {
    readonly from: string;

    readonly to: string;
}

export interface DispatcherRestControllerApiGetAllMissionsRequest {
    readonly from?: string;

    readonly to?: string;
}

export interface DispatcherRestControllerApiRemoveBusUnavailabilityRequest {
    readonly id: number;
}

export interface DispatcherRestControllerApiRemoveBusVacationRequest {
    readonly id: number;
}

export interface DispatcherRestControllerApiRemoveMissionRequest {
    readonly mission: string;
}

export interface DispatcherRestControllerApiRemoveOnDemandBusReportRequest {
    readonly id: number;
}

export interface DispatcherRestControllerApiRemoveRecurrentMissionRequest {
    readonly mission: string;
}

export interface DispatcherRestControllerApiRequestOnDemandBusReportRequest {
    readonly onDemandReportUpdateDto: OnDemandReportUpdateDto;
}

export interface DispatcherRestControllerApiUpdateFlightMissionRequest {
    readonly mission: string;

    readonly flightMissionUpdateDto: FlightMissionUpdateDto;
}

export interface DispatcherRestControllerApiUpdateNumberOfToursRequest {
    readonly mission: string;

    readonly tours: number;
}

export interface DispatcherRestControllerApiUpdateOtherMissionRequest {
    readonly mission: string;

    readonly otherMissionUpdateDto: OtherMissionUpdateDto;
}

export interface DispatcherRestControllerApiUpdateRecurrentMissionRequest {
    readonly mission: string;

    readonly recurrentMissionUpdateDto: RecurrentMissionUpdateDto;
}

export class DispatcherRestControllerApi extends BaseAPI {
    public allocateBus(requestParameters: DispatcherRestControllerApiAllocateBusRequest, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .allocateBus(requestParameters.mission, requestParameters.bus, options)
            .then(request => request(this.axios, this.basePath));
    }
    public createFlightMission(
        requestParameters: DispatcherRestControllerApiCreateFlightMissionRequest,
        options?: any,
    ) {
        return DispatcherRestControllerApiFp(this.configuration)
            .createFlightMission(requestParameters.flightMissionCreateDto, options)
            .then(request => request(this.axios, this.basePath));
    }
    public createOtherMission(requestParameters: DispatcherRestControllerApiCreateOtherMissionRequest, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .createOtherMission(requestParameters.otherMissionUpdateDto, options)
            .then(request => request(this.axios, this.basePath));
    }
    public createRecurrentMission(
        requestParameters: DispatcherRestControllerApiCreateRecurrentMissionRequest,
        options?: any,
    ) {
        return DispatcherRestControllerApiFp(this.configuration)
            .createRecurrentMission(requestParameters.recurrentMissionUpdateDto, options)
            .then(request => request(this.axios, this.basePath));
    }
    public deallocateBus(requestParameters: DispatcherRestControllerApiDeallocateBusRequest, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .deallocateBus(requestParameters.mission, options)
            .then(request => request(this.axios, this.basePath));
    }
    public declareBusUnavailability(
        requestParameters: DispatcherRestControllerApiDeclareBusUnavailabilityRequest,
        options?: any,
    ) {
        return DispatcherRestControllerApiFp(this.configuration)
            .declareBusUnavailability(requestParameters.unavailabilityUpdateDto, options)
            .then(request => request(this.axios, this.basePath));
    }
    public declareBusVacation(requestParameters: DispatcherRestControllerApiDeclareBusVacationRequest, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .declareBusVacation(requestParameters.vacationUpdateDto, options)
            .then(request => request(this.axios, this.basePath));
    }
    public duplicateToBus(requestParameters: DispatcherRestControllerApiDuplicateToBusRequest, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .duplicateToBus(requestParameters.mission, requestParameters.bus, options)
            .then(request => request(this.axios, this.basePath));
    }
    public exportBusReports(requestParameters: DispatcherRestControllerApiExportBusReportsRequest = {}, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .exportBusReports(requestParameters.bus, requestParameters.from, requestParameters.to, options)
            .then(request => request(this.axios, this.basePath));
    }
    public exportMissions(requestParameters: DispatcherRestControllerApiExportMissionsRequest, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .exportMissions(requestParameters.from, requestParameters.to, options)
            .then(request => request(this.axios, this.basePath));
    }
    public getAllMissions(requestParameters: DispatcherRestControllerApiGetAllMissionsRequest = {}, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .getAllMissions(requestParameters.from, requestParameters.to, options)
            .then(request => request(this.axios, this.basePath));
    }
    public getAllRecurrentMissions(options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .getAllRecurrentMissions(options)
            .then(request => request(this.axios, this.basePath));
    }
    public removeBusUnavailability(
        requestParameters: DispatcherRestControllerApiRemoveBusUnavailabilityRequest,
        options?: any,
    ) {
        return DispatcherRestControllerApiFp(this.configuration)
            .removeBusUnavailability(requestParameters.id, options)
            .then(request => request(this.axios, this.basePath));
    }
    public removeBusVacation(requestParameters: DispatcherRestControllerApiRemoveBusVacationRequest, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .removeBusVacation(requestParameters.id, options)
            .then(request => request(this.axios, this.basePath));
    }
    public removeMission(requestParameters: DispatcherRestControllerApiRemoveMissionRequest, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .removeMission(requestParameters.mission, options)
            .then(request => request(this.axios, this.basePath));
    }
    public removeOnDemandBusReport(
        requestParameters: DispatcherRestControllerApiRemoveOnDemandBusReportRequest,
        options?: any,
    ) {
        return DispatcherRestControllerApiFp(this.configuration)
            .removeOnDemandBusReport(requestParameters.id, options)
            .then(request => request(this.axios, this.basePath));
    }
    public removeRecurrentMission(
        requestParameters: DispatcherRestControllerApiRemoveRecurrentMissionRequest,
        options?: any,
    ) {
        return DispatcherRestControllerApiFp(this.configuration)
            .removeRecurrentMission(requestParameters.mission, options)
            .then(request => request(this.axios, this.basePath));
    }
    public requestOnDemandBusReport(
        requestParameters: DispatcherRestControllerApiRequestOnDemandBusReportRequest,
        options?: any,
    ) {
        return DispatcherRestControllerApiFp(this.configuration)
            .requestOnDemandBusReport(requestParameters.onDemandReportUpdateDto, options)
            .then(request => request(this.axios, this.basePath));
    }
    public updateFlightMission(
        requestParameters: DispatcherRestControllerApiUpdateFlightMissionRequest,
        options?: any,
    ) {
        return DispatcherRestControllerApiFp(this.configuration)
            .updateFlightMission(requestParameters.mission, requestParameters.flightMissionUpdateDto, options)
            .then(request => request(this.axios, this.basePath));
    }
    public updateNumberOfTours(
        requestParameters: DispatcherRestControllerApiUpdateNumberOfToursRequest,
        options?: any,
    ) {
        return DispatcherRestControllerApiFp(this.configuration)
            .updateNumberOfTours(requestParameters.mission, requestParameters.tours, options)
            .then(request => request(this.axios, this.basePath));
    }
    public updateOtherMission(requestParameters: DispatcherRestControllerApiUpdateOtherMissionRequest, options?: any) {
        return DispatcherRestControllerApiFp(this.configuration)
            .updateOtherMission(requestParameters.mission, requestParameters.otherMissionUpdateDto, options)
            .then(request => request(this.axios, this.basePath));
    }
    public updateRecurrentMission(
        requestParameters: DispatcherRestControllerApiUpdateRecurrentMissionRequest,
        options?: any,
    ) {
        return DispatcherRestControllerApiFp(this.configuration)
            .updateRecurrentMission(requestParameters.mission, requestParameters.recurrentMissionUpdateDto, options)
            .then(request => request(this.axios, this.basePath));
    }
}

// custom hook generation here
export type PostAllocateBusResponse = AxiosResponse<DispatcherMissionSlotDto>;
export const KeyPostAllocateBus = 'PostAllocateBus';
export const useMutationPostAllocateBus = (
    options?: UseMutationOptions<PostAllocateBusResponse, AxiosError, DispatcherRestControllerApiAllocateBusRequest>,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<PostAllocateBusResponse, AxiosError, DispatcherRestControllerApiAllocateBusRequest>(
        (args: DispatcherRestControllerApiAllocateBusRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).allocateBus(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostAllocateBus = (
    args: DispatcherRestControllerApiAllocateBusRequest,
    options?: UseQueryOptions<PostAllocateBusResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostAllocateBusResponse, AxiosError>(
        [KeyPostAllocateBus, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).allocateBus(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostCreateFlightMissionResponse = AxiosResponse<DispatcherMissionSlotDto>;
export const KeyPostCreateFlightMission = 'PostCreateFlightMission';
export const useMutationPostCreateFlightMission = (
    options?: UseMutationOptions<
        PostCreateFlightMissionResponse,
        AxiosError,
        DispatcherRestControllerApiCreateFlightMissionRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostCreateFlightMissionResponse,
        AxiosError,
        DispatcherRestControllerApiCreateFlightMissionRequest
    >(
        (args: DispatcherRestControllerApiCreateFlightMissionRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).createFlightMission(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostCreateFlightMission = (
    args: DispatcherRestControllerApiCreateFlightMissionRequest,
    options?: UseQueryOptions<PostCreateFlightMissionResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostCreateFlightMissionResponse, AxiosError>(
        [KeyPostCreateFlightMission, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).createFlightMission(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostCreateOtherMissionResponse = AxiosResponse<DispatcherMissionSlotDto>;
export const KeyPostCreateOtherMission = 'PostCreateOtherMission';
export const useMutationPostCreateOtherMission = (
    options?: UseMutationOptions<
        PostCreateOtherMissionResponse,
        AxiosError,
        DispatcherRestControllerApiCreateOtherMissionRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostCreateOtherMissionResponse,
        AxiosError,
        DispatcherRestControllerApiCreateOtherMissionRequest
    >(
        (args: DispatcherRestControllerApiCreateOtherMissionRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).createOtherMission(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostCreateOtherMission = (
    args: DispatcherRestControllerApiCreateOtherMissionRequest,
    options?: UseQueryOptions<PostCreateOtherMissionResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostCreateOtherMissionResponse, AxiosError>(
        [KeyPostCreateOtherMission, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).createOtherMission(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostCreateRecurrentMissionResponse = AxiosResponse<RecurrentMissionDto>;
export const KeyPostCreateRecurrentMission = 'PostCreateRecurrentMission';
export const useMutationPostCreateRecurrentMission = (
    options?: UseMutationOptions<
        PostCreateRecurrentMissionResponse,
        AxiosError,
        DispatcherRestControllerApiCreateRecurrentMissionRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostCreateRecurrentMissionResponse,
        AxiosError,
        DispatcherRestControllerApiCreateRecurrentMissionRequest
    >(
        (args: DispatcherRestControllerApiCreateRecurrentMissionRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).createRecurrentMission(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostCreateRecurrentMission = (
    args: DispatcherRestControllerApiCreateRecurrentMissionRequest,
    options?: UseQueryOptions<PostCreateRecurrentMissionResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostCreateRecurrentMissionResponse, AxiosError>(
        [KeyPostCreateRecurrentMission, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).createRecurrentMission(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type DeleteDeallocateBusResponse = AxiosResponse<DispatcherMissionSlotDto>;
export const KeyDeleteDeallocateBus = 'DeleteDeallocateBus';
export const useMutationDeleteDeallocateBus = (
    options?: UseMutationOptions<
        DeleteDeallocateBusResponse,
        AxiosError,
        DispatcherRestControllerApiDeallocateBusRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<DeleteDeallocateBusResponse, AxiosError, DispatcherRestControllerApiDeallocateBusRequest>(
        (args: DispatcherRestControllerApiDeallocateBusRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).deallocateBus(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryDeleteDeallocateBus = (
    args: DispatcherRestControllerApiDeallocateBusRequest,
    options?: UseQueryOptions<DeleteDeallocateBusResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<DeleteDeallocateBusResponse, AxiosError>(
        [KeyDeleteDeallocateBus, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).deallocateBus(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostDeclareBusUnavailabilityResponse = AxiosResponse<PeriodDto>;
export const KeyPostDeclareBusUnavailability = 'PostDeclareBusUnavailability';
export const useMutationPostDeclareBusUnavailability = (
    options?: UseMutationOptions<
        PostDeclareBusUnavailabilityResponse,
        AxiosError,
        DispatcherRestControllerApiDeclareBusUnavailabilityRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostDeclareBusUnavailabilityResponse,
        AxiosError,
        DispatcherRestControllerApiDeclareBusUnavailabilityRequest
    >(
        (args: DispatcherRestControllerApiDeclareBusUnavailabilityRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).declareBusUnavailability(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostDeclareBusUnavailability = (
    args: DispatcherRestControllerApiDeclareBusUnavailabilityRequest,
    options?: UseQueryOptions<PostDeclareBusUnavailabilityResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostDeclareBusUnavailabilityResponse, AxiosError>(
        [KeyPostDeclareBusUnavailability, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).declareBusUnavailability(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostDeclareBusVacationResponse = AxiosResponse<BusVacationDto>;
export const KeyPostDeclareBusVacation = 'PostDeclareBusVacation';
export const useMutationPostDeclareBusVacation = (
    options?: UseMutationOptions<
        PostDeclareBusVacationResponse,
        AxiosError,
        DispatcherRestControllerApiDeclareBusVacationRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostDeclareBusVacationResponse,
        AxiosError,
        DispatcherRestControllerApiDeclareBusVacationRequest
    >(
        (args: DispatcherRestControllerApiDeclareBusVacationRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).declareBusVacation(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostDeclareBusVacation = (
    args: DispatcherRestControllerApiDeclareBusVacationRequest,
    options?: UseQueryOptions<PostDeclareBusVacationResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostDeclareBusVacationResponse, AxiosError>(
        [KeyPostDeclareBusVacation, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).declareBusVacation(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostDuplicateToBusResponse = AxiosResponse<DispatcherMissionSlotDto>;
export const KeyPostDuplicateToBus = 'PostDuplicateToBus';
export const useMutationPostDuplicateToBus = (
    options?: UseMutationOptions<
        PostDuplicateToBusResponse,
        AxiosError,
        DispatcherRestControllerApiDuplicateToBusRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<PostDuplicateToBusResponse, AxiosError, DispatcherRestControllerApiDuplicateToBusRequest>(
        (args: DispatcherRestControllerApiDuplicateToBusRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).duplicateToBus(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostDuplicateToBus = (
    args: DispatcherRestControllerApiDuplicateToBusRequest,
    options?: UseQueryOptions<PostDuplicateToBusResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostDuplicateToBusResponse, AxiosError>(
        [KeyPostDuplicateToBus, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).duplicateToBus(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetExportBusReportsResponse = AxiosResponse<any>;
export const KeyGetExportBusReports = 'GetExportBusReports';
export const useMutationGetExportBusReports = (
    options?: UseMutationOptions<
        GetExportBusReportsResponse,
        AxiosError,
        DispatcherRestControllerApiExportBusReportsRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        GetExportBusReportsResponse,
        AxiosError,
        DispatcherRestControllerApiExportBusReportsRequest
    >(
        (args: DispatcherRestControllerApiExportBusReportsRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).exportBusReports(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetExportBusReports = (
    args: DispatcherRestControllerApiExportBusReportsRequest,
    options?: UseQueryOptions<GetExportBusReportsResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetExportBusReportsResponse, AxiosError>(
        [KeyGetExportBusReports, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).exportBusReports(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetExportMissionsResponse = AxiosResponse<any>;
export const KeyGetExportMissions = 'GetExportMissions';
export const useMutationGetExportMissions = (
    options?: UseMutationOptions<
        GetExportMissionsResponse,
        AxiosError,
        DispatcherRestControllerApiExportMissionsRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<GetExportMissionsResponse, AxiosError, DispatcherRestControllerApiExportMissionsRequest>(
        (args: DispatcherRestControllerApiExportMissionsRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).exportMissions(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetExportMissions = (
    args: DispatcherRestControllerApiExportMissionsRequest,
    options?: UseQueryOptions<GetExportMissionsResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetExportMissionsResponse, AxiosError>(
        [KeyGetExportMissions, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).exportMissions(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetGetAllMissionsResponse = AxiosResponse<DispatcherGanttDto>;
export const KeyGetGetAllMissions = 'GetGetAllMissions';
export const useMutationGetGetAllMissions = (
    options?: UseMutationOptions<
        GetGetAllMissionsResponse,
        AxiosError,
        DispatcherRestControllerApiGetAllMissionsRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<GetGetAllMissionsResponse, AxiosError, DispatcherRestControllerApiGetAllMissionsRequest>(
        (args: DispatcherRestControllerApiGetAllMissionsRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).getAllMissions(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetGetAllMissions = (
    args: DispatcherRestControllerApiGetAllMissionsRequest,
    options?: UseQueryOptions<GetGetAllMissionsResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetGetAllMissionsResponse, AxiosError>(
        [KeyGetGetAllMissions, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).getAllMissions(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetGetAllRecurrentMissionsResponse = AxiosResponse<RecurrentMissionsDto>;
export const KeyGetGetAllRecurrentMissions = 'GetGetAllRecurrentMissions';
export const useMutationGetGetAllRecurrentMissions = (
    options?: UseMutationOptions<GetGetAllRecurrentMissionsResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<GetGetAllRecurrentMissionsResponse, AxiosError>(
        () => new DispatcherRestControllerApi(new Configuration({ accessToken })).getAllRecurrentMissions(axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetGetAllRecurrentMissions = (
    options?: UseQueryOptions<GetGetAllRecurrentMissionsResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetGetAllRecurrentMissionsResponse, AxiosError>(
        [KeyGetGetAllRecurrentMissions, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).getAllRecurrentMissions(
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type DeleteRemoveBusUnavailabilityResponse = AxiosResponse<void>;
export const KeyDeleteRemoveBusUnavailability = 'DeleteRemoveBusUnavailability';
export const useMutationDeleteRemoveBusUnavailability = (
    options?: UseMutationOptions<
        DeleteRemoveBusUnavailabilityResponse,
        AxiosError,
        DispatcherRestControllerApiRemoveBusUnavailabilityRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        DeleteRemoveBusUnavailabilityResponse,
        AxiosError,
        DispatcherRestControllerApiRemoveBusUnavailabilityRequest
    >(
        (args: DispatcherRestControllerApiRemoveBusUnavailabilityRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).removeBusUnavailability(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryDeleteRemoveBusUnavailability = (
    args: DispatcherRestControllerApiRemoveBusUnavailabilityRequest,
    options?: UseQueryOptions<DeleteRemoveBusUnavailabilityResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<DeleteRemoveBusUnavailabilityResponse, AxiosError>(
        [KeyDeleteRemoveBusUnavailability, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).removeBusUnavailability(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type DeleteRemoveBusVacationResponse = AxiosResponse<void>;
export const KeyDeleteRemoveBusVacation = 'DeleteRemoveBusVacation';
export const useMutationDeleteRemoveBusVacation = (
    options?: UseMutationOptions<
        DeleteRemoveBusVacationResponse,
        AxiosError,
        DispatcherRestControllerApiRemoveBusVacationRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        DeleteRemoveBusVacationResponse,
        AxiosError,
        DispatcherRestControllerApiRemoveBusVacationRequest
    >(
        (args: DispatcherRestControllerApiRemoveBusVacationRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).removeBusVacation(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryDeleteRemoveBusVacation = (
    args: DispatcherRestControllerApiRemoveBusVacationRequest,
    options?: UseQueryOptions<DeleteRemoveBusVacationResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<DeleteRemoveBusVacationResponse, AxiosError>(
        [KeyDeleteRemoveBusVacation, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).removeBusVacation(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type DeleteRemoveMissionResponse = AxiosResponse<DispatcherMissionSlotDto>;
export const KeyDeleteRemoveMission = 'DeleteRemoveMission';
export const useMutationDeleteRemoveMission = (
    options?: UseMutationOptions<
        DeleteRemoveMissionResponse,
        AxiosError,
        DispatcherRestControllerApiRemoveMissionRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<DeleteRemoveMissionResponse, AxiosError, DispatcherRestControllerApiRemoveMissionRequest>(
        (args: DispatcherRestControllerApiRemoveMissionRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).removeMission(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryDeleteRemoveMission = (
    args: DispatcherRestControllerApiRemoveMissionRequest,
    options?: UseQueryOptions<DeleteRemoveMissionResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<DeleteRemoveMissionResponse, AxiosError>(
        [KeyDeleteRemoveMission, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).removeMission(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type DeleteRemoveOnDemandBusReportResponse = AxiosResponse<void>;
export const KeyDeleteRemoveOnDemandBusReport = 'DeleteRemoveOnDemandBusReport';
export const useMutationDeleteRemoveOnDemandBusReport = (
    options?: UseMutationOptions<
        DeleteRemoveOnDemandBusReportResponse,
        AxiosError,
        DispatcherRestControllerApiRemoveOnDemandBusReportRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        DeleteRemoveOnDemandBusReportResponse,
        AxiosError,
        DispatcherRestControllerApiRemoveOnDemandBusReportRequest
    >(
        (args: DispatcherRestControllerApiRemoveOnDemandBusReportRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).removeOnDemandBusReport(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryDeleteRemoveOnDemandBusReport = (
    args: DispatcherRestControllerApiRemoveOnDemandBusReportRequest,
    options?: UseQueryOptions<DeleteRemoveOnDemandBusReportResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<DeleteRemoveOnDemandBusReportResponse, AxiosError>(
        [KeyDeleteRemoveOnDemandBusReport, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).removeOnDemandBusReport(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type DeleteRemoveRecurrentMissionResponse = AxiosResponse<void>;
export const KeyDeleteRemoveRecurrentMission = 'DeleteRemoveRecurrentMission';
export const useMutationDeleteRemoveRecurrentMission = (
    options?: UseMutationOptions<
        DeleteRemoveRecurrentMissionResponse,
        AxiosError,
        DispatcherRestControllerApiRemoveRecurrentMissionRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        DeleteRemoveRecurrentMissionResponse,
        AxiosError,
        DispatcherRestControllerApiRemoveRecurrentMissionRequest
    >(
        (args: DispatcherRestControllerApiRemoveRecurrentMissionRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).removeRecurrentMission(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryDeleteRemoveRecurrentMission = (
    args: DispatcherRestControllerApiRemoveRecurrentMissionRequest,
    options?: UseQueryOptions<DeleteRemoveRecurrentMissionResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<DeleteRemoveRecurrentMissionResponse, AxiosError>(
        [KeyDeleteRemoveRecurrentMission, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).removeRecurrentMission(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostRequestOnDemandBusReportResponse = AxiosResponse<OnDemandReportDto>;
export const KeyPostRequestOnDemandBusReport = 'PostRequestOnDemandBusReport';
export const useMutationPostRequestOnDemandBusReport = (
    options?: UseMutationOptions<
        PostRequestOnDemandBusReportResponse,
        AxiosError,
        DispatcherRestControllerApiRequestOnDemandBusReportRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostRequestOnDemandBusReportResponse,
        AxiosError,
        DispatcherRestControllerApiRequestOnDemandBusReportRequest
    >(
        (args: DispatcherRestControllerApiRequestOnDemandBusReportRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).requestOnDemandBusReport(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostRequestOnDemandBusReport = (
    args: DispatcherRestControllerApiRequestOnDemandBusReportRequest,
    options?: UseQueryOptions<PostRequestOnDemandBusReportResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostRequestOnDemandBusReportResponse, AxiosError>(
        [KeyPostRequestOnDemandBusReport, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).requestOnDemandBusReport(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PatchUpdateFlightMissionResponse = AxiosResponse<DispatcherMissionSlotDto>;
export const KeyPatchUpdateFlightMission = 'PatchUpdateFlightMission';
export const useMutationPatchUpdateFlightMission = (
    options?: UseMutationOptions<
        PatchUpdateFlightMissionResponse,
        AxiosError,
        DispatcherRestControllerApiUpdateFlightMissionRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PatchUpdateFlightMissionResponse,
        AxiosError,
        DispatcherRestControllerApiUpdateFlightMissionRequest
    >(
        (args: DispatcherRestControllerApiUpdateFlightMissionRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).updateFlightMission(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPatchUpdateFlightMission = (
    args: DispatcherRestControllerApiUpdateFlightMissionRequest,
    options?: UseQueryOptions<PatchUpdateFlightMissionResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PatchUpdateFlightMissionResponse, AxiosError>(
        [KeyPatchUpdateFlightMission, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).updateFlightMission(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PatchUpdateNumberOfToursResponse = AxiosResponse<DispatcherMissionSlotDto>;
export const KeyPatchUpdateNumberOfTours = 'PatchUpdateNumberOfTours';
export const useMutationPatchUpdateNumberOfTours = (
    options?: UseMutationOptions<
        PatchUpdateNumberOfToursResponse,
        AxiosError,
        DispatcherRestControllerApiUpdateNumberOfToursRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PatchUpdateNumberOfToursResponse,
        AxiosError,
        DispatcherRestControllerApiUpdateNumberOfToursRequest
    >(
        (args: DispatcherRestControllerApiUpdateNumberOfToursRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).updateNumberOfTours(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPatchUpdateNumberOfTours = (
    args: DispatcherRestControllerApiUpdateNumberOfToursRequest,
    options?: UseQueryOptions<PatchUpdateNumberOfToursResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PatchUpdateNumberOfToursResponse, AxiosError>(
        [KeyPatchUpdateNumberOfTours, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).updateNumberOfTours(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PatchUpdateOtherMissionResponse = AxiosResponse<DispatcherMissionSlotDto>;
export const KeyPatchUpdateOtherMission = 'PatchUpdateOtherMission';
export const useMutationPatchUpdateOtherMission = (
    options?: UseMutationOptions<
        PatchUpdateOtherMissionResponse,
        AxiosError,
        DispatcherRestControllerApiUpdateOtherMissionRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PatchUpdateOtherMissionResponse,
        AxiosError,
        DispatcherRestControllerApiUpdateOtherMissionRequest
    >(
        (args: DispatcherRestControllerApiUpdateOtherMissionRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).updateOtherMission(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPatchUpdateOtherMission = (
    args: DispatcherRestControllerApiUpdateOtherMissionRequest,
    options?: UseQueryOptions<PatchUpdateOtherMissionResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PatchUpdateOtherMissionResponse, AxiosError>(
        [KeyPatchUpdateOtherMission, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).updateOtherMission(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PatchUpdateRecurrentMissionResponse = AxiosResponse<RecurrentMissionDto>;
export const KeyPatchUpdateRecurrentMission = 'PatchUpdateRecurrentMission';
export const useMutationPatchUpdateRecurrentMission = (
    options?: UseMutationOptions<
        PatchUpdateRecurrentMissionResponse,
        AxiosError,
        DispatcherRestControllerApiUpdateRecurrentMissionRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PatchUpdateRecurrentMissionResponse,
        AxiosError,
        DispatcherRestControllerApiUpdateRecurrentMissionRequest
    >(
        (args: DispatcherRestControllerApiUpdateRecurrentMissionRequest) =>
            new DispatcherRestControllerApi(new Configuration({ accessToken })).updateRecurrentMission(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPatchUpdateRecurrentMission = (
    args: DispatcherRestControllerApiUpdateRecurrentMissionRequest,
    options?: UseQueryOptions<PatchUpdateRecurrentMissionResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PatchUpdateRecurrentMissionResponse, AxiosError>(
        [KeyPatchUpdateRecurrentMission, args, options && options.queryKey, accessToken],
        () =>
            new DispatcherRestControllerApi(new Configuration({ accessToken, ...conf })).updateRecurrentMission(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
