/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React from 'react';
import { Input, styled } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { SquareButton } from '../../../../../../components/Button/SquareButton/SquareButton';
import { theme } from '../../../../../../theme';
import { ReportItemDto } from '../../../../../../backend/gen';
import classNames from 'classnames';

export function VehicleReportFooter({
    items,
    onClose,
    onSubmit,
    isSubmitting,
    driverName,
    setDriverName,
}: {
    items: ReportItemDto[];
    onClose: () => void;
    onSubmit: () => void;
    isSubmitting: boolean;
    driverName: string;
    setDriverName: (name: string) => void;
}) {
    const intl = useIntl();
    const isFormValid = items.every(item => item.ok || item.comment) && driverName.trim() !== '';

    return (
        <StyledContainer>
            <StyledTextInputContainer>
                <FormattedMessage id={'driver.vehicleReport.driverName'} />
                <StyledTextInput
                    placeholder={intl.formatMessage({ id: 'driver.vehicleReport.driverName' })}
                    type={'text'}
                    disableUnderline
                    value={driverName}
                    onChange={e => setDriverName(e.target.value)}
                    className={classNames({ invalid: !driverName })}
                />
            </StyledTextInputContainer>
            <StyledButtonsContainer>
                <StyledCancelButton color="primary" size="medium" variant="outlined" onClick={onClose}>
                    <FormattedMessage id="confirm.default.cancel" />
                </StyledCancelButton>
                <StyledConfirmButton
                    color="primary"
                    size="medium"
                    variant="contained"
                    disabled={!isFormValid}
                    onClick={onSubmit}
                    loading={isSubmitting}
                >
                    <FormattedMessage id="confirm.default.confirm" />
                </StyledConfirmButton>
            </StyledButtonsContainer>
        </StyledContainer>
    );
}

const StyledContainer = styled('div')`
    height: 150px;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid ${theme.palette.blue.lightest};
    padding: 0 100px;
`;

const StyledTextInputContainer = styled('div')`
    height: 75px;
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: end;
    margin-bottom: 20px;
    & > span {
        position: absolute;
        top: 0;
        left: 1px;
        font-size: 14px;
        font-weight: normal;
    }
`;

const StyledTextInput = styled(Input)`
    height: 50px;
    width: 340px;
    border-radius: 5px;
    padding: 10px;
    background-color: #f1f7fb;
    &.invalid {
        border: 1px solid ${theme.palette.secondary.main};
    }
`;

const StyledButtonsContainer = styled('div')`
    display: flex;
    gap: 85px;
`;

const StyledCancelButton = styled(SquareButton)(({ theme }) => ({
    color: `${theme.palette.other.main} !important`,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    padding: '15px 34px',
    fontSize: '17px',
}));

const StyledConfirmButton = styled(SquareButton)`
    text-transform: capitalize;
    padding: 15px 34px;
    font-size: 17px;
    &.Mui-disabled {
        opacity: 0.5;
        color: white;
        background-color: #006ebb;
    }
    &.MuiLoadingButton-loading {
        color: transparent;
    }
`;
