/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { DriverMissionSlotDto } from '../../../../../../backend/gen';
import { Box, styled } from '@mui/material';
import { CurrentMissionOtherInfo } from './CurrentMissionOtherInfo';
import React from 'react';
import { CurrentMissionOtherSteps } from './CurrentMissionOtherSteps';

export function DriverCurrentOtherMission({ mission }: { mission: DriverMissionSlotDto }) {
    return (
        <StyledMissionContainer>
            <CurrentMissionOtherInfo mission={mission} />
            <CurrentMissionOtherSteps mission={mission} />
        </StyledMissionContainer>
    );
}

const StyledMissionContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 510px;
`;
