import { useEffect } from 'react';

export default function useKeypress(key: string, action?: (() => void) | null | false) {
    useEffect(() => {
        function onKeyup(e: KeyboardEvent) {
            if (e.key === key && action) action();
        }
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);
    }, [action, key]);
}
