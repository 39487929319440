/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React, { useState } from 'react';
import { styled } from '@mui/material';
import { HEADER_HEIGHT_IN_PX } from '../../../../../utils/constants';
import { VehicleReportFooter } from './VehicleReportComponents/VehicleReportFooter';
import { VehicleReportHeader } from './VehicleReportComponents/VehicleReportHeader';
import { VehicleReportBody } from './VehicleReportComponents/VehicleReportBody';
import { BusReportDto, ReportItemDto } from '../../../../../backend/gen';

interface VehicleReportFormProps {
    report: BusReportDto;
    onSubmit: (updatedItems: ReportItemDto[]) => Promise<void>;
    onClose: () => void;
    driverName: string;
    setDriverName: (name: string) => void;
}

export function VehicleReportForm({ report, onSubmit, onClose, driverName, setDriverName }: VehicleReportFormProps) {
    const [currentItems, setCurrentItems] = useState<ReportItemDto[]>(report.items);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        await onSubmit(currentItems);
    };

    const updateReportItem = (index: number, updatedItem: ReportItemDto) => {
        setCurrentItems(prevItems => prevItems.map((item, i) => (i === index ? updatedItem : item)));
    };

    return (
        <StyledContainer>
            <VehicleReportHeader reportType={report.type} />
            <VehicleReportBody items={currentItems} updateItem={updateReportItem} />
            <VehicleReportFooter
                items={currentItems}
                onClose={onClose}
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                driverName={driverName}
                setDriverName={setDriverName}
            />
        </StyledContainer>
    );
}

const StyledContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: 'white',
    color: theme.palette.text.primary,
    height: `calc(100% - ${HEADER_HEIGHT_IN_PX}px)`,
    width: '100%',
    borderRadius: '20px',
    padding: '40px 0 0 0',
    margin: '30px',
    display: 'flex',
    flexDirection: 'column',
}));
