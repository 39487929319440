import React, { memo, useMemo } from 'react';
import { DriverMissionSlotDto } from '../../../../backend/gen';
import { FlightMissionStripBus } from './StripBusSegments/FlightMission/FlightMissionStripBus';
import { OtherMissionStripBus } from './StripBusSegments/OtherMission/OtherMissionStripBus';

export const StripBus = memo(function StripBus({ mission }: { mission: DriverMissionSlotDto }) {
    const isMissionTypeOther = useMemo(
        () => mission?.type === 'OTHER' || mission?.type === 'RECURRENT',
        [mission?.type],
    );

    if (isMissionTypeOther) return <OtherMissionStripBus mission={mission} />;
    return <FlightMissionStripBus mission={mission} />;
});
