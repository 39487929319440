/*
 * Copyright Innov'ATM all rights reserved. This software is the property of Innov'ATM and may not be used in any
 * manner except under a license agreement signed with Innov'ATM.
 */

import { ToastOptions } from 'react-toastify';
import { Paper, ThemeProvider } from '@mui/material';
import { lightTheme } from '../theme';
import React from 'react';
import { ConfirmOptions } from 'material-ui-confirm';
import { Locales } from '@innovatm/js-iam';

export const HEADER_HEIGHT_IN_PX = 80;
export const RECURRENT_MISSIONS_ACTIONS_HEIGHT_IN_PX = 100;

export const ONE_HOUR = 60 * 60 * 1000;
export const HALF_HOUR = 30 * 60 * 1000;
export const localTimeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000;

export const MOBILE_BREAKPOINT = 900;
export const EMPTY_ARRAY: any[] = [];

export const defaultLocale = Locales.en;

export const defaultIcaoIata: 'icao' | 'iata' = 'iata';

export const errorToastConfig: ToastOptions = {
    autoClose: 6000,
    hideProgressBar: false,
    pauseOnHover: true,
    closeButton: false,
    closeOnClick: true,
    type: 'error' as const,
};

export const successToastConfig: ToastOptions = {
    autoClose: 4000,
    hideProgressBar: true,
    pauseOnHover: true,
    closeButton: false,
    closeOnClick: true,
    type: 'success' as const,
};

export const infoToastConfig: ToastOptions = {
    autoClose: 3000,
    hideProgressBar: true,
    pauseOnHover: true,
    closeButton: false,
    closeOnClick: true,
    type: 'dark' as const,
    toastId: 'info',
};

export const AUTH_TOKEN_AK_SCHEDULE_STORAGE_KEY = 'as_schedule_auth_token';
export const RENEW_TOKEN_AK_SCHEDULE_STORAGE_KEY = 'as_schedule_renew_token';

export const defaultConfirmProps: ConfirmOptions = {
    dialogProps: {
        disablePortal: true,
        PaperComponent: props => (
            <ThemeProvider theme={lightTheme}>
                <Paper {...props} />
            </ThemeProvider>
        ),
    },
    cancellationButtonProps: {
        color: 'primary',
        variant: 'contained',
        size: 'small',
    },
    confirmationButtonProps: {
        color: 'primary',
        variant: 'contained',
        size: 'small',
    },
};

export const passwordPolicy = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9\S]{8,}$/;
