/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { Autocomplete, Box, styled, TextField } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { ReactComponent as AirlineSeatReclineExtraIcon } from '../../../../../../../assets/icons/icon-passenger-light.svg';
import { ReactComponent as AircraftIcon } from '../../../../../../../assets/icons/icon-plane-light.svg';
import { DispatcherMissionSlotDto, FlightDto, FlightFlow } from '../../../../../../../backend/gen';
import { FormattedTime } from '../../../../../../../components/time/FormattedTime';
import { useCurrentUserClientTimezone } from '../../../../../../../contexts/AuthContext.selectors';
import { utcToZonedTime } from 'date-fns-tz';

export type FlightOptionType = {
    label: string;
    flight: FlightDto;
};

export function FlightSelector({
    flights,
    mission,
    selectedFlight,
    setSelectedFlight,
    setMission,
}: {
    flights: Array<FlightDto>;
    mission: Partial<DispatcherMissionSlotDto>;
    selectedFlight: FlightOptionType;
    setSelectedFlight: React.Dispatch<React.SetStateAction<FlightOptionType>>;
    setMission: React.Dispatch<React.SetStateAction<Partial<DispatcherMissionSlotDto>>>;
}) {
    const timezone = useCurrentUserClientTimezone();
    const now = useMemo(() => new Date(), []);
    const tomorrow = useMemo(() => {
        const zonedNow = utcToZonedTime(now, timezone || 'UTC');
        return new Date(zonedNow.setDate(zonedNow.getDate() + 1)).getDate();
    }, [now, timezone]);

    const flightList: Array<FlightOptionType> = useMemo(
        () =>
            flights?.map(flight => {
                const flightDate = new Date(utcToZonedTime(flight.refTime, timezone || 'UTC')).getDate();
                const isFlightTomorrow = flightDate === tomorrow ? '(D+1)' : '';
                return { label: `${flight.flightNumber} ${isFlightTomorrow}`, flight: flight };
            }),
        [flights, tomorrow, timezone],
    );

    const handleFlightChange = useCallback(
        (event: any, value: FlightOptionType) => {
            setSelectedFlight(value);
            setMission({
                flight: value.flight,
                type: undefined,
                staffNum: undefined,
                pickUpLocation: undefined,
                dropOffLocation: undefined,
                comment: undefined,
                driverNote: undefined,
                endTime: undefined,
                removable: undefined,
                missionId: undefined,
                name: undefined,
                refTime: undefined,
            });
        },
        [setMission, setSelectedFlight],
    );

    return (
        <>
            <StyledSectionTitle>Flight Number</StyledSectionTitle>
            <StyledFlightInfoContainer>
                <Autocomplete
                    disablePortal
                    options={flightList}
                    sx={{ width: 160, backgroundColor: '#F1F7FB', color: '#0A3B5D', marginRight: '30px' }}
                    renderInput={params => <TextField {...params} placeholder="Flight Number" required />}
                    autoHighlight
                    clearOnEscape
                    disableClearable={!!mission?.flight}
                    onChange={handleFlightChange}
                    value={selectedFlight}
                    disabled={!!mission?.missionId}
                    renderOption={(props, option) => (
                        <li {...props} key={option.flight?.flightId}>
                            {option.label}
                        </li>
                    )}
                    isOptionEqualToValue={(option: FlightOptionType, value: FlightOptionType) =>
                        option?.flight?.flightId === value?.flight?.flightId
                    }
                />
                {mission?.flight && (
                    <>
                        <StyledFlightInfoBox style={{ marginRight: '30px' }}>
                            <StyledFlightInfoTitle>
                                {mission.flight?.flow === FlightFlow.ARR ? 'STA' : 'STD'}
                            </StyledFlightInfoTitle>
                            <StyledFlightInfo>
                                <FormattedTime>{mission.flight?.refTime}</FormattedTime>
                            </StyledFlightInfo>
                        </StyledFlightInfoBox>
                        <Box style={{ marginRight: '20px' }}>
                            <StyledFlightInfoTitle>
                                {mission.flight?.flow === FlightFlow.ARR ? 'PROV' : 'DEST'}
                            </StyledFlightInfoTitle>
                            <StyledFlightInfo>{mission.flight?.adepAdes?.iata}</StyledFlightInfo>
                        </Box>
                        <StyledRowDiv>
                            <AircraftIcon width={'36px'} height={'36px'} />
                            <div style={{ marginRight: '20px', width: '20px' }}>{mission.flight?.acType?.iata}</div>
                            <AirlineSeatReclineExtraIcon fill="#f1f7fb" width={'36px'} height={'36px'} />
                            <div style={{ width: '20px' }}>{mission.flight?.pax}</div>
                        </StyledRowDiv>
                    </>
                )}
            </StyledFlightInfoContainer>
        </>
    );
}

const StyledFlightInfoContainer = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 27px;
`;

const StyledSectionTitle = styled('div')`
    opacity: 1;
    color: rgba(10, 59, 93, 1);
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
`;

const StyledFlightInfoBox = styled('div')`
    display: flex;
    flex-direction: column;
`;
const StyledFlightInfoTitle = styled('div')`
    opacity: 1;
    color: rgba(172, 172, 172, 1);
    font-size: 10px;
    max-height: 10px;
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
`;
const StyledFlightInfo = styled(`div`)`
    color: rgba(8, 41, 64, 1);
    font-size: 20px;
    font-weight: 700;
    text-align: left;
`;
const StyledRowDiv = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
