/**
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import isEqual from 'lodash/fp/isEqual';
import { useCallback, useState } from 'react';
import { useMirroredRef } from './useMirroredRef';

export const useOptimizedState = <T>(
    initialValue: T | (() => T),
): [T, React.Dispatch<React.SetStateAction<T>>, any] => {
    const [state, setState] = useState<T>(initialValue);

    const stateRef = useMirroredRef(state);
    return [
        state,
        useCallback(
            (v: React.SetStateAction<T>) => {
                const newValue = typeof v === 'function' ? (v as any)(stateRef.current) : v;

                if (isEqual(newValue, stateRef.current)) {
                    return;
                }

                stateRef.current = newValue;
                setState(newValue);
            },
            [stateRef],
        ),
        useCallback(<K extends keyof T>(key: K): T[K] => stateRef.current[key], [stateRef]),
    ];
};
