import React, { ComponentProps, memo, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { TextField } from '../TextField/TextField';
import { format } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { FormattedMessage } from 'react-intl';
import { useCurrentUserClientTimezone } from '../../../contexts/AuthContext.selectors';

const StyledTimeField = styled(TextField)`
    input::-webkit-calendar-picker-indicator {
        filter: invert(100%);
    }
`;

export const TimeField = memo(function TimeField({
    label,
    name,
    date,
    value,
    onChange,
    step,
    ...rest
}: {
    label?: string | React.ReactNode;
    name: string;
    date: number | Date; // Day
    value?: number | Date | null; // hour + minutes
    onChange?: (value: number | Date | null) => void;
    step?: number;
} & ComponentProps<typeof TextField>) {
    const timezone = useCurrentUserClientTimezone();
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (value) {
            setInputValue(format(utcToZonedTime(value, timezone || ''), 'HH:mm'));
        } else {
            setInputValue('');
        }
    }, [value, timezone]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setInputValue(newValue);

        if (newValue.length === 5 && /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(newValue)) {
            const [hours, minutes] = newValue.split(':').map(Number);
            const zonedDate = utcToZonedTime(date, timezone || '');
            const updatedTime = new Date(zonedDate).setHours(hours, minutes);
            onChange?.(zonedTimeToUtc(updatedTime, timezone || '').getTime());
        } else if (newValue === '') {
            onChange?.(null);
        }
    };

    return (
        <StyledTimeField
            label={<>{label ?? <FormattedMessage id={timezone === 'UTC' ? 'form.utcTime' : 'form.localTime'} />}</>}
            id={name}
            name={name}
            type="time"
            {...rest}
            value={inputValue}
            inputProps={{
                step: step || 5 * 60, // in seconds, 5 min increments
            }}
            onChange={handleInputChange}
        />
    );
});
