import React, { memo } from 'react';
import { StyledMissionSteps, StyledOtherStepDot, StyledPendingStepDot } from './OtherMissionStepsIndicator.style';

export const OtherMissionStepsIndicator = memo(function OtherMissionStepsIndicator({
    column,
    columns,
    stepsTotal,
    stepsDone,
}: {
    column: number;
    columns: number;
    stepsTotal: number;
    stepsDone: number;
}) {
    const stepsInThisColumn = column === columns ? stepsTotal % 3 || 3 : 3;
    const stepsDoneBeforeThisColumn = (column - 1) * 3;
    const stepsDoneInThisColumn = Math.max(0, Math.min(stepsInThisColumn, stepsDone - stepsDoneBeforeThisColumn));

    return (
        <StyledMissionSteps>
            {[...Array(stepsDoneInThisColumn)].map((_, i) => (
                <StyledOtherStepDot key={i} />
            ))}
            {[...Array(stepsInThisColumn - stepsDoneInThisColumn)].map((_, i) => (
                <StyledPendingStepDot key={i} />
            ))}
        </StyledMissionSteps>
    );
});
