/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { useCurrentUser } from '../../../contexts/AuthContext.selectors';
import { ProfileType, useAuthorizeProfile } from '../../../hooks/useAuthorizeProfile';
import { RecurrentMissionsHeader } from './RecurrentMissionsHeader';
import { RecurrentMissionsContent } from './RecurrentMissionsContent';
import { RecurrentMissionsActions } from './RecurrentMissionsActions';
import { useState } from 'react';
import { useMissionForm } from '../../../contexts/MissionFormContext';
import { useQueryGetGetAllRecurrentMissions } from '../../../backend/gen';

export function RecurrentMissionsPage() {
    const currentUser = useCurrentUser();
    useAuthorizeProfile(currentUser, ProfileType.DISPATCHER);
    const { data } = useQueryGetGetAllRecurrentMissions();
    const [selectedMissionId, setSelectedMissionId] = useState<string>(null);
    const { currentFormValues } = useMissionForm();
    const currentMission = currentFormValues?.type === 'recurrentMissionForm' ? currentFormValues?.mission : null;

    return (
        <>
            <RecurrentMissionsHeader />
            <RecurrentMissionsContent
                mission={currentMission}
                missions={data}
                selectedMissionId={selectedMissionId}
                setSelectedMissionId={setSelectedMissionId}
            />
            <RecurrentMissionsActions
                selectedMissionId={selectedMissionId}
                setSelectedMissionId={setSelectedMissionId}
                mission={currentMission}
            />
        </>
    );
}
