/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React, { useCallback, useState } from 'react';
import { DispatcherMissionSlotDto, FlightDto } from '../../../../../../backend/gen';
import { FlightSelector } from './FlightMissionDialogContentComponents/FlightSelector';
import { AirportResourceInfo } from './FlightMissionDialogContentComponents/AirportResourceInfo';
import { MissionTypeSelector } from './FlightMissionDialogContentComponents/MissionTypeSelector';
import { CommentInput } from './FlightMissionDialogContentComponents/CommentInput';
import { DropOffInput } from './FlightMissionDialogContentComponents/DropOffInput';
import { useMissionForm } from '../../../../../../contexts/MissionFormContext';

type FlightOptionType = {
    label: string;
    flight: FlightDto;
};

export function FlightMissionDialogContent({
    flights,
    mission,
}: {
    flights?: Array<FlightDto>;
    mission: Partial<DispatcherMissionSlotDto>;
}) {
    const { updateFormMissionValues } = useMissionForm();

    const setMission = useCallback(
        (updatedMission: Partial<DispatcherMissionSlotDto>) => {
            updateFormMissionValues('flightMissionForm', updatedMission);
        },
        [updateFormMissionValues],
    );

    const [selectedFlight, setSelectedFlight] = useState<FlightOptionType>(
        mission?.missionId
            ? {
                  label: mission.flight?.flightNumber,
                  flight: mission.flight,
              }
            : null,
    );

    return (
        <>
            <FlightSelector
                flights={flights}
                mission={mission}
                selectedFlight={selectedFlight}
                setSelectedFlight={setSelectedFlight}
                setMission={setMission}
            />
            <AirportResourceInfo mission={mission} />
            <DropOffInput mission={mission} setMission={setMission} />
            <MissionTypeSelector mission={mission} setMission={setMission} selectedFlight={selectedFlight} />
            <CommentInput mission={mission} setMission={setMission} />
        </>
    );
}
