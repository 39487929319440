import { buttonClasses, IconButton, styled } from '@mui/material';

export const SquareIconButton = styled(IconButton)`
    border: 1px solid #ffffff;
    padding: 0;
    border-radius: 5px;
    letter-spacing: 0;
    text-transform: none;
    height: ${({ size }) => (size === 'small' ? '30px' : size === 'medium' ? '50px' : '75px')};
    font-size: ${({ size }) => (size === 'small' ? '13px' : size === 'medium' ? '15px' : '22px')};
    line-height: 18px;
    white-space: nowrap;
    &.${buttonClasses.outlined} {
        color: ${({ theme }) => theme.palette.primary.contrastText};
        border-color: #245f88;
        &.${buttonClasses.disabled} {
            color: rgba(255, 255, 255, 0.3);
            border-color: rgba(255, 255, 255, 0.12);
        }
    }
`;
