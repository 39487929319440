import { styled } from '@mui/material';
import React from 'react';

export const HelpIcon = styled(props => <div {...props}>?</div>)<{ size: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    font-size: ${({ size }) => size * 0.7}px;
    border-radius: 50%;
    border: 1px solid white;
`;
