/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback } from 'react';
import {
    StyledOtherMissionDialogRow,
    StyledOtherMissionTextInput,
    StyledOtherMissionTextInputContainer,
    StyledOtherMissionTimeContainer,
    StyledOtherMissionTimePicker,
} from '../OtherMissionDialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import { DispatcherMissionSlotDto } from '../../../../../../../backend/gen';

export function OtherMissionSteps({
    mission,
    setMission,
}: {
    mission: Partial<DispatcherMissionSlotDto>;
    setMission: (updatedMission: Partial<DispatcherMissionSlotDto>) => void;
}) {
    const intl = useIntl();
    const isMissionTodo = !mission?.state || mission.state === 'TODO';

    const onChangeUpdateStepName = useCallback(
        (name: string, stepIndex: number) => {
            setMission({
                steps: mission?.steps?.map((s, index) => (index === stepIndex ? { ...s, name } : s)),
            });
        },
        [mission?.steps, setMission],
    );

    const onChangeUpdateStepTime = useCallback(
        (time: number | Date | null, stepIndex: number) => {
            if (!time) return undefined;
            const timeAsNumber = typeof time === 'number' ? time : time.getTime();
            setMission({
                steps: mission?.steps?.map((s, index) =>
                    index === stepIndex ? { ...s, estimatedDoneTime: timeAsNumber } : s,
                ),
            });
        },
        [mission?.steps, setMission],
    );

    return (
        <>
            {mission?.steps?.map((step, i) => (
                <StyledOtherMissionDialogRow key={i}>
                    <StyledOtherMissionTextInputContainer>
                        <FormattedMessage id={'otherMissionForm.stepName'} />
                        <StyledOtherMissionTextInput
                            placeholder={intl.formatMessage({ id: 'otherMissionForm.stepName' })}
                            type={'text'}
                            disableUnderline
                            value={step.name || ''}
                            onChange={e => onChangeUpdateStepName(e.target.value, i)}
                            disabled={!isMissionTodo}
                        />
                    </StyledOtherMissionTextInputContainer>
                    <StyledOtherMissionTimeContainer>
                        <StyledOtherMissionTimePicker
                            name={'stepTime'}
                            date={new Date()}
                            value={step?.estimatedDoneTime ?? null}
                            onChange={time => onChangeUpdateStepTime(time, i)}
                            required
                            label={
                                <span className={'missionTimeLabelText'}>
                                    <FormattedMessage id={'otherMissionForm.stepTime'} />
                                </span>
                            }
                            disabled={!isMissionTodo}
                        />
                    </StyledOtherMissionTimeContainer>
                    {isMissionTodo && (
                        <StyledCloseIcon
                            onClick={() => setMission({ steps: mission?.steps?.filter((_, index) => index !== i) })}
                        />
                    )}
                </StyledOtherMissionDialogRow>
            ))}
        </>
    );
}

const StyledCloseIcon = styled(CloseIcon)`
    position: absolute;
    right: 40px;
    opacity: 80%;
    cursor: pointer;
`;
