/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React, { memo } from 'react';
import { DispatcherMissionSlotDto, FlightFlow, MissionState, MissionType } from '../../../../../backend/gen';
import {
    exceptTooltipContentsStyles,
    StyledAcPaxInfo,
    StyledAcPaxInfos,
    StyledDirections,
    StyledStripFooter,
    StyledStripFooterContainer,
    StyledStripTooltip,
    zoneTooltipContentsStyles,
} from '../MissionStrip.style';
import { Box, styled, Zoom } from '@mui/material';
import { ReactComponent as AirlineSeatReclineExtraIcon } from '../../../../../assets/icons/icon-passenger-light-bus.svg';
import { ReactComponent as StaffIcon } from '../../../../../assets/icons/icon-staff.svg';
import { ReactComponent as AircraftIcon } from '../../../../../assets/icons/icon-plane-light-bus.svg';

export const StripFlight = memo(function StripFlight({
    mission,
    color,
}: {
    mission: DispatcherMissionSlotDto;
    color: string;
}) {
    const isMissionTerminated = mission.state === MissionState.TERMINATED;

    return (
        <>
            <StyledStripFlightBox
                sx={{
                    backgroundColor: isMissionTerminated ? '#cccccc' : 'white',
                    outline: mission.state === MissionState.IN_PROGRESS ? `3px solid ${color}` : 'none',
                }}
            >
                <StyledDirections>
                    {mission.flight?.flow === FlightFlow.ARR ? (
                        <>
                            <Box>
                                Pkg <b>{mission.flight?.parking}</b>
                            </Box>
                            <Box>→</Box>
                            <StyledStripTooltip
                                title={mission.dropOffLocation || ''}
                                TransitionComponent={Zoom}
                                placement={'bottom'}
                                arrow
                            >
                                <Box sx={mission.dropOffLocation?.length ? zoneTooltipContentsStyles : undefined}>
                                    Zone <b>{mission.flight?.zone}</b>
                                </Box>
                            </StyledStripTooltip>
                        </>
                    ) : mission.type === MissionType.EX_PAX || mission.type === MissionType.EX_STAFF ? (
                        <>
                            <StyledStripTooltip
                                title={mission.pickUpLocation || ''}
                                TransitionComponent={Zoom}
                                placement={'bottom'}
                                arrow
                            >
                                <Box sx={exceptTooltipContentsStyles}>Pick-up</Box>
                            </StyledStripTooltip>

                            <Box>→</Box>
                            <StyledStripTooltip
                                title={mission.dropOffLocation || ''}
                                TransitionComponent={Zoom}
                                placement={'bottom'}
                                arrow
                            >
                                <Box sx={exceptTooltipContentsStyles}>Drop-off</Box>
                            </StyledStripTooltip>
                        </>
                    ) : (
                        <>
                            <Box>
                                Gate <b>{mission.flight?.gate}</b>
                            </Box>
                            <Box>→</Box>
                            <Box>
                                Pkg <b>{mission.flight?.parking}</b>
                            </Box>
                        </>
                    )}
                </StyledDirections>
                <StyledAcPaxInfos>
                    <StyledAcPaxInfo>
                        <AircraftIcon fill={color} width={'24px'} height={'24px'} />
                        {mission.flight?.acType.iata}
                    </StyledAcPaxInfo>
                    <StyledAcPaxInfo>
                        {mission.type === MissionType.STAFF || mission.type === MissionType.EX_STAFF ? (
                            <>
                                <StaffIcon fill={color} width={'24px'} height={'24px'} />
                                <div>{mission.staffNum}</div>
                            </>
                        ) : (
                            <>
                                <AirlineSeatReclineExtraIcon fill={color} width={'24px'} height={'24px'} />
                                <div>{mission.flight?.pax}</div>
                            </>
                        )}
                    </StyledAcPaxInfo>
                </StyledAcPaxInfos>
            </StyledStripFlightBox>
            <StyledStripFooterContainer sx={{ opacity: isMissionTerminated ? '50%' : '100%' }}>
                <StyledStripFooter>
                    <Box>{mission.flight?.flightNumber}</Box>
                    <Box>{mission.flight?.adepAdes.iata}</Box>
                </StyledStripFooter>
            </StyledStripFooterContainer>
        </>
    );
});

const StyledStripFlightBox = styled(Box)`
    height: 60px;
    width: 140px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 0 15px;
    color: black;
    display: flex;
    gap: 3px;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 3;
`;
