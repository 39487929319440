/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React, { memo } from 'react';
import { DispatcherMissionSlotDto, MissionState } from '../../../../backend/gen';
import { useUiValue } from '../../../../contexts/UiContext';
import { useBoolean } from '../../../../hooks/useBoolean';
import { getMissionPrimaryColor } from '../../../../utils/data.utils';
import {
    StyledAboveStripContainer,
    StyledContainer,
    StyledLeftInfo,
    StyledStripFlow,
    StyledStripTimeBadge,
    StyledStripTooltip,
} from './MissionStrip.style';
import { Box, Zoom } from '@mui/material';
import { FormattedTime } from '../../../../components/time/FormattedTime';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { MissionStripMenu } from './MissionStripMenu/MissionStripMenu';
import { StripFlight } from './StripFlight/StripFlight';
import { StripOther } from './StripOther/StripOther';
import { StripFlightSteps } from './StripFlight/StripFlightSteps';
import { StripOtherSteps } from './StripOther/StripOtherSteps';

export const STRIP_MINIMUM_WIDTH = 140;

export const MissionStrip = memo(function MissionStrip({
    buses,
    mission,
    isUnallocated,
}: {
    buses: string[];
    mission: DispatcherMissionSlotDto;
    isUnallocated: boolean;
}) {
    const isTactical = useUiValue('isTacticalView');
    const isMenuButtonVisible = useBoolean(false);
    const stripPrimaryColor = getMissionPrimaryColor(mission.type, mission.flight?.flow);
    const isMissionTerminated = mission.state === MissionState.TERMINATED;

    return (
        <StyledContainer>
            <Box
                onMouseEnter={!isTactical ? null : isMenuButtonVisible.setTrue}
                onMouseLeave={!isTactical ? null : isMenuButtonVisible.setFalse}
            >
                <StyledAboveStripContainer>
                    <StyledLeftInfo>
                        <StyledStripTimeBadge
                            bgcolor={stripPrimaryColor}
                            color={isMissionTerminated ? '#cccccc' : 'white'}
                        >
                            <FormattedTime>{mission.refTime}</FormattedTime>
                        </StyledStripTimeBadge>
                        {mission.comment && (
                            <StyledStripTooltip title={mission.comment} TransitionComponent={Zoom} placement={'top'}>
                                <PriorityHighIcon
                                    sx={{
                                        color: stripPrimaryColor,
                                        height: '15px',
                                        width: '15px',
                                        backgroundColor: 'white',
                                        borderRadius: '2px',
                                        marginTop: '2px',
                                        marginLeft: '4px',
                                    }}
                                />
                            </StyledStripTooltip>
                        )}
                    </StyledLeftInfo>
                    {isMenuButtonVisible.value && !mission.comment && (
                        <StyledStripFlow color={stripPrimaryColor}>{mission.flight?.flow}</StyledStripFlow>
                    )}
                    <MissionStripMenu
                        buses={buses}
                        isMenuButtonVisible={isMenuButtonVisible}
                        mission={mission}
                        isMissionTerminated={isMissionTerminated}
                        isUnallocated={isUnallocated}
                    />
                </StyledAboveStripContainer>
                {mission.type === 'OTHER' || mission.type === 'RECURRENT' ? (
                    <StripOther mission={mission} color={stripPrimaryColor} />
                ) : (
                    <StripFlight mission={mission} color={stripPrimaryColor} />
                )}
            </Box>
            {mission.type === 'OTHER' || mission.type === 'RECURRENT' ? (
                <StripOtherSteps mission={mission} />
            ) : (
                <StripFlightSteps mission={mission} />
            )}
        </StyledContainer>
    );
});
