/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { DriverMissionSlotDto } from '../../../../../../backend/gen';
import {
    StyledStepContainer,
    StyledStepPendingCheckbox,
    StyledStepsContainer,
} from '../FlightMission/CurrentMissionFlightSteps.style';
import { Box, styled } from '@mui/material';
import { Locales, useIntlValue } from '@innovatm/js-iam';
import React, { useEffect, useMemo, useRef } from 'react';
import { getMissionPrimaryColor } from '../../../../../../utils/data.utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { theme } from '../../../../../../theme';
import { FormattedTime } from '../../../../../../components/time/FormattedTime';

export function CurrentMissionOtherSteps({ mission }: { mission: DriverMissionSlotDto }) {
    const locale = useIntlValue('locale');
    const flowColor = getMissionPrimaryColor(mission.type, mission.flight?.flow);
    const currentStep = useMemo(() => {
        const indexOfFirstIncompleteStep = mission.steps.findIndex(step => step.done === false);
        return indexOfFirstIncompleteStep === 0 ? 0 : indexOfFirstIncompleteStep - 1;
    }, [mission.steps]);

    const hasOverflowTop = mission.steps.length > 8 && currentStep > 3;
    const hasOverflowBottom = mission.steps.length > 8 && currentStep < mission.steps.length - 4;
    const stepsContainerClasses = useMemo(() => {
        if (hasOverflowBottom && hasOverflowTop) {
            return 'both-fadeout';
        } else if (hasOverflowBottom) {
            return 'bottom-fadeout';
        } else if (hasOverflowTop) {
            return 'top-fadeout';
        } else {
            return '';
        }
    }, [hasOverflowBottom, hasOverflowTop]);

    const stepsRef = useRef([]);
    useEffect(() => {
        const firstIncompleteStepIndex = mission.steps.findIndex(step => !step.done);
        stepsRef.current[
            mission.steps.find(step => !step.done)
                ? firstIncompleteStepIndex > 0
                    ? firstIncompleteStepIndex - 1
                    : 0
                : mission.steps.length - 1
        ].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    }, [mission.steps]);

    return (
        <StyledStepsContainer className={stepsContainerClasses} marginTop={'40px'} height={'420px'}>
            {mission.steps.map((step, i) => {
                const stepTimeToShow = step.actualDoneTime ?? step.estimatedDoneTime;
                return (
                    <StyledStepContainer
                        key={i}
                        color={!mission.steps[i].done ? '#ACACAC' : ''}
                        ref={e => (stepsRef.current[i] = e)}
                    >
                        <StyledStepTime>
                            {stepTimeToShow ? <FormattedTime>{stepTimeToShow}</FormattedTime> : '--:--'}
                        </StyledStepTime>
                        {step.done ? <StyledOtherStepCompleteIcon /> : <StyledStepPendingCheckbox />}
                        <Box color={i === currentStep && step.done ? flowColor : ''}>
                            {locale === Locales.en ? step.descriptionEng : step.descriptionFr}
                        </Box>
                    </StyledStepContainer>
                );
            })}
        </StyledStepsContainer>
    );
}

const StyledOtherStepCompleteIcon = styled(CheckCircleIcon)`
    height: 24px;
    width: 24px;
    color: ${theme.palette.other.main};
`;

const StyledStepTime = styled('div')`
    width: 75px;
    text-align: center;
`;
