/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import {
    StyledOtherMissionDialogRow,
    StyledOtherMissionTextInput,
    StyledOtherMissionTextInputContainer,
} from '../OtherMissionDialogContent';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { Input, styled } from '@mui/material';
import { DispatcherMissionSlotDto } from '../../../../../../../backend/gen';

export function OtherMissionNoteComment({
    mission,
    setMission,
}: {
    mission: Partial<DispatcherMissionSlotDto>;
    setMission: (updatedMission: Partial<DispatcherMissionSlotDto>) => void;
}) {
    const intl = useIntl();

    return (
        <>
            <StyledOtherMissionDialogRow>
                <StyledOtherMissionTextInputContainer>
                    <FormattedMessage id={'otherMissionForm.driverNote'} />
                    <StyledOtherMissionTextInput
                        placeholder={intl.formatMessage({ id: 'otherMissionForm.driverNote' })}
                        type={'text'}
                        disableUnderline
                        value={mission?.driverNote || ''}
                        onChange={e => setMission({ driverNote: e.target.value })}
                    />
                </StyledOtherMissionTextInputContainer>
            </StyledOtherMissionDialogRow>
            <StyledOtherMissionDialogRow>
                <StyledOtherMissionTextAreaContainer>
                    <FormattedMessage id={'otherMissionForm.reportComment'} />
                    <StyledOtherMissionTextArea
                        placeholder={intl.formatMessage({ id: 'otherMissionForm.reportComment' })}
                        type={'text'}
                        multiline={true}
                        disableUnderline
                        value={mission?.comment || ''}
                        onChange={e => setMission({ comment: e.target.value })}
                    />
                </StyledOtherMissionTextAreaContainer>
            </StyledOtherMissionDialogRow>
        </>
    );
}

const StyledOtherMissionTextAreaContainer = styled('div')`
    height: 125px;
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: end;
    margin-bottom: 20px;
    & > span {
        position: absolute;
        top: 0;
        left: 1px;
        font-size: 14px;
        font-weight: normal;
    }
`;

const StyledOtherMissionTextArea = styled(Input)`
    height: 100px;
    width: 100%;
    align-items: flex-start;
    border-radius: 5px;
    padding: 10px;
    background-color: #f1f7fb;
`;
