/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { CustomDialog } from '../../../../../components/CustomDialog/CustomDialog';
import React from 'react';
import { dialogActionsClasses, dialogContentClasses, DialogTitleProps, styled } from '@mui/material';
import { dialogClasses, DialogProps } from '@mui/material/Dialog';
import { FormattedMessage } from 'react-intl';
import { OnDemandReportDto } from '../../../../../backend/gen';
import { BusRequestReportDialogContentAndActions } from './BusRequestReportDialogContentAndActions';

export function BusRequestReportDialog({
    busName,
    report,
    open,
    closeDialog,
}: {
    busName: string;
    report: OnDemandReportDto;
    open: boolean;
    closeDialog: () => void;
}) {
    const isEditingExistingReport = !!report?.id;

    return (
        <CustomDialog
            open={open}
            customDialogProps={BusRequestReportDialogProps}
            title={
                <StyledTitle>
                    {busName.toUpperCase()}
                    <FormattedMessage
                        id={
                            isEditingExistingReport
                                ? 'dispatcher.busRequestReport.edit'
                                : 'dispatcher.busRequestReport.create'
                        }
                    />
                </StyledTitle>
            }
            customTitleProps={BusRequestReportDialogTitleProps}
            contentAndActionsCombined={
                <BusRequestReportDialogContentAndActions busName={busName} report={report} closeDialog={closeDialog} />
            }
        ></CustomDialog>
    );
}

const BusRequestReportDialogTitleProps: Partial<DialogTitleProps> = {
    color: '#082940',
    sx: { textAlign: 'center', fontFamily: 'Roboto', fontSize: '18px', lineHeight: '28px' },
};

const BusRequestReportDialogProps: Partial<DialogProps> = {
    sx: {
        [`& .${dialogClasses.paper}`]: {
            background: 'white',
            padding: '24px 70px 50px 70px',
            height: 'fit-content',
            width: '460px',
            borderRadius: '20px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0 3px 75px 0 rgba(0,0,0,0.5)',
            color: '#082940',
            fontFamily: 'Roboto',
            fontSize: '18px',
        },
        [`& .${dialogContentClasses.root}`]: {
            padding: '0 0 20px 0',
            textAlign: 'center',
            whiteSpace: 'pre-line',
        },
        [`& .${dialogActionsClasses.root}`]: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
};

const StyledTitle = styled('div')`
    display: flex;
    flex-direction: column;
`;
