/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React, { memo } from 'react';
import { DispatcherMissionSlotDto } from '../../../../../backend/gen';
import { StyledStripTooltip } from '../MissionStrip.style';
import { Box, styled, Zoom } from '@mui/material';
import { OtherMissionStepsIndicator } from './StripOtherMissionSteps/OtherMissionStepsIndicator';
import { MissionStripStepsPreview } from '../MissionStripStepsPreview';

export const StripOtherSteps = memo(function StripOtherSteps({ mission }: { mission: DispatcherMissionSlotDto }) {
    const indicatorColumnsNb = Math.ceil(mission.nbStepsTotal / 3);

    return (
        <StyledStripTooltip
            title={
                <MissionStripStepsPreview
                    steps={mission.steps}
                    flow={mission.flight?.flow}
                    nbTours={mission.nbTours}
                    missionType={mission.type}
                />
            }
            followCursor={true}
            TransitionComponent={Zoom}
        >
            <StyledMissionStepsContainer>
                {[...Array(indicatorColumnsNb)].map((_, i) => {
                    return (
                        <OtherMissionStepsIndicator
                            key={i}
                            column={i + 1}
                            columns={indicatorColumnsNb}
                            stepsTotal={mission.nbStepsTotal}
                            stepsDone={mission.nbStepsDone}
                        />
                    );
                })}
            </StyledMissionStepsContainer>
        </StyledStripTooltip>
    );
});

const StyledMissionStepsContainer = styled(Box)`
    height: 26px;
    display: flex;
    gap: 3px;
    margin-left: 6px;
    margin-bottom: 4px;
    padding-top: 4px;
    position: relative;
    z-index: 3;
    align-self: flex-end;
`;
